import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import UserRoleService from "../../services/srv-user/UserRoleService";
import { filterBy } from "@progress/kendo-data-query";
import UserService from "../../services/srv-user/UserService";

const SalesRepresentativeDropDownList = ({
  id,
  label,
  value,
  required,
  onChange,
  satisTemsilcileri,
  itemKey,
}) => {
  const delay = 500;
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    data: data.slice(),
    loading: false,
  });
  useEffect(() => {
    getDataByFilter();
  }, []);

  const onCompletedGetData = (data) => {
    setData(data);
    setDataLoading(false);
  };

  const getDataByFilter = () => {
    setDataLoading(true);
    if (satisTemsilcileri === "all") {
      UserService.getAllSatisTemsilcileri(onCompletedGetData);
    } else {
      UserRoleService.getSatisTemsilcileri(onCompletedGetData);
    }
  };

  const timeout = React.useRef(false);
  const filterData = (filter) => {
    const newData = data.slice();
    return filterBy(newData, filter);
  };
  const filterChange = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setState({
        loading: false,
        data: filterData(event.filter),
      });
    }, delay);
    setState({
      ...state,
      loading: true,
    });
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={state.data}
      textField="adsoyad"
      dataItemKey={itemKey ? itemKey : "kullanicI_ID"}
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      onFilterChange={filterChange}
      filterable={true}
      loading={dataLoading}
      defaultItem={{ adsoyad: "Lütfen Seçiniz", kullanicI_ID: null }}
      value={
        value
          ? data.find((v) =>
              itemKey ? v[itemKey] === value : v.kullanicI_ID === value
            )
          : ""
      }
    />
  );
};

export default SalesRepresentativeDropDownList;
