import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import alertify from "alertifyjs";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
  getWarningMessage,
} from "../../helpers/utils";
import {
  executeOnayIptal,
  executeOnaySorgula,
} from "../../redux/actions/act-order/orderActions";
import {
  changeOrderProduct,
  deleteOrderProduct,
  getOrderProducts,
  insertOrderProduct,
  updateOrderProduct,
} from "../../redux/actions/act-order/orderProductActions";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import { GridInlineEditNumericCell } from "../sys-toolbox/GridInlineEditNumericCell";
import { GridInlineEditNumericPercentCell } from "../sys-toolbox/GridInlineEditNumericPercentCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import OrderDiscountProductGroupModal from "./OrderDiscountProductGroupModal";
import OrderProductAutoComplete from "./OrderProductAutoComplete";
import OrderProductImport from "./OrderProductImport";
import { formatDate } from "@telerik/kendo-intl";
import OrderFreeStock from "./OrderFreeStock";
import FreeStockService from "../../services/srv-freeStock/FreeStockService";
import OrderFreeStockRDD from "./OrderFreeStockRDD";
import CalculateVolume from "../sys-common/CalculateVolume";

class OrderProductList extends Component {
  editField = "inEdit";
  isLoadOrderProductData = false;
  isRejectedData = false;
  isRejectedUpdateData = false;
  isApprovalEditControl = false;
  isApprovalAddControl = false;
  isApprovalRemoveControl = false;

  state = {
    isLoading: false,
    visibleFreeStock: false,
    visibleFreeStockRDD: false,
    visibleNewProduct: false,
    visibleOrderProductImport: false,
    visibleOrderDiscountProductGroup: false,
    orderProductData: this.props.orderProductData,
    visibleCalculateVolume: false,
    editData: null,
    removeData: null,
    brySiparisUrunId: null,
    dealerProductListData:
      this.props.dealerProductGroupList.dealerProductGroups.filter(
        (a) =>
          a.bayI_KODU === this.props.order._order.bayI_KODU &&
          a.satiS_DEPARTMANI_KODU ===
            this.props.order._order.satiS_DEPARTMANI_KODU
      ),
  };

  componentDidUpdate() {
    if (
      this.props.orderProductList.fetching === false &&
      this.isLoadOrderProductData === false
    ) {
      this.setState({
        orderProductData: this.props.orderProductList.orderProducts,
      });
      this.isLoadOrderProductData = true;
    }

    if (this.props.orderProduct.rejectedData && this.isRejectedData === false) {
      this.discard();
      this.isRejectedData = true;
    }

    if (
      this.props.orderProduct.rejectedUpdateData &&
      this.isRejectedUpdateData === true
    ) {
      this.setState({
        orderProductData: this.props.orderProductList.orderProducts,
      });
      this.isRejectedUpdateData = false;
    }

    if (this.isApprovalEditControl && this.props.orderApproval.isApproval) {
      this.isApprovalEditControl = false;
      if (this.props.orderApproval.isApproval === "EVET") {
        this.showMessageApproval();
      }
      if (this.props.orderApproval.isApproval === "HAYIR") {
        this.setOrderProductData();
      }
    }

    if (this.isApprovalAddControl && this.props.orderApproval.isApproval) {
      this.isApprovalAddControl = false;
      if (this.props.orderApproval.isApproval === "EVET") {
        this.showMessageApproval();
      }
      if (this.props.orderApproval.isApproval === "HAYIR") {
        const newDataItem = { inEdit: true, ilavE_ISKONTO: 0 };

        this.setState({
          orderProductData: [newDataItem, ...this.state.orderProductData],
        });
      }
    }

    if (this.isApprovalRemoveControl && this.props.orderApproval.isApproval) {
      this.isApprovalRemoveControl = false;
      if (this.props.orderApproval.isApproval === "EVET") {
        this.showMessageApproval();
      }
      if (this.props.orderApproval.isApproval === "HAYIR") {
        this.isRejectedData = false;
        this.props.deleteOrderProduct(this.state.removeData);
      }
    }
  }

  showMessageApproval = () => {
    defaultAlertMessages.MessageDetailWarning =
      "Sipariş üzerinde bir değişiklik yapabilmeniz için öncelikle sipariş onay sürecini geri almanız gerekmektedir.";
    defaultAlertMessages.Ok = "Tamam";

    confirmAlert({
      customUI: ({ onClose }) => {
        return getWarningMessage(onClose, defaultAlertMessages);
      },
    });
  };

  setOrderProductData = () => {
    this.setState({
      orderProductData: this.state.orderProductData.map((item) =>
        item.brY_SIPARIS_URUN_ID === this.state.editData.brY_SIPARIS_URUN_ID
          ? { ...item, inEdit: true }
          : item
      ),
    });
  };

  CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={this.enterEdit}
      remove={this.remove}
      add={this.add}
      discard={this.discard}
      update={this.update}
      cancel={this.cancel}
      editField={this.editField}
      idField="brY_SIPARIS_URUN_ID"
      nameField="uruN_KODU"
    />
  );

  ilaveIskontoCell = (props) => {
    <GridInlineEditNumericPercentCell {...props} editField={this.editField} />;
  };

  remove = (dataItem) => {
    this.isApprovalRemoveControl = true;
    this.queryApproval();
    this.setState({ removeData: dataItem });
  };

  add = (dataItem) => {
    if (dataItem.adet && dataItem.uruN_KODU) {
      dataItem.brY_SIPARIS_URUN_ID = 0;
      dataItem.brY_SIPARIS_ID = parseInt(this.props.orderId);
      this.props.insertOrderProduct(dataItem);
      dataItem.inEdit = undefined;
    } else {
      alertify.error("Lütfen zorunlu alanları doldurunuz.");
    }
  };

  update = (dataItem) => {
    if (dataItem.adet && dataItem.uruN_KODU) {
      this.props.updateOrderProduct(dataItem);
      dataItem.inEdit = undefined;
      this.isRejectedUpdateData = true;
    } else {
      alertify.error("Lütfen zorunlu alanları doldurunuz.");
    }
  };

  discard = () => {
    const data = [...this.state.orderProductData];
    data.splice(0, 1);
    this.setState({ orderProductData: data });
  };

  cancel = (dataItem) => {
    const originalItem = this.props.orderProductList.orderProducts.find(
      (p) => p.brY_SIPARIS_URUN_ID === dataItem.brY_SIPARIS_URUN_ID
    );
    const data = this.state.orderProductData.map((item) =>
      item.brY_SIPARIS_URUN_ID === originalItem.brY_SIPARIS_URUN_ID
        ? originalItem
        : item
    );

    this.setState({ orderProductData: data });
  };

  queryApproval = () => {
    var satisOnay = {
      ISLEM: "ONAY_SORGULAMA",
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
    };
    this.props.executeOnaySorgula(satisOnay);
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.orderProductData.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.isApprovalEditControl = true;
      this.queryApproval();
      this.setState({ editData: dataItem });
    }
  };

  itemChange = (event) => {
    const data = this.state.orderProductData.map((item) =>
      item.brY_SIPARIS_URUN_ID === event.dataItem.brY_SIPARIS_URUN_ID
        ? {
            ...item,
            [event.field]: event.value,
          }
        : item
    );
    this.setState({ orderProductData: data });
  };

  export = () => {
    this._export.save(
      this.props.orderProductList.orderProducts,
      this._grid.columns
    );
  };

  dataStateChange = (event) => {
    var changeData = process(
      this.state.orderProductData.slice(0),
      event.dataState
    );
    this.setState({ orderProductData: changeData.data });

    this.props.changeOrderProduct(event.dataState);
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  addNewProduct = () => {
    if (this.props.sevkAdresTipi !== "YENI_ADRES") {
      var isOpenEdit = this.state.orderProductData.find(
        (a) => a.inEdit === true
      );
      if (isOpenEdit) {
        defaultAlertMessages.MessageDetailError =
          "Lütfen önce düzenlenen satırı tamamlayınız.";
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      } else {
        this.isApprovalAddControl = true;
        this.queryApproval();
      }
    } else {
      defaultAlertMessages.MessageDetailWarning =
        "Sevk Adres Tipi YENI ADRES seçilidir. Lütfen Adres Bilgilerini giriniz.";
      defaultAlertMessages.Ok = "Tamam";

      confirmAlert({
        customUI: ({ onClose }) => {
          return getWarningMessage(onClose, defaultAlertMessages);
        },
      });
    }
  };

  AutoComleteCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <OrderProductAutoComplete
            dealerProductListData={this.state.dealerProductListData}
            cellProps={props}
            order={this.props.order._order}
          />
        </td>
      );
    } else {
      if (dataItem.puto === "DET") {
        return (
          <td style={{ fontStyle: "italic", color: "gray" }}>
            {dataValue.toString()} ({dataItem.uruN_ADI})
          </td>
        );
      }
      return (
        <td>
          {dataValue.toString()} ({dataItem.uruN_ADI})
        </td>
      );
    }
  };

  ConfDateCell = (props) => {
    var { dataItem, field } = props;
    if (dataItem.inEdit || dataItem.puto === "DET") {
      return <td></td>;
    }
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    return (
      <td>
        {dataValue ? formatDate(new Date(dataValue), "dd.MM.yyyy") : ""}
        {dataValue ? (
          <button
            title="Detay"
            className="btn btn-info btn-sm"
            style={{ marginLeft: "10px" }}
            onClick={() => this.openFreeStockDetail(props.dataItem)}
          >
            <i className="fas fa-info-circle"></i> Detay
          </button>
        ) : undefined}
      </td>
    );
  };

  openFreeStockDetail = (dataItem) => {
    this.setState({
      visibleFreeStock: true,
      brySiparisUrunId: dataItem.brY_SIPARIS_URUN_ID,
    });
  };

  NumberFormatCell = (value, dataItem, isPercent, isKDV) => {
    if (dataItem.inEdit) {
      return <td></td>;
    }
    return (
      <td
        style={{
          fontStyle: dataItem.puto === "DET" ? "italic" : "normal",
          color: dataItem.puto === "DET" ? "gray" : "rgba(0,0,0,0.78)",
        }}
      >
        {isPercent ? "% " : ""}
        <NumberFormat
          thousandSeparator={"."}
          value={value ? (isKDV ? value * 100 : value) : 0}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="5"
        ></NumberFormat>
      </td>
    );
  };

  PutoCellNumber = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return this.NumberFormatCell(dataValue, props.dataItem, false, false);
  };

  TotalPriceCellTurkishLira = (props) => {
    var { dataItem, field } = props;
    var dataValue =
      dataItem[field] === null ? "" : dataItem[field] * this.props.kur;

    return this.NumberFormatCell(dataValue, dataItem, false, false);
  };

  PutoCellPercentNumber = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return this.NumberFormatCell(dataValue, props.dataItem, true, true);
  };

  PutoCellPercent = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return this.NumberFormatCell(dataValue, props.dataItem, true, false);
  };

  FooterSumCell = (props) => {
    var total = this.getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam :<br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        Adet
      </td>
    );
  };

  FooterSumCellCurrency = (props) => {
    var total = this.getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        {this.props.order._order.parA_BIRIMI}
      </td>
    );
  };

  FooterSumCellPercent = (props) => {
    var toplamListeFiyati = this.props.orderProductList.orderProducts
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current["listE_BIRIM_FIYATI_KDV_SIZ"], 0);

    var toplamSatisFiyati = this.props.orderProductList.orderProducts
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current["satiS_BIRIM_FIYAT_KDV_SIZ"], 0);

    var result = Math.round(
      ((toplamListeFiyati - toplamSatisFiyati) / toplamListeFiyati) * 100
    );

    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>%{" "}
        <NumberFormat
          thousandSeparator={"."}
          value={result ? result : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
      </td>
    );
  };

  FooterSumTotalPriceCellTurkishLira = (props) => {
    var total = this.getTotalByField(props.field);
    var result = total * this.props.kur;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={result ? result : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        {" TRY"}
      </td>
    );
  };

  getTotalByField = (field) => {
    return this.props.orderProductList.orderProducts
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current[field], 0);
  };

  toggleDialogOrderProductImport = () => {
    this.setState({
      visibleOrderProductImport: !this.state.visibleOrderProductImport,
    });
  };

  refreshGrid = () => {
    // Burada grid verilerini yeniden yükleme kodunu ekleyin
    // Örneğin, bir API çağrısı yapabilirsiniz.
    this.setState({
      orderProductData: !this.props.getOrderProducts(this.props.orderId),
    }); // Placeholder kodu
  };

  toggleDialogFreeStock = () => {
    this.setState({
      visibleFreeStock: !this.state.visibleFreeStock,
    });
  };

  toggleDialogFreeStockRDD = () => {
    this.setState({
      visibleFreeStockRDD: !this.state.visibleFreeStockRDD,
    });
  };

  toggleDialogOrderDiscountProductGroup = () => {
    this.setState({
      visibleOrderDiscountProductGroup:
        !this.state.visibleOrderDiscountProductGroup,
    });
  };

  assignAutoDatesAll = () => {
    if (this.props.order._order.satiS_ONAY_DURUM === "TEKLIF") {
      this.setState({ isLoading: true });
      FreeStockService.assignFreeStockAutoDates(
        {
          tekliF_SIPARIS: "SIPARIS",
          tekliF_SIPARIS_ID: this.props.orderId,
          islem: "TOPLU_ATAMA",
        },
        this.callbackAfterAutoDatesAll,
        this.onErrorAssignAutoDates
      );
    } else {
      defaultAlertMessages.MessageDetailWarning =
        "Sipariş TEKLIF durumunda değildir. Conf. Date Güncelleme işlemi yapılamaz.";
      defaultAlertMessages.Ok = "Tamam";

      confirmAlert({
        customUI: ({ onClose }) => {
          return getWarningMessage(onClose, defaultAlertMessages);
        },
      });
    }
  };
  callbackAfterAutoDatesAll = () => {
    this.setState({ isLoading: false });
    defaultAlertMessages.MessageDetailSuccess =
      "Conf. Date Güncelleme işlemi başarılı.";
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
    this.props.refreshFunc();
  };
  onErrorAssignAutoDates = () => {
    this.setState({ isLoading: false });
  };

  toggleDialogCalculateVolume = () => {
    this.setState({
      visibleCalculateVolume: !this.state.visibleCalculateVolume,
    });
  };

  render() {
    return (
      <div>
        {this.state.visibleCalculateVolume ? (
          <CalculateVolume
            toggleDialog={this.toggleDialogCalculateVolume}
            orderId={this.props.orderId}
          ></CalculateVolume>
        ) : (
          ""
        )}
        {this.state.visibleOrderProductImport ? (
          <OrderProductImport
            toggleDialog={this.toggleDialogOrderProductImport}
            title="Excel ile Ürün Yükleme"
            orderId={this.props.orderId}
            onUploadSuccess={this.refreshGrid}
          ></OrderProductImport>
        ) : (
          ""
        )}
        {this.state.visibleOrderDiscountProductGroup ? (
          <OrderDiscountProductGroupModal
            toggleDialog={this.toggleDialogOrderDiscountProductGroup}
            title="Discount Ürün Grubu Listesi"
            orderId={this.props.orderId}
          ></OrderDiscountProductGroupModal>
        ) : (
          ""
        )}
        {this.state.visibleFreeStock ? (
          <OrderFreeStock
            toggleDialog={this.toggleDialogFreeStock}
            teklifSiparisId={this.props.orderId}
            teklifSiparisUrunId={this.state.brySiparisUrunId}
          ></OrderFreeStock>
        ) : (
          ""
        )}
        {this.state.visibleFreeStockRDD ? (
          <OrderFreeStockRDD
            toggleDialog={this.toggleDialogFreeStockRDD}
            teklifSiparisId={this.props.orderId}
            teklifSiparisUrunId={this.state.brySiparisUrunId}
          ></OrderFreeStockRDD>
        ) : (
          ""
        )}
        <h4>Sipariş Ürün Listesi</h4>
        {(this.props.orderProductList.fetching || this.state.isLoading) &&
          LoadingPanel}
        <ExcelExport
          data={this.state.orderProductData}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.state.orderProductData}
            {...this.props.orderProductList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={false}
            style={{ height: "600px" }}
            onItemChange={this.itemChange}
            editField={this.editField}
            resizable
            filterable={false}
            confirmat
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={() => this.addNewProduct()}
              >
                {" "}
                <span className="k-icon k-i-plus-circle"></span> Yeni Ürün
              </Button>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              <button
                title="Import Excel"
                className="btn btn-info btn-sm"
                onClick={this.toggleDialogOrderProductImport}
              >
                Excel ile Ürün Yükle
              </button>
              <button
                title="Discount Ürün Grubu"
                className="btn btn-warning btn-sm"
                onClick={this.toggleDialogOrderDiscountProductGroup}
              >
                Ürün Grubu
              </button>
              {this.props.auth.bayiKodu ? undefined : (
                <button
                  title="Teslim Tarihleri Güncelle"
                  className="btn btn-success btn-sm"
                  onClick={this.assignAutoDatesAll}
                >
                  Teslim Tarihleri Güncelle
                </button>
              )}
              {this.props.auth.bayiKodu ? undefined : (
                <button
                  title="Teslim Tarihi Toplu Görüntüleme"
                  className="btn btn-success btn-sm"
                  onClick={this.toggleDialogFreeStockRDD}
                >
                  Teslim Tarihi Toplu Görüntüleme
                </button>
              )}
              <button
                title="Hacim Bilgisi Hesapla"
                className="btn btn-warning btn-sm mr-1"
                onClick={() => this.toggleDialogCalculateVolume()}
              >
                <i class="fas fa-edit"></i> Hacim Bilgisi Hesapla
              </button>
            </GridToolbar>
            <GridColumn
              cell={this.CommandCell}
              width="100px"
              filterable={false}
            />
            <GridColumn
              field="uruN_KODU"
              title="Ürün Kodu/Adı"
              cell={this.AutoComleteCell}
              width="350px"
            ></GridColumn>
            <GridColumn
              field="adet"
              title="Adet"
              width="90px"
              filterable={false}
              editor="numeric"
              footerCell={this.FooterSumCell}
              cell={GridInlineEditNumericCell}
            ></GridColumn>
            <GridColumn
              field="listE_BIRIM_FIYATI_KDV_SIZ"
              title={"Liste Birim Fiyatı"}
              width="100px"
              filterable={false}
              editable={false}
              footerCell={this.FooterSumCellCurrency}
              cell={this.PutoCellNumber}
            ></GridColumn>
            <GridColumn
              field="kdv"
              title="KDV"
              width="60px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercentNumber}
              // format="{0:p}"
            ></GridColumn>
            <GridColumn
              field="listE_BIRIM_FIYATI_KDV_LI"
              title={"KDV'li Liste Birim Fiyatı"}
              width="100px"
              filterable={false}
              editable={false}
              footerCell={this.FooterSumCellCurrency}
              cell={this.PutoCellNumber}
            ></GridColumn>
            <GridColumn
              field="standarT_ISKONTO"
              title="Standart İskonto"
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercent}
            ></GridColumn>
            <GridColumn
              field="kampanyA_ISKONTO"
              title="Kampanya İskonto"
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercent}
            ></GridColumn>
            <GridColumn
              field="ilavE_ISKONTO"
              title="İlave İskonto"
              width="100px"
              filterable={false}
              cell={GridInlineEditNumericPercentCell}
              editor="numeric"
            ></GridColumn>
            <GridColumn
              field="hedeF_TUTAR"
              title="Hedef Tutar"
              width="100px"
              filterable={false}
              cell={GridInlineEditNumericCell}
              editor="numeric"
            ></GridColumn>
            <GridColumn
              field="toplaM_ISKONTO"
              title="Toplam İskonto"
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercent}
              footerCell={this.FooterSumCellPercent}
            ></GridColumn>
            <GridColumn
              field="satiS_BIRIM_FIYAT_KDV_SIZ"
              title={"KDV'siz Birim Fiyat"}
              width="100px"
              filterable={false}
              editable={false}
              footerCell={this.FooterSumCellCurrency}
              cell={this.PutoCellNumber}
            ></GridColumn>
            <GridColumn
              field="toplaM_FIYAT_KDV_SIZ"
              title={"KDV'siz Toplam Fiyat"}
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellNumber}
              footerCell={this.FooterSumCellCurrency}
            ></GridColumn>
            <GridColumn
              field="toplaM_FIYAT_KDV_LI"
              title={"KDV'li Toplam Fiyat"}
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellNumber}
              footerCell={this.FooterSumCellCurrency}
            ></GridColumn>
            {this.props.auth.bayiKodu ? undefined : (
              <GridColumn
                field="maX_RDD_DATE"
                title="Son Teslim Tarihi"
                width="180px"
                cell={this.ConfDateCell}
              ></GridColumn>
            )}
            {this.props.auth.bayiKodu ? undefined : (
              <GridColumn
                field="stoktaN_TESLIM_ADET"
                title="Stoktan Teslim Adet"
                width="180px"
                filterable={false}
                editable={false}
              ></GridColumn>
            )}
          </Grid>
        </ExcelExport>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.orderReducer,
    orderProduct: state.orderProductReducer,
    orderProductList: state.orderProductListReducer,
    dealerProductGroupList: state.dealerProductGroupListReducer,
    orderApproval: state.orderApprovalReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    insertOrderProduct: bindActionCreators(insertOrderProduct, dispatch),
    updateOrderProduct: bindActionCreators(updateOrderProduct, dispatch),
    deleteOrderProduct: bindActionCreators(deleteOrderProduct, dispatch),
    changeOrderProduct: bindActionCreators(changeOrderProduct, dispatch),
    getOrderProducts: bindActionCreators(getOrderProducts, dispatch),
    executeOnaySorgula: bindActionCreators(executeOnaySorgula, dispatch),
    executeOnayIptal: bindActionCreators(executeOnayIptal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderProductList);
