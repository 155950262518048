import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Fragment, cloneElement, useEffect, useRef, useState } from "react";
import {
  BASE_URL,
  DATABRYSATISDEPARTMAN,
  DATAPARABIRIMI,
  DATASAPDURUM,
  DATASEVKARACTIPI,
  DATASEVKEDILMEMEDETAYI,
  DATASEVKEDILMEMENEDENI,
  DATASEVKSEKLI,
  DATASEVKZAMANI,
} from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import OrderService from "../../services/srv-order/OrderService";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

import ExportExcel from "../sys-toolbox/ExcelExport";
import { orderCopGridColumns } from "./OrderCopGridColumns";
import MyOrderUpdateBry from "../cmp-order-my/MyOrderUpdateBry";

export function OrderCopList() {
  const _grid = useRef(null);
  const sort = [
    {
      field: "brY_SIPARIS_ID",
      dir: "desc",
    },
  ];
  const [cexsLoading, setCexsLoading] = useState(false);
  const [cexs, setCexs] = useState(null);
  const [cexsDataState, setCexsDataState] = useState({
    take: 10,
    skip: 0,
    sort,
  });

  const [visibleOrderCopUpdateBry, setVisibleOrderCopUpdateBry] =
    useState(false);
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    getOrderCops();
  }, []);

  function ChangeIdToText(data, id) {
    return data.find((item) => item.id === id)?.text || id;
  }

  function toggleDialogOrderCopUpdateBry(dataItem) {
    setVisibleOrderCopUpdateBry(!visibleOrderCopUpdateBry);
    setTableData(dataItem);
  }

  function onGetCexsCompleted(data) {
    setCexs(data);
    setCexsLoading(false);
  }

  function getOrderCops() {
    setCexsLoading(true);
    OrderService.getOrdersCop(cexsDataState, onGetCexsCompleted);
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setCexsLoading(true);
    setCexsDataState(event.dataState);
    OrderService.getOrdersCop(event.dataState, onGetCexsCompleted);
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  function goOrderDetail(dataItem) {
    window.open(BASE_URL + "order-cop-update/" + dataItem.brY_SIPARIS_ID);
  }

  function SapStatusFilterCell(props) {
    return (
      <DropdownFilterCell
        {...props}
        data={DATASAPDURUM.map((a) => a.id)}
        defaultValue={"Seçiniz"}
      />
    );
  }

  function SalesDepartmantFilterCell(props) {
    return (
      <DropdownFilterCell
        {...props}
        data={DATABRYSATISDEPARTMAN.map((a) => a.text)}
        defaultValue={"Seçiniz"}
      />
    );
  }

  function CurrencyFilterCell(props) {
    return (
      <DropdownFilterCell
        {...props}
        data={DATAPARABIRIMI.map((a) => a.text)}
        defaultValue={"Seçiniz"}
      />
    );
  }

  function onCallbackGeriGonder() {
    getOrderCops();
  }

  return (
    <Fragment>
      {visibleOrderCopUpdateBry ? (
        <MyOrderUpdateBry
          toggleDialog={toggleDialogOrderCopUpdateBry}
          title="Sipariş Güncelle"
          data={{ ...tableData, tekliF_SIPARIS_ID: tableData.brY_SIPARIS_ID }}
          callBack={onCallbackGeriGonder}
        ></MyOrderUpdateBry>
      ) : (
        ""
      )}
      <Card>
        <CardHeader>
          <CardTitle>Bireysel Mop Sipariş Listesi</CardTitle>
        </CardHeader>
        <CardBody>
          {cexsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={cexs}
            dataItemKey={"brY_SIPARIS_ID"}
            {...cexsDataState}
            onDataStateChange={onDataStateChange}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px", fontSize: "11px" }}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 50, 100],
            }}
          >
            <GridToolbar>
              <ExportExcel
                data={cexs}
                columns={orderCopGridColumns}
                loadingFunction={setCexsLoading}
                dataState={cexsDataState}
                serviceMethod={OrderService.getOrdersCopForExcel}
                fileName="CopSiparisListesi"
              ></ExportExcel>
            </GridToolbar>
            <GridColumn
              filterable={false}
              width="200px"
              cell={(props) => (
                <td>
                  <button
                    title="Güncelle"
                    className="btn btn-info btn-sm mr-1"
                    onClick={() =>
                      toggleDialogOrderCopUpdateBry(props.dataItem)
                    }
                  >
                    <i class="fas fa-edit"></i> Güncelle
                  </button>
                  <button
                    title="Detay"
                    className="btn btn-info btn-sm"
                    onClick={() => goOrderDetail(props.dataItem)}
                  >
                    <i className="fas fa-info-circle"></i> Detay
                  </button>
                </td>
              )}
            />
            <GridColumn
              field="brY_SIPARIS_ID"
              title="Sipariş Id"
              filter="numeric"
              width="90px"
            />
            <GridColumn
              field="crE_DATE"
              title="Sipariş Giriş Tarihi"
              width="120px"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
            />
            <GridColumn
              field="moP_GONDERIM_TARIHI"
              title="Sipariş Onay Tarihi"
              width="120px"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
            />
            <GridColumn field="bayI_KODU" title="Bayi Kodu" width="100px" />
            <GridColumn field="bayI_ADI" title="Bayi Adı" width="250px" />
            <GridColumn
              field="satiS_DEPARTMANI_ADI"
              title="Satış Departmanı"
              width="120px"
              filterCell={SalesDepartmantFilterCell}
            />
            <GridColumn field="brY_KANAL" title="Kanal" width="120px" />
            <GridColumn
              field="parA_BIRIMI"
              title="Para Birimi"
              width="100px"
              filterCell={CurrencyFilterCell}
            />

            <GridColumn
              field="saP_FATURA_PARA_BIRIMI"
              width="150px"
              title="Sap Fatura Para Birimi"
              filterCell={CurrencyFilterCell}
            />

            <GridColumn
              field="satiS_TEMSILCISI"
              width="150px"
              title="Satış Temsilcisi"
            />
            <GridColumn
              field="saP_DURUM"
              title="SAP Sipariş Durumu"
              width="120px"
              filterCell={SapStatusFilterCell}
              cell={(props) => {
                return (
                  <td>
                    {ChangeIdToText(DATASAPDURUM, props.dataItem.saP_DURUM)}
                  </td>
                );
              }}
            />
            <GridColumn
              field="saP_SIPARIS_NO"
              title="SAP Sipariş No"
              width="150px"
            />
            <GridColumn
              field="saP_GIRIS_TARIHI"
              title="SAP Giriş Tarihi"
              width="120px"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
            />
            <GridColumn
              field="revizE_SEBEBI"
              title="Revize Sebebi"
              width="150px"
            />
            <GridColumn
              field="revizE_ACIKLAMA"
              title="Revize Açıklama"
              width="150px"
            />
            <GridColumn
              field="soN_ISLEM_TARIHI"
              title="Son İşlem Tarihi"
              width="120px"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
            />
            <GridColumn
              field="sevK_EDILMEME_NEDENI"
              title="Sevk Edilememe Nedeni"
              width="250px"
              filterCell={(props) => {
                return (
                  <DropdownFilterCell
                    {...props}
                    data={DATASEVKEDILMEMENEDENI.map((a) => a.id)}
                    defaultValue={"Lütfen Seçiniz"}
                  />
                );
              }}
              cell={(props) => {
                return (
                  <td>
                    {ChangeIdToText(
                      DATASEVKEDILMEMENEDENI,
                      props.dataItem.sevK_EDILMEME_NEDENI
                    )}
                  </td>
                );
              }}
            />

            <GridColumn
              field="sevK_EDILMEME_DETAYI"
              title="Sevk Edilememe Detayı"
              width="250px"
              filterCell={(props) => {
                return (
                  <DropdownFilterCell
                    {...props}
                    data={DATASEVKEDILMEMEDETAYI.map((a) => a.id)}
                    defaultValue={"Lütfen Seçiniz"}
                  />
                );
              }}
              cell={(props) => {
                return (
                  <td>
                    {ChangeIdToText(
                      DATASEVKEDILMEMEDETAYI,
                      props.dataItem.sevK_EDILMEME_DETAYI
                    )}
                  </td>
                );
              }}
            />

            <GridColumn
              field="sevK_EDILMEME_ACIKLAMA"
              title="Sevk Edilememe Açıklama"
              width="150px"
            />

            <GridColumn
              field="sevK_SEKLI"
              title="Sevk Şekli"
              width="250px"
              filterCell={(props) => {
                return (
                  <DropdownFilterCell
                    {...props}
                    data={DATASEVKSEKLI.map((a) => a.id)}
                    defaultValue={"Lütfen Seçiniz"}
                  />
                );
              }}
              cell={(props) => {
                return (
                  <td>
                    {ChangeIdToText(DATASEVKSEKLI, props.dataItem.sevK_SEKLI)}
                  </td>
                );
              }}
            />

            <GridColumn
              field="sevK_ARAC_TIPI"
              title="Sevk Aracı Tipi"
              width="250px"
              filterCell={(props) => {
                return (
                  <DropdownFilterCell
                    {...props}
                    data={DATASEVKARACTIPI.map((a) => a.id)}
                    defaultValue={"Lütfen Seçiniz"}
                  />
                );
              }}
              cell={(props) => {
                return (
                  <td>
                    {ChangeIdToText(
                      DATASEVKARACTIPI,
                      props.dataItem.sevK_ARAC_TIPI
                    )}
                  </td>
                );
              }}
            />

            <GridColumn
              field="sevK_ZAMANI"
              title="Sevk Zamanı"
              width="250px"
              filterCell={(props) => {
                return (
                  <DropdownFilterCell
                    {...props}
                    data={DATASEVKZAMANI.map((a) => a.id)}
                    defaultValue={"Lütfen Seçiniz"}
                  />
                );
              }}
              cell={(props) => {
                return (
                  <td>
                    {ChangeIdToText(DATASEVKZAMANI, props.dataItem.sevK_ZAMANI)}
                  </td>
                );
              }}
            />
            <GridColumn
              field="rpA_GONDEREN_ADI"
              title="RPA Gönderen"
              width="192px"
            ></GridColumn>
            <GridColumn
              field="toplaM_HACIM"
              title="Toplam Hacim (m³)"
              width="192px"
            ></GridColumn>
          </Grid>
        </CardBody>
      </Card>
    </Fragment>
  );
}
