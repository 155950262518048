import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DATASATISDEPARTMAN } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import {
  fetchSalesApproval,
  fetchSalesApprovalPayload,
} from "../../redux/actions/act-salesApproval/salesApprovalActions";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";
import GridToolTipCell from "../sys-common/GridToolTipCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { process } from "@progress/kendo-data-query";

const primaryKey = "id";
const sort = [
  {
    field: primaryKey,
    dir: "desc",
  },
];

class SalesApprovalList extends Component {
  state = {
    salesApprovalData: [],
  };

  componentDidMount() {
    this.getSalesApprovalList();
  }

  getSalesApprovalList = (search) => {
    var take = this.props.salesApprovalList.dataState
      ? this.props.salesApprovalList.dataState.take
      : 6;
    var skip = this.props.salesApprovalList.dataState
      ? this.props.salesApprovalList.dataState.skip
      : 0;

    this.props.fetchSalesApproval(
      {
        sort,
        take: take,
        skip: skip,
        ...search,
      },
      this.onCompletedCallback
    );
  };

  onCompletedCallback = (data) => {
    this.setState({
      salesApprovalData: data,
    });
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.fetchSalesApproval(event.dataState, this.onCompletedCallback);
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  SalesStatusFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={["ONAY", "RED"]}
      defaultValue={"Seçiniz"}
    />
  );

  StatusFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={["EVET", "HAYIR"]}
      defaultValue={"Seçiniz"}
    />
  );

  TypeFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={["SIPARIS", "TEKLIF"]}
      defaultValue={"Seçiniz"}
    />
  );

  SalesDepartmantFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATASATISDEPARTMAN.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  export = async () => {
    if (this._export !== null) {
      var data = await fetchSalesApprovalPayload({
        ...this.props.salesApprovalList.dataState,
        skip: 0,
        take: 10000,
      });
      this._export.save(data.data.data, this._grid.columns);
    }
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Satış Onay Listesi</h2>
        {this.props.salesApprovalList.fetching && LoadingPanel}

        <ExcelExport
          data={this.props.salesApprovalList.salesApprovals?.data}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.state.salesApprovalData}
            {...this.props.salesApprovalList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            filterable
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
            </GridToolbar>
            <GridColumn
              field={primaryKey}
              title="Teklif/Sipariş Id"
              width="100px"
              editable={false}
              filter={"numeric"}
            ></GridColumn>
            <GridColumn
              field="tip"
              title="Tip"
              width="100px"
              filterCell={this.TypeFilterCell}
            ></GridColumn>
            <GridColumn
              field="satiS_ONAYCI_KULLANICI_ADI"
              title="Satış Onaycı"
              width="180px"
            ></GridColumn>
            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              title="Satış Departmanı"
              filterCell={this.SalesDepartmantFilterCell}
            ></GridColumn>
            <GridColumn
              field="onaY_SIRA_NO"
              title="Onay Sıra No"
              width="80px"
            ></GridColumn>
            <GridColumn
              field="onaY_TIPI"
              title="Onay Tipi"
              width="100px"
            ></GridColumn>
            <GridColumn
              field="onaY_RED"
              title="Onay Red"
              width="100px"
              filterCell={this.SalesStatusFilterCell}
            ></GridColumn>
            <GridColumn
              field="onaY_GELIS_TARIHI"
              title="Onay Geliş Tarihi"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
            ></GridColumn>
            <GridColumn
              field="onaY_RED_TARIHI"
              title="Onay Red Tarihi"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
            ></GridColumn>
            <GridColumn
              field="toplaM_TUTAR"
              title="Toplam Tutar"
              width="100px"
              cell={(props) => GridNumberFormatCell(props, 2)}
              filterable={false}
            ></GridColumn>
            <GridColumn
              field="gecerli"
              title="Geçerli"
              width="100px"
              filterCell={this.StatusFilterCell}
            ></GridColumn>
            <GridColumn
              field="aciklama"
              title="Açıklama"
              cell={GridToolTipCell}
            ></GridColumn>
            <GridColumn
              field="sT_ACIKLAMA"
              title="Satış Temsilcisi Açıklama"
              cell={GridToolTipCell}
            ></GridColumn>
            <GridColumn
              field="crE_DATE"
              title="Oluşturulma Tarihi"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    salesApprovalList: state.salesApprovalListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchSalesApproval: bindActionCreators(fetchSalesApproval, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesApprovalList);
