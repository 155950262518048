import axios from "axios";
import { API_GATEWAY, PR_DEX_MERCURY } from "../../config/globalVariables";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";

class OfferService {
  updateMyOrder(data, callback) {
    return axios
      .put(`${API_GATEWAY + PR_DEX_MERCURY}/teklif/my-order-update`, data)
      .then((response) => {
        defaultAlertMessages.MessageDetailSuccess = response.message;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        if (callback) callback(response);
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
  getOfferProduct(teklifId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_MERCURY
        }/teklifurun/get-offer-product/${teklifId}`
      )
      .then((result) => {
        if (callback) callback(result.data);
        return result.data;
      });
  }
  productReviseUpdate(data, callback) {
    return axios
      .put(
        `${
          API_GATEWAY + PR_DEX_MERCURY
        }/teklifurun/offer-product-revise-update`,
        data
      )
      .then((response) => {
        defaultAlertMessages.MessageDetailSuccess = response.message;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        if (callback) callback(response);
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new OfferService();
