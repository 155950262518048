import {
  DATASEVKARACTIPI,
  DATASEVKEDILMEMEDETAYI,
  DATASEVKEDILMEMENEDENI,
  DATASEVKSEKLI,
  DATASEVKZAMANI,
  DATAYESILBINASERTIFIKA,
} from "../../config/globalVariables";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";

function ChangeIdToText(data, id) {
  return data.find((item) => item.id === id)?.text || id;
}

export const MyOrderGridColumns = [
  {
    field: "tekliF_SIPARIS",
    title: "Teklif/ Sipariş",
    width: "80px",
  },
  {
    field: "tekliF_SIPARIS_ID",
    title: "T/S ID",
    width: "80px",
    filter: "numeric",
  },
  {
    field: "bayI_KODU",
    title: "Bayi Kodu",
    width: "90px",
  },
  {
    field: "bayI_ADI",
    title: "Müşteri Adı",
    width: "150px",
  },
  {
    field: "saP_SIPARIS_NO",
    title: "SAP Sip. No",
    width: "80px",
  },
  // {
  //   field: "crE_DATE",
  //   title: "Teslim Tarihi",
  //   width: "256px",
  //   filter: "date",
  //   filterable: false,
  //   cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
  //   type: "date",
  // },
  // Text yerine açıklama olsun

  {
    field: "sevK_EDILMEME_NEDENI",
    title: "Sevk Edilmeme Nedeni",
    width: "75px",
    cell: (props) => {
      return (
        <td
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {ChangeIdToText(
            DATASEVKEDILMEMENEDENI,
            props.dataItem.sevK_EDILMEME_NEDENI
          )}
        </td>
      );
    },
    filterCell: (props) => {
      return (
        <DropdownFilterCell
          {...props}
          data={DATASEVKEDILMEMENEDENI.filter((item) => item.id !== "-1").map(
            (a) => a.id
          )}
          defaultValue={"Lütfen Seçiniz"}
        />
      );
    },
  },
  {
    field: "sevK_EDILMEME_DETAYI",
    title: "Sevk Edilmeme Detayı",
    width: "75px",
    cell: (props) => {
      return (
        <td
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {ChangeIdToText(
            DATASEVKEDILMEMEDETAYI,
            props.dataItem.sevK_EDILMEME_DETAYI
          )}
        </td>
      );
    },
    filterCell: (props) => {
      return (
        <DropdownFilterCell
          {...props}
          data={DATASEVKEDILMEMEDETAYI.map((a) => a.id)}
          defaultValue={"Lütfen Seçiniz"}
        />
      );
    },
  },
  {
    field: "sevK_EDILMEME_ACIKLAMA",
    title: "Not",
    width: "75px",
  },
  {
    field: "sevK_SEKLI",
    title: "Sevk Şekli",
    width: "75px",
    cell: (props) => {
      return (
        <td>{ChangeIdToText(DATASEVKSEKLI, props.dataItem.sevK_SEKLI)}</td>
      );
    },
    filterCell: (props) => {
      return (
        <DropdownFilterCell
          {...props}
          data={DATASEVKSEKLI.filter((item) => item.id !== "-1").map(
            (a) => a.id
          )}
          defaultValue={"Lütfen Seçiniz"}
        />
      );
    },
  },
  {
    field: "sevK_ARAC_TIPI",
    title: "Sevk Araç Tipi",
    width: "75px",
    cell: (props) => {
      return (
        <td>
          {ChangeIdToText(DATASEVKARACTIPI, props.dataItem.sevK_ARAC_TIPI)}
        </td>
      );
    },
    filterCell: (props) => {
      return (
        <DropdownFilterCell
          {...props}
          data={DATASEVKARACTIPI.filter((item) => item.id !== "-1").map(
            (a) => a.id
          )}
          defaultValue={"Lütfen Seçiniz"}
        />
      );
    },
  },
  {
    field: "sevK_ZAMANI",
    title: "Sevk Zamanı",
    width: "75px",
    cell: (props) => {
      return (
        <td>{ChangeIdToText(DATASEVKZAMANI, props.dataItem.sevK_ZAMANI)}</td>
      );
    },
    filterCell: (props) => {
      return (
        <DropdownFilterCell
          {...props}
          data={DATASEVKZAMANI.filter((item) => item.id !== "-1").map(
            (a) => a.id
          )}
          defaultValue={"Lütfen Seçiniz"}
        />
      );
    },
  },
  {
    field: "montaJ_YAPAN_FIRMA_ADI",
    title: "Montaj Yapan Firma Adı",
    width: "100px",
  },
  {
    field: "yesiL_BINA_SERTIFIKA",
    title: "Yeni Bina Sertifika",
    width: "75px",
    cell: (props) => {
      return (
        <td>
          {ChangeIdToText(
            DATAYESILBINASERTIFIKA,
            props.dataItem.yesiL_BINA_SERTIFIKA
          )}
        </td>
      );
    },
    filterCell: (props) => {
      return (
        <DropdownFilterCell
          {...props}
          data={DATAYESILBINASERTIFIKA.filter((item) => item.id !== "-1").map(
            (a) => a.id
          )}
          defaultValue={"Lütfen Seçiniz"}
        />
      );
    },
  },
  {
    field: "tescillI_MARKA_KODU",
    title: "Tescilli Marka",
    width: "100px",
    cell: (props) => {
      return <td>{props.dataItem.tescillI_MARKA_ADI}</td>;
    },
  },
  {
    field: "projE_ADI",
    title: "Proje Adı",
    width: "100px",
  },
  {
    field: "tesliM_TARIHI",
    title: "Teslim Tarihi",
    width: "100px",
    cell: (props) => {
      return <td>{GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}</td>;
    },
  },
];
