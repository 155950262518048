import {
  MS_USERSDEX,
  MFA,
  CDN_PROJECT_NAME,
  API_GATEWAY,
} from "../../config/globalVariables";
import { ServiceBase, rawRequest } from "../srv-base/ServiceBase";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import {
  serviceMessageError,
  serviceMessageSuccess,
} from "../../helpers/utils";
import axios from "axios";

class UserService extends ServiceBase {
  constructor() {
    super(MS_USERSDEX, "/kullanici");
  }

  getKullanicilar(onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      MS_USERSDEX,
      "/kullanici",
      "/get-all",
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  getKullaniciList(callback) {
    return axios
      .get(`${API_GATEWAY + MS_USERSDEX}/kullanici/get-all`)
      .then((result) => {
        if (callback) {
          callback(result);
        }
        return result;
      });
  }

  getSatisTemsilcileri(onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      MS_USERSDEX,
      "/kullanici",
      "/get-satis-temsilcileri",
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  getAllSatisTemsilcileri(onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      MS_USERSDEX,
      "/kullanici",
      "/get-all-satis-temsilcileri",
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  getDaikinUsers(dataState, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      MS_USERSDEX,
      "/kullanici",
      `/get-daikin-users?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return { data: response.data, dataState };
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  resetPassword(user, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "post",
      MS_USERSDEX,
      "/kullanici",
      "/reset-password",
      user,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  resetAuthenticator(data, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "post",
      MFA,
      "/mfa",
      "/reset-authenticator",
      data,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  getUsersForExcel(dataState, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      MS_USERSDEX,
      "/kullanici",
      `/get-list-for-excel?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new UserService();
