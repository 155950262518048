import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { getProductsByFilter } from "../../redux/actions/act-order/orderProductActions";

const OrderProductAutoComplete = ({ cellProps, order }) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [isEmptyData, setIsEmptyData] = useState(true);

  const dispatch = useDispatch();
  const orderProductOptionList = useSelector(
    (state) => state.orderProductOptionReducer
  );

  useEffect(() => {
    setValue(cellProps.dataItem.uruN_KODU);
  }, [cellProps.dataItem.uruN_KODU]);

  useEffect(() => {
    if (
      orderProductOptionList.products &&
      orderProductOptionList.products.data &&
      !isEmptyData &&
      orderProductOptionList.products.data !== data
    ) {
      setData(orderProductOptionList.products.data);
    }
  }, [orderProductOptionList.products, isEmptyData, data]);

  const onChange = useCallback(
    (event) => {
      setValue(event.value);
      setIsEmptyData(event.value.length <= 2);

      if (event.value.length > 2) {
        const urunKoduAciklamasiFilter = [
          {
            field: "uruN_KODU",
            operator: "contains",
            value: event.value,
          },
          {
            field: "uruN_ADI",
            operator: "contains",
            value: event.value,
          },
        ];

        dispatch(
          getProductsByFilter(
            {
              filter: { logic: "or", filters: urunKoduAciklamasiFilter },
            },
            order.sipariS_TURU,
            order.brY_SIPARIS_ID
          )
        );

        cellProps.onChange({
          dataItem: cellProps.dataItem,
          field: cellProps.field,
          syntheticEvent: event.syntheticEvent,
          value: event.value,
        });
      } else {
        setData([]);
      }
    },
    [cellProps, order, dispatch]
  );

  const itemRender = useCallback((li, itemProps) => {
    const itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "9px", color: "blue" }}>
            ({itemProps.dataItem.uruN_ADI})
          </span>
        </div>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }, []);

  return (
    <AutoComplete
      data={data}
      value={value}
      onChange={onChange}
      style={{ width: "80%" }}
      itemRender={itemRender}
      textField="uruN_KODU"
      placeholder="En az 3 harf giriniz."
    />
  );
};

export default OrderProductAutoComplete;
