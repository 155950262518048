import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Trans } from "react-i18next";
import moment from "moment-timezone";

const date = new Date();

export const getCurrentDateTime = () => {
  let dateStr =
    date.getFullYear() +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getDate()).slice(-2) +
    "T" +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    ":" +
    ("00" + date.getSeconds()).slice(-2);
  return dateStr;
};
export const convertDate = (argDate) => {
  let dateStr = null;
  if (argDate !== null && argDate !== undefined) {
    dateStr =
      argDate.getFullYear() +
      "-" +
      ("00" + (argDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + argDate.getDate()).slice(-2);
  }
  return dateStr;
};

export const convertDateToString = (argDate) => {
  const dateObj = new Date(argDate);
  const month = dateObj.getMonth() + 1;
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  return year + "-" + month + "-" + day;
};

export const convertDateToStringLocaleFormat = (argDate) => {
  const dateObj = new Date(argDate);
  const month = dateObj.getMonth() + 1;
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  return day + "-" + month + "-" + year;
};

export const convertDateToStringLocale = (argDate) => {
  const dateObj = new Date(argDate);
  const date = dateObj.toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return date;
};

export const getCurrentDate = () => {
  return date;
};

export const getDateAgo = (argYear = 0, argMonth = 0, argDay = 0) => {
  let dateAgo = new Date();

  dateAgo.setDate(dateAgo.getDate() - argDay);
  dateAgo.setMonth(dateAgo.getMonth() - argMonth);
  dateAgo.setFullYear(dateAgo.getFullYear() - argYear);

  return dateAgo;
};

export const getGridExcelColums = (data) => {
  if (data !== undefined) {
    const arr = [];
    Object.keys(data).forEach((key) =>
      arr.push({ name: key, value: data[key] })
    );
    const gridColumns = arr.map((column, index) => {
      return (
        <ExcelExportColumn
          key={index}
          field={column.name}
          title={column.name}
        />
      );
    });
    return gridColumns;
  }
};

export const getLocalDate = (value, timezone) => {
  var obj = {
    year: value.getFullYear(),
    month: value.getMonth(),
    day: value.getDate(),
    hour: value.getHours(),
    minute: value.getMinutes(),
    second: value.getSeconds(),
  };

  return moment.tz(obj, timezone).format();
};

export const getLocalDateWithFormat = (value, timezone) => {
  var obj = {
    year: value.getFullYear(),
    month: value.getMonth(),
    day: value.getDate(),
    hour: value.getHours(),
    minute: value.getMinutes(),
    second: value.getSeconds(),
  };

  return moment.tz(obj, timezone).format("YYYY-MM-DDTHH:mm:ss");
};

export const getGridFilterProperties = (argColumns, argValue) => {
  let filterData = {};
  if (argValue === "" || argValue === undefined) {
    filterData = {
      filter: null,
      skip: 0,
    };
  } else {
    let columnsFilter = [];
    columnsFilter = argColumns.map(function (v, i) {
      let column = {
        logic: "and",
        filters: [{ field: v.field, operator: "contains", value: argValue }],
      };
      return column;
    });

    filterData = {
      filter: {
        filters: columnsFilter,
        logic: "or",
      },
      skip: 0,
    };
  }

  return filterData;
};

export const getConfirmErrorMessage = (onClose, objMessageInfo) => {
  return (
    <div className="eConfirm">
      <div className="circle-wrapper">
        <div className="error circle"></div>
        <div className="error-icon icon">
          <i className="fa fa-times"></i>
        </div>
      </div>
      <h2 className="text-center">{objMessageInfo.TittleError}</h2>
      <hr></hr>
      <p>{objMessageInfo.MessageDetailError}</p>
      <div className="eConfirmButtons text-center">
        <button className="k-button btn-primary w-50" onClick={onClose}>
          {objMessageInfo.Ok}
        </button>
      </div>
    </div>
  );
};

export const getWarningMessage = (onClose, objMessageInfo) => {
  return (
    <div className="eConfirm">
      <div className="circle-wrapper">
        <div className="warning circle"></div>
        <div className="warning-icon icon">
          <i className="fa fa-exclamation"></i>
        </div>
      </div>
      <h2 className="text-center">{objMessageInfo.TittleWarning}</h2>
      <hr></hr>
      <p>{objMessageInfo.MessageDetailWarning}</p>
      <div className="eConfirmButtons text-center">
        <button className="k-button btn-primary w-50" onClick={onClose}>
          {objMessageInfo.Ok}
        </button>
      </div>
    </div>
  );
};

export const getConfirmWarningMessage = (
  onClose,
  handleYesClick,
  objMessageInfo,
  objData
  // objFuncExtra
) => {
  return (
    <div className="eConfirm">
      <div className="circle-wrapper">
        <div className="warning circle"></div>
        <div className="warning-icon icon">
          <i className="fa fa-exclamation"></i>
        </div>
      </div>
      <h2 className="text-center">{objMessageInfo.TittleWarning}</h2>
      <hr></hr>
      <p>{objMessageInfo.MessageDetailWarning}</p>
      <div className="eConfirmButtons text-center">
        <button
          className="k-button btn-primary w-50"
          onClick={() => {
            handleYesClick(objData);
            // objFuncExtra();
            onClose();
          }}
        >
          {objMessageInfo.Yes}
        </button>
        <button className="k-button btn-danger w-50" onClick={onClose}>
          {objMessageInfo.No}
        </button>
      </div>
    </div>
  );
};

export const getConfirmSuccessMessage = (onClose, objMessageInfo) => {
  return (
    <div className="eConfirm">
      <div className="circle-wrapper">
        <div className="success circle"></div>
        <div className="success-icon  icon">
          <i className="fa fa-check"></i>
        </div>
      </div>
      <h2 className="text-center">{objMessageInfo.TittleSuccess}</h2>
      <hr></hr>
      <p>{objMessageInfo.MessageDetailSuccess}</p>
      <div className="eConfirmButtons text-center">
        <button className="k-button btn-success w-50" onClick={onClose}>
          {objMessageInfo.Ok}
        </button>
      </div>
    </div>
  );
};

export const getResetPasswordMessage = (objMessageInfo) => {
  return (
    <div className="eConfirm">
      <div className="circle-wrapper">
        <div className="success circle"></div>
        <div className="success-icon  icon">
          <i className="fa fa-check"></i>
        </div>
      </div>
      <h2 className="text-center">{objMessageInfo.TittleSuccess}</h2>
      <hr></hr>
      <p>{objMessageInfo.MessageDetailSuccess}</p>
      <div className="eConfirmButtons text-center">
        <button
          className="k-button btn-success w-50"
          onClick={() => window.location.replace("/homepage")}
        >
          {objMessageInfo.Ok}
        </button>
      </div>
    </div>
  );
};

export let defaultAlertMessages = {
  TittleError: <Trans>Uyarı</Trans>,
  TittleWarning: <Trans>Uyarı</Trans>,
  TittleSuccess: <Trans>Başarılı</Trans>,
  MessageDetailError: <Trans>please_try_again</Trans>,
  MessageDetailWarning: <Trans>sure_continue</Trans>,
  MessageDetailSuccess: <Trans>successfully_completed</Trans>,
  Ok: <Trans>ok</Trans>,
  Yes: <Trans>yes</Trans>,
  No: <Trans>no</Trans>,
};

export const TurkishCharConverter = (char) => {
  var charMap = {
    Ç: "C",
    Ö: "O",
    Ş: "S",
    İ: "I",
    Ü: "U",
    Ğ: "G",
  };

  var str_array = char.split("");

  for (var i = 0, len = str_array.length; i < len; i++) {
    str_array[i] = charMap[str_array[i]] || str_array[i];
  }

  char = str_array.join("");

  // var clearStr = char
  //   .replace(/[^a-z0-9-.çöşüğı]/gi, "");

  return char;
};

export const CheckTCN = (TCKN) => {
  var tek = 0,
    cift = 0,
    sonuc = 0,
    TCToplam = 0,
    hatali = [
      11111111110, 22222222220, 33333333330, 44444444440, 55555555550,
      66666666660, 7777777770, 88888888880, 99999999990,
    ];
  if (TCKN === undefined || TCKN === null) return false;
  if (TCKN.length !== 11) return false;
  if (isNaN(TCKN)) return false;
  if (TCKN[0] === 0) return false;

  tek =
    parseInt(TCKN[0]) +
    parseInt(TCKN[2]) +
    parseInt(TCKN[4]) +
    parseInt(TCKN[6]) +
    parseInt(TCKN[8]);
  cift =
    parseInt(TCKN[1]) +
    parseInt(TCKN[3]) +
    parseInt(TCKN[5]) +
    parseInt(TCKN[7]);

  tek = tek * 7;
  sonuc = Math.abs(tek - cift);
  if ((sonuc % 10).toString() !== TCKN[9]) return false;

  for (var i = 0; i < 10; i++) {
    TCToplam += parseInt(TCKN[i]);
  }

  if ((TCToplam % 10).toString() !== TCKN[10]) return false;

  if (hatali.toString().indexOf(TCKN) !== -1) return false;

  return true;
};

export const CheckTaxNo = (TaxNo) => {
  var v1 = 0;
  var v2 = 0;
  var v3 = 0;
  var v4 = 0;
  var v5 = 0;
  var v6 = 0;
  var v7 = 0;
  var v8 = 0;
  var v9 = 0;
  var v11 = 0;
  var v22 = 0;
  var v33 = 0;
  var v44 = 0;
  var v55 = 0;
  var v66 = 0;
  var v77 = 0;
  var v88 = 0;
  var v99 = 0;
  var v_last_digit = 0;
  var toplam = 0;

  if (TaxNo === undefined || TaxNo === null) return false;

  if (TaxNo.length === 10) {
    v1 = (Number(TaxNo.charAt(0)) + 9) % 10;

    v2 = (Number(TaxNo.charAt(1)) + 8) % 10;

    v3 = (Number(TaxNo.charAt(2)) + 7) % 10;

    v4 = (Number(TaxNo.charAt(3)) + 6) % 10;

    v5 = (Number(TaxNo.charAt(4)) + 5) % 10;

    v6 = (Number(TaxNo.charAt(5)) + 4) % 10;
    v7 = (Number(TaxNo.charAt(6)) + 3) % 10;
    v8 = (Number(TaxNo.charAt(7)) + 2) % 10;
    v9 = (Number(TaxNo.charAt(8)) + 1) % 10;
    v_last_digit = Number(TaxNo.charAt(9));

    v11 = (v1 * 512) % 9;
    v22 = (v2 * 256) % 9;
    v33 = (v3 * 128) % 9;
    v44 = (v4 * 64) % 9;
    v55 = (v5 * 32) % 9;
    v66 = (v6 * 16) % 9;
    v77 = (v7 * 8) % 9;
    v88 = (v8 * 4) % 9;
    v99 = (v9 * 2) % 9;

    if (v1 !== 0 && v11 === 0) v11 = 9;
    if (v2 !== 0 && v22 === 0) v22 = 9;
    if (v3 !== 0 && v33 === 0) v33 = 9;
    if (v4 !== 0 && v44 === 0) v44 = 9;
    if (v5 !== 0 && v55 === 0) v55 = 9;
    if (v6 !== 0 && v66 === 0) v66 = 9;
    if (v7 !== 0 && v77 === 0) v77 = 9;
    if (v8 !== 0 && v88 === 0) v88 = 9;
    if (v9 !== 0 && v99 === 0) v99 = 9;
    toplam = v11 + v22 + v33 + v44 + v55 + v66 + v77 + v88 + v99;

    if (toplam % 10 === 0) toplam = 0;
    else toplam = 10 - (toplam % 10);

    if (toplam === v_last_digit) {
      return true;
    } else return false;
  } else return false;
};

export const CheckEmail = (email) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const parseQueryString = () => {
  var str = window.location.search;
  var objURL = {};
  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    }
  );
  return objURL;
};

export const toExcel = (
  excelExport,
  serviceMethod,
  dataState,
  grid,
  onSuccessCallback,
  onErrorCallback
) => {
  confirmAlert({
    title: "Uyarı",
    message:
      "Listenen veri sayısına göre işlem süresi uzayabilir. Devam etmek istiyor musunuz?",
    buttons: [
      {
        label: "Evet",
        onClick: async () => {
          try {
            var data = await serviceMethod(dataState);

            excelExport.save(data);

            // if (onSuccessCallback) onSuccessCallback();
          } catch (err) {
            if (onErrorCallback) onErrorCallback(err);
          }
        },
      },
      {
        label: "Hayır",
        onClick: () => {},
      },
    ],
  });
};

export const serviceMessageSuccess = (message) => {
  defaultAlertMessages.MessageDetailSuccess = message;
  confirmAlert({
    customUI: ({ onClose }) => {
      return getConfirmSuccessMessage(onClose, defaultAlertMessages);
    },
  });
};

export const serviceMessageError = (message) => {
  defaultAlertMessages.MessageDetailError = message;
  confirmAlert({
    customUI: ({ onClose }) => {
      return getConfirmErrorMessage(onClose, defaultAlertMessages);
    },
  });
};

export const replacePleaseSelect = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const newItem = { ...item };

      Object.keys(newItem).forEach((key) => {
        if (newItem[key] === -1 || newItem[key] === "-1") {
          newItem[key] = null;
        }
      });

      return newItem;
    });
  }
  if (typeof data === "object") {
    const newData = { ...data };
    Object.keys(newData).forEach((key) => {
      if (newData[key] === -1 || newData[key] === "-1") {
        newData[key] = null;
      }
    });

    return newData;
  }
};
