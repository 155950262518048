import { filterBy } from "@progress/kendo-data-query";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { bindActionCreators } from "redux";
import {
  DATABANKATAKSIT,
  DATABRYSATISDEPARTMAN,
  DATAEXPORTBOLGE,
  DATAEXPORTSIPARISTIPI,
  DATAEXPORTSIPARISTURU,
  DATAFATURAVADESI,
  DATAINCOTERMS,
  DATALISTEVADESI,
  DATAODEMETIPI,
  DATAODEMEVADESI,
  DATAPARABIRIMI,
  DATAPLANT,
  DATASEVKADRESITIPI,
  DATASIPARISTURU,
  DATASEVKEDILMEMENEDENI,
  DATASEVKEDILMEMEDETAYI,
  DATASEVKSEKLI,
  DATASEVKARACTIPI,
  DATASEVKZAMANI,
  DATAFATURAKURU,
  DATAREVIZENEDENLERI,
  DATAMAXODEMEVADESI,
  DATAARTIGUN,
  DATAEXPORTSEVKSEKLI,
} from "../../config/globalVariables";
import { getDbsBanks } from "../../redux/actions/act-bank/bankActions";
import { getBankInstallment } from "../../redux/actions/act-bry-bankInstallment/bankInstallmentActions";
import { getCampaignsByDate } from "../../redux/actions/act-bry-campaign/campaignActions";
import { getCampaignDealersByBayiKodu } from "../../redux/actions/act-bry-campaign/campaignDealerActions";
import { getChannels } from "../../redux/actions/act-bry-channel/channelActions";
import { getDealerProductGroupByBayiKodu } from "../../redux/actions/act-bry-dealerProductGroup/dealerProductGroupActions";
import { getCountries } from "../../redux/actions/act-country/countryActions";
import { getDealers } from "../../redux/actions/act-dealer/dealerActions";
import {
  setDealerToOrder,
  setOrder,
  setFaturaVadesi,
  setCampaignToOrder,
} from "../../redux/actions/act-order/orderActions";
import { getPaymentTerms } from "../../redux/actions/act-paymentTerm/paymentTermActions";
import { getPaymentExpiry } from "../../redux/actions/act-paymentExpiry/paymentExpiryActions";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import CustomerService from "../../services/srv-customer/CustomerService";

class OrderProps extends Component {
  isLoadCampaignDealers = false;
  requestStarted = false;
  pendingRequest;
  state = {
    bryChannels: this.props.bryChannels.channels,
    isLoadChannels: false,
    dealerProductGroupList: [],
    campaignData: [],
    campaignDetail: {},
    departmantList: [],
    countries: [],
    paymentTerms: [],
    addressList: [],
    paymentExpiry: [],
    kampanyaKosul: "",
    loadingCountry: false,
    loadingPaymentTerm: false,
  };

  componentDidMount() {
    if (
      this.props.order._order.bayI_KODU ||
      (this.props.auth.bayiKodu && this.props.auth.bayiKodu !== "")
    ) {
      var filter = [];
      var bayiKodu = this.props.orderData.bayI_KODU
        ? this.props.orderData.bayI_KODU
        : this.props.auth.bayiKodu;

      filter.push({
        field: "BAYI_KODU",
        operator: "eq",
        value: bayiKodu,
      });
      this.props.getDealers({
        filter: { logic: "or", filters: filter },
      });

      this.props.setDealerToOrder(bayiKodu);
      this.filterSalesDepartments(bayiKodu);
    } else {
      var take = this.props.dealerList.dataState
        ? this.props.dealerList.dataState.take
        : 100;
      var skip = this.props.dealerList.dataState
        ? this.props.dealerList.dataState.skip
        : 0;
      this.props.getDealers({
        take: take,
        skip: skip,
      });
    }

    this.props.getChannels();
    this.props.getDbsBanks();
    this.props.getBankInstallment();
    this.props.getCampaignsByDate(this.props.orderData.crE_DATE);
    this.props.getCountries();
    this.props.getPaymentTerms();
    this.props.getPaymentExpiry();
    if (this.props.order._order.bayI_KODU) {
      this.props.getCampaignDealersByBayiKodu(
        this.props.order._order.bayI_KODU
      );
      this.props.getDealerProductGroupByBayiKodu(
        this.props.order._order.bayI_KODU
      );
    }
  }

  componentDidUpdate() {
    if (
      this.props.bryCampaignDealerList.fetching === false &&
      this.isLoadCampaignDealers === false &&
      this.props.campaignListAll.fetching === false &&
      this.props.paymentTermList.fetching === false &&
      this.props.paymentExpiryList.fetching === false &&
      this.props.countryList.fetching === false
    ) {
      this.isLoadCampaignDealers = true;
      var campaings = [
        ...new Set(
          this.props.bryCampaignDealerList.campaignDealers.map(
            (a) => a.kampanyA_KODU
          )
        ),
      ];

      var campaignData = this.props.campaignListAll.campaigns.filter((a) =>
        campaings.includes(a.kampanyA_KODU)
      );

      var selectedCampaign = this.props.campaignListAll.campaigns.find(
        (a) => a.kampanyA_KODU === this.props.order._order.kampanyA_KODU
      );

      this.setState({
        paymentTerms: this.props.paymentTermList.paymentTerms,
        paymentExpiry: this.props.paymentExpiryList.paymentExpiry,
        countries: this.props.countryList.countries,
        campaignData: campaignData,
        kampanyaKosul: selectedCampaign ? selectedCampaign.kampanyA_KOSULU : "",
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.dealerProductGroupList !==
      nextProps.dealerProductGroupList.dealerProductGroups
    ) {
      var departmants = [
        ...new Set(
          nextProps.dealerProductGroupList.dealerProductGroups.map(
            (a) => a.satiS_DEPARTMANI_KODU
          )
        ),
      ];

      var filteredChannelList =
        nextProps.dealerProductGroupList.dealerProductGroups
          .filter(
            (a) =>
              a.satiS_DEPARTMANI_KODU ===
              nextProps.order._order.satiS_DEPARTMANI_KODU
          )
          .map((x) => x.brY_KANAL);

      var filteredData =
        filteredChannelList.length > 0
          ? nextProps.bryChannels.channels.filter((x) =>
              filteredChannelList.includes(x.brY_KANAL)
            )
          : [];

      return {
        departmantList: DATABRYSATISDEPARTMAN.filter((a) =>
          departmants.includes(a.id)
        ),
        dealerProductGroupList:
          nextProps.dealerProductGroupList.dealerProductGroups,
        bryChannels: filteredData,
      };
    }
  }

  filterChangePaymentTerm = (event) => {
    clearTimeout(this.timeout2);
    this.timeout2 = setTimeout(() => {
      this.setState({
        paymentTerms: filterBy(
          this.props.paymentTermList.paymentTerms,
          event.filter
        ),
        loadingPaymentTerm: false,
      });
    }, 500);

    this.setState({
      loadingPaymentTerm: true,
    });
  };

  filterChangeCountry = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        countries: filterBy(this.props.countryList.countries, event.filter),
        loadingCountry: false,
      });
    }, 500);

    this.setState({
      loadingCountry: true,
    });
  };

  dealerFilter = (event) => {
    if (this.props.dealerList.fetching) {
      clearTimeout(this.pendingRequest);
      this.pendingRequest = setTimeout(() => {
        this.dealerFilter(event);
      }, 50);
      return;
    }

    var bayiFilter = [];
    bayiFilter.push({
      field: "bayI_KODU",
      operator: "contains",
      value: event.filter.value,
    });
    bayiFilter.push(event.filter);
    this.props.getDealers({
      filter: { logic: "or", filters: bayiFilter },
    });
  };

  getDealersByFilter = (event) => {
    if (event.filter.value.length > 2) {
      this.dealerFilter(event);
    }
  };

  onChange = (event) => {
    this.props.setOrder(event);
  };

  onChangeSiparisTuru = (event) => {
    this.props.setOrder(event);
  };

  onChangeCampaign = (event) => {
    this.props.setCampaignToOrder(event.value);
    this.setState({
      kampanyaKosul: event.value.kampanyA_KOSULU,
      campaignDetail: event.value,
    });
  };

  onChangeListeFiyatiVadesi = (e) => {
    var faturaVadesi = null;
    if (this.props.order._order.odemE_VADESI_TIPI === "FATURA_VADESI") {
      faturaVadesi = this.getFaturaVadesi(e.value.id);
    }

    if (
      this.props.order._order.sipariS_TURU === "KAMPANYA" &&
      this.props.order._order.odemE_VADESI_TIPI === "FATURA_VADESI"
    ) {
      if (
        this.props.order._order.artI_GUN !== null &&
        (e.value.id !== "0" || e.value.id !== "1")
      ) {
        if (this.props.order._order.maX_ODEME_VADESI !== null) {
          faturaVadesi =
            parseFloat(e.value.id) +
              parseFloat(this.props.order._order.artI_GUN) <
            parseFloat(this.props.order._order.maX_ODEME_VADESI)
              ? parseFloat(e.value.id) +
                parseFloat(this.props.order._order.artI_GUN)
              : parseFloat(this.props.order._order.maX_ODEME_VADESI);
        } else {
          faturaVadesi =
            parseFloat(e.value.id) +
            parseFloat(this.props.order._order.artI_GUN);
        }
      }
      if (e.value.id === "0" && this.props.order._order.artI_GUN !== null) {
        faturaVadesi = this.props.order._order.artI_GUN;
      }
      if (e.value.id === "1" && this.props.order._order.artI_GUN !== null) {
        faturaVadesi = this.props.order._order.artI_GUN;
      }
    }

    this.props.setFaturaVadesi(faturaVadesi);
    this.props.setOrder(e);
  };

  onChangeOdemeVadesiTipi = (e) => {
    var faturaVadesi = null;
    if (e.value.id === "FATURA_VADESI") {
      faturaVadesi = this.getFaturaVadesi(
        this.props.order._order.listE_FIYATI_VADESI
      );
    }
    this.props.setFaturaVadesi(faturaVadesi);
    this.props.setOrder(e);
  };

  onChangeIncoterms = (e) => {
    const filteredText = e.value.replace(/[ÇĞİÖŞÜçğıöşüa-z]/g, "");
    e.value = filteredText;
    this.props.setOrder(e);
  };

  getFaturaVadesi = (value) => {
    var faturaVadesi = null;
    var dealer = this.props.dealerList.dealers.data.find(
      (a) => a.bayI_KODU === this.props.order._order.bayI_KODU
    );
    var selected = this.state.paymentExpiry.find(
      (s) =>
        s.listE_FIYATI_VADESI.toString() === value.toString() &&
        s.brY_MUSTERI_GRUBU === dealer.brY_MUSTERI_GRUBU
    );
    if (selected) {
      faturaVadesi = selected.faturA_VADESI;
    }

    if (
      this.props.order._order.sipariS_TURU === "KAMPANYA" &&
      this.props.order._order.artI_GUN === null &&
      this.props.order._order.maX_ODEME_VADESI !== null &&
      faturaVadesi > this.props.order._order.maX_ODEME_VADESI
    ) {
      faturaVadesi = this.props.order._order.maX_ODEME_VADESI;
    }

    return faturaVadesi;
  };

  filterChannels = (departmant) => {
    var filteredChannelList =
      this.props.dealerProductGroupList.dealerProductGroups
        .filter((a) => a.satiS_DEPARTMANI_KODU === departmant)
        .map((x) => x.brY_KANAL);

    var filteredData =
      filteredChannelList.length > 0
        ? this.props.bryChannels.channels.filter((x) =>
            filteredChannelList.includes(x.brY_KANAL)
          )
        : [];

    this.setState({
      bryChannels: filteredData,
    });
  };

  onChangeDepartman = (event) => {
    this.filterChannels(event.value.id);
    this.onChange(event);
  };

  onChangeDealer = (event) => {
    this.onChange(event);
    this.filterSalesDepartments(event.value.bayI_KODU);
  };

  onChangeSevkAdresiTipi = (event) => {
    this.onChange(event);
  };

  filterSalesDepartments = (bayiKodu) => {
    this.props.getCampaignDealersByBayiKodu(bayiKodu);
    this.props.getDealerProductGroupByBayiKodu(bayiKodu);
    CustomerService.getCustomerAddressByBayiKodu(
      bayiKodu,
      this.defaultDataHandler
    );
    this.isLoadCampaignDealers = false;
  };

  defaultDataHandler = (data) => {
    this.setState({
      addressList: [...DATASEVKADRESITIPI, ...data],
    });
  };

  itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.bayI_KODU})
          </span>
        </div>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  render() {
    const data = this.props.orderData;
    const bayiKodu = this.props.auth.bayiKodu
      ? this.props.auth.bayiKodu
      : data.bayI_KODU;

    const maxTaksitSayisi = data.dkN_FIN_BANKA_LIST_ID
      ? this.props.bankInstallmentList.bankInstallments.find(
          (s) => s.dkN_FIN_BANKA_LIST_ID === data.dkN_FIN_BANKA_LIST_ID
        )
      : undefined;

    return (
      <form onSubmit={this.props.submitFunc} className="k-form">
        <Row>
          <Col lg="4" md="4" xs="12">
            {this.props.orderId === "0" ? undefined : (
              <Input
                label="Sipariş No"
                name="brY_SIPARIS_ID"
                id="brY_SIPARIS_ID"
                style={{ width: "100%" }}
                disabled={true}
                value={data.brY_SIPARIS_ID}
              ></Input>
            )}
            {/*  ÖNEMLİ : BAYİ KULLANICISI HİÇBİR ZAMAN BURDA SEÇİM YAPAMAMALI, BAŞKA BAYİYİ SEÇEBİLİR HALE GELİRSE BÜYÜK PROBLEM YARATIR. */}
            <DropDownList
              name="bayI_KODU"
              placeholder="Lütfen Seçiniz"
              data={this.props.dealerList.dealers.data}
              label="Bayi Adı"
              style={{ width: "100%" }}
              onChange={this.onChangeDealer}
              textField="bayI_ADI"
              dataItemKey="bayI_KODU"
              itemRender={this.itemRender}
              value={
                this.props.dealerList.dealers.data
                  ? {
                      ...this.props.dealerList.dealers.data.filter(
                        (x) => x.bayI_KODU === bayiKodu
                      )[0],
                    }
                  : null
              }
              disabled={this.props.auth.bayiKodu}
              loading={this.props.dealerList.fetching}
              filterable={true}
              required={true}
              onFilterChange={(event) => this.getDealersByFilter(event)}
            />
            {this.props.dealerProductGroupList.fetching ? undefined : (
              <DropDownList
                name="satiS_DEPARTMANI_KODU"
                label="Ana Ürün Grubu"
                data={this.state.departmantList}
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={this.onChangeDepartman}
                required={true}
                disabled={!bayiKodu}
                // loading={this.props.dealerProductGroupList.fetching}
                value={
                  data.satiS_DEPARTMANI_KODU
                    ? this.state.departmantList.find(
                        (a) => a.id === data.satiS_DEPARTMANI_KODU
                      )
                    : ""
                }
              />
            )}
            <DropDownList
              name="brY_KANAL"
              label="Marka"
              data={this.state.bryChannels}
              textField="brY_KANAL"
              dataItemKey="brY_KANAL"
              style={{ width: "100%" }}
              onChange={this.onChange}
              disabled={!data.satiS_DEPARTMANI_KODU}
              // required={true}
              value={
                data.brY_KANAL
                  ? this.state.bryChannels.find(
                      (a) => a.brY_KANAL === data.brY_KANAL
                    )
                  : ""
              }
            />
            {this.props.orderId === "0" ? undefined : (
              <Input
                label="Satış Onay Durum"
                name="satiS_ONAY_DURUM"
                id="satiS_ONAY_DURUM"
                style={{ width: "100%" }}
                disabled={true}
                value={data.satiS_ONAY_DURUM}
              ></Input>
            )}
            {this.props.orderId === "0" ? undefined : (
              <DateInput
                label="Sipariş Giriş Tarihi"
                format="dd.MM.yyyy HH:mm"
                formatPlaceholder="formatPattern"
                value={new Date(data.crE_DATE)}
                style={{ width: "100%" }}
                disabled={true}
              />
            )}
            <DropDownList
              name="parA_BIRIMI"
              label="Para Birimi"
              data={DATAPARABIRIMI}
              textField="text"
              dataItemKey="id"
              style={{ width: "100%" }}
              onChange={this.onChange}
              required={true}
              value={
                data.parA_BIRIMI
                  ? DATAPARABIRIMI.find((a) => a.id === data.parA_BIRIMI)
                  : DATAPARABIRIMI.find((a) => a.id === "TRY")
              }
            />
            {this.props.orderId === "0" ? undefined : (
              <Input
                label="Satış Temsilcisi"
                name="satiS_TEMSILCISI"
                id="satiS_TEMSILCISI"
                style={{ width: "100%" }}
                value={data.satiS_TEMSILCISI}
                disabled={true}
              ></Input>
            )}
            {data.kopyalanaN_SIPARIS_ID ? (
              <Input
                label="Kopyalanan Sipariş No"
                name="kopyalanaN_SIPARIS_ID"
                id="kopyalanaN_SIPARIS_ID"
                style={{ width: "100%" }}
                value={data.kopyalanaN_SIPARIS_ID}
                disabled={true}
              ></Input>
            ) : undefined}
            {data.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
              <div>
                {" "}
                <NumericTextBox
                  label="Freight"
                  name="navluN_FIYATI"
                  id="navluN_FIYATI"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  value={data.navluN_FIYATI ? data.navluN_FIYATI : 0}
                ></NumericTextBox>
                <NumericTextBox
                  label="Insurance"
                  name="sigortA_UCRETI"
                  id="sigortA_UCRETI"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  value={data.sigortA_UCRETI ? data.sigortA_UCRETI : 0}
                ></NumericTextBox>
              </div>
            ) : undefined}
            <Input
              label="Purchase Order Number"
              name="satiN_ALMA_SIPARIS_NO"
              id="satiN_ALMA_SIPARIS_NO"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={data.satiN_ALMA_SIPARIS_NO}
            ></Input>
            {data.sipariS_TARIH_ONAY !== null && (
              <Input
                label="Sipariş Tarih Onay"
                name="sipariS_TARIH_ONAY"
                id="sipariS_TARIH_ONAY"
                style={{ width: "100%" }}
                value={data.sipariS_TARIH_ONAY}
                readOnly={true}
              ></Input>
            )}
          </Col>
          <Col lg={{ size: 4 }} md="4" xs="12">
            <DropDownList
              name="sipariS_TURU"
              label="Sipariş Türü"
              data={DATASIPARISTURU}
              textField="text"
              dataItemKey="id"
              style={{ width: "100%" }}
              onChange={this.onChangeSiparisTuru}
              required={true}
              disabled={
                this.props.orderId !== "0" ||
                data.satiS_DEPARTMANI_KODU === "EXPORT_UPG"
              }
              value={
                data.sipariS_TURU
                  ? DATASIPARISTURU.find((a) => a.id === data.sipariS_TURU)
                  : ""
              }
            />
            {data.sipariS_TURU === "KAMPANYA" ? (
              <div>
                {" "}
                <DropDownList
                  name="kampanyA_KODU"
                  label="Kampanya"
                  data={this.state.campaignData}
                  textField="kampanyA_ADI"
                  dataItemKey="kampanyA_KODU"
                  style={{ width: "100%" }}
                  onChange={this.onChangeCampaign}
                  disabled={!data.bayI_KODU}
                  required={true}
                  value={
                    data.sipariS_TURU === "KAMPANYA"
                      ? this.state.campaignData.find(
                          (a) => a.kampanyA_KODU === data.kampanyA_KODU
                        )
                      : ""
                  }
                />
                <Input
                  label="Kampanya Bilgisi"
                  name="kampanyaKosul"
                  id="kampanyaKosul"
                  style={{ width: "100%" }}
                  value={this.state.kampanyaKosul}
                  disabled={true}
                ></Input>{" "}
              </div>
            ) : undefined}

            {data.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
              <div>
                <DropDownList
                  name="exporT_SIPARIS_TIPI"
                  label="Export Sipariş Tipi"
                  data={DATAEXPORTSIPARISTIPI}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  required={true}
                  value={
                    data.exporT_SIPARIS_TIPI
                      ? DATAEXPORTSIPARISTIPI.find(
                          (a) => a.id === data.exporT_SIPARIS_TIPI
                        )
                      : ""
                  }
                />
                <DropDownList
                  name="exporT_SIPARIS_TURU"
                  label="Export Sipariş Türü"
                  data={DATAEXPORTSIPARISTURU}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  required={true}
                  value={
                    data.exporT_SIPARIS_TURU
                      ? DATAEXPORTSIPARISTURU.find(
                          (a) => a.id === data.exporT_SIPARIS_TURU
                        )
                      : ""
                  }
                />
                <DropDownList
                  name="exporT_BOLGE_KODU"
                  label="Export Bölge Kodu"
                  data={DATAEXPORTBOLGE}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  required={true}
                  value={
                    data.exporT_BOLGE_KODU
                      ? DATAEXPORTBOLGE.find(
                          (a) => a.id === data.exporT_BOLGE_KODU
                        )
                      : ""
                  }
                />
                <DropDownList
                  name="incoterms"
                  label="Incoterms"
                  data={DATAINCOTERMS}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  required={true}
                  value={
                    data.incoterms
                      ? DATAINCOTERMS.find((a) => a.id === data.incoterms)
                      : ""
                  }
                />
                <div>Incoterms2</div>
                <TextArea
                  placeholder="Incoterms 2"
                  id="incotermS2"
                  name="incotermS2"
                  autoSize={false}
                  style={{ width: "100%" }}
                  onChange={this.onChangeIncoterms}
                  value={data.incotermS2}
                ></TextArea>
                <DropDownList
                  name="sevK_YAPILACAK_ULKE_TANIM_ID"
                  label="Sevk Yapılacak Ülke"
                  data={this.state.countries}
                  textField="ulkE_ADI"
                  dataItemKey="ulkE_TANIM_ID"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  required={true}
                  filterable={true}
                  onFilterChange={this.filterChangeCountry}
                  loading={this.state.loadingCountry}
                  value={
                    data.sevK_YAPILACAK_ULKE_TANIM_ID
                      ? this.state.countries.find(
                          (a) =>
                            a.ulkE_TANIM_ID ===
                            data.sevK_YAPILACAK_ULKE_TANIM_ID
                        )
                      : ""
                  }
                />
                <DropDownList
                  name="odemE_KOSULU"
                  label="Ödeme Koşulu"
                  data={this.state.paymentTerms}
                  textField="kosuL_ACIKLAMA"
                  dataItemKey="kosuL_KODU"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  required={true}
                  filterable={true}
                  onFilterChange={this.filterChangePaymentTerm}
                  loading={this.state.loadingPaymentTerm}
                  value={
                    data.odemE_KOSULU
                      ? this.state.paymentTerms.find(
                          (a) => a.kosuL_KODU === data.odemE_KOSULU
                        )
                      : ""
                  }
                />
                {data.exporT_SIPARIS_TIPI === "KB" ? (
                  <DatePicker
                    label="Konsinye Kapama Tarihi"
                    name="konsinyE_KAPAMA_TARIHI"
                    format="dd.MM.yyyy"
                    formatPlaceholder="formatPattern"
                    min={new Date()}
                    value={
                      data.konsinyE_KAPAMA_TARIHI != null
                        ? data.konsinyE_KAPAMA_TARIHI === ""
                          ? null
                          : new Date(data.konsinyE_KAPAMA_TARIHI)
                        : null
                    }
                    onChange={this.onChange}
                    style={{ width: "100%" }}
                    required
                  />
                ) : undefined}
              </div>
            ) : undefined}

            {data.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
              <DropDownList
                name="listE_FIYATI_VADESI"
                label="Liste Fiyatı Vadesi"
                data={DATALISTEVADESI}
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={this.onChangeListeFiyatiVadesi}
                required={true}
                disabled={
                  this.props.orderData.sipariS_TURU === "DTAS_OTHERS"
                    ? false
                    : this.props.orderData.sipariS_TURU === "KAMPANYA" &&
                      this.props.auth.bayiKodu &&
                      this.state.campaignDetail.listE_FIYATI_VADESI !== null
                }
                value={
                  data.satiS_DEPARTMANI_KODU === "EXPORT_UPG"
                    ? ""
                    : data.listE_FIYATI_VADESI || data.listE_FIYATI_VADESI === 0
                    ? DATALISTEVADESI.find(
                        (a) => a.id === data.listE_FIYATI_VADESI.toString()
                      )
                    : ""
                }
              />
            ) : undefined}
            {data.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
              <DropDownList
                name="odemE_VADESI_TIPI"
                label="Ödeme Vadesi Tipi"
                data={DATAODEMEVADESI}
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={this.onChangeOdemeVadesiTipi}
                required={true}
                value={
                  data.odemE_VADESI_TIPI
                    ? DATAODEMEVADESI.find(
                        (a) => a.id === data.odemE_VADESI_TIPI
                      )
                    : ""
                }
              />
            ) : undefined}

            {data.odemE_VADESI_TIPI === "FATURA_VADESI" ? (
              <DropDownList
                name="faturA_VADESI"
                label="Ödeme Vadesi"
                data={DATAFATURAVADESI}
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                required={true}
                disabled={
                  this.props.orderData.sipariS_TURU === "DTAS_OTHERS"
                    ? false
                    : this.props.auth.bayiKodu
                }
                onChange={this.onChange}
                value={
                  data.faturA_VADESI || data.faturA_VADESI === 0
                    ? DATAFATURAVADESI.find(
                        (a) => a.id === data.faturA_VADESI.toString()
                      )
                    : ""
                }
              />
            ) : undefined}

            {data.odemE_VADESI_TIPI === "NOKTA_VADE" ? (
              <div>
                <div>Ödeme Vadesi Tarihi</div>
                <DatePicker
                  label="Ödeme Vadesi Tarihi"
                  name="odemE_VADE_TARIHI"
                  format="dd.MM.yyyy"
                  formatPlaceholder="formatPattern"
                  min={new Date()}
                  value={
                    data.odemE_VADE_TARIHI != null
                      ? data.odemE_VADE_TARIHI === ""
                        ? null
                        : new Date(data.odemE_VADE_TARIHI)
                      : null
                  }
                  onChange={this.onChange}
                  style={{ width: "100%" }}
                  required={true}
                />
              </div>
            ) : undefined}
            <DropDownList
              name="odemE_TIPI"
              label="Ödeme Tipi"
              data={
                data.satiS_DEPARTMANI_KODU === "EXPORT_UPG"
                  ? DATAODEMETIPI
                  : DATAODEMETIPI.filter((a) => a.type !== "EXPORT")
              }
              textField="text"
              dataItemKey="id"
              style={{ width: "100%" }}
              onChange={this.onChange}
              required={true}
              value={
                data.odemE_TIPI
                  ? DATAODEMETIPI.find((a) => a.id === data.odemE_TIPI)
                  : ""
              }
            />
            {/* {data.parA_BIRIMI !== "TRY" ? ( */}
            <div>
              <DropDownList
                name="faturA_KURU"
                label="Fatura Para Birimi"
                data={DATAFATURAKURU}
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={this.onChange}
                required={true}
                value={
                  data.faturA_KURU
                    ? DATAFATURAKURU.find((a) => a.id === data.faturA_KURU)
                    : ""
                }
              />
            </div>
            {/* ) : undefined} */}

            {data.odemE_TIPI === "DBS" ||
            data.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
              <DropDownList
                name="dkN_FIN_BANKA_LIST_ID"
                label="Banka"
                data={
                  data.satiS_DEPARTMANI_KODU.includes("EXPORT")
                    ? this.props.bankList.banks
                    : this.props.bankList.banks.filter(
                        (b) => b.rpA_DBS_BANKA_ADI != null
                      )
                }
                textField="bankA_ADI"
                dataItemKey="dkN_FIN_BANKA_LIST_ID"
                style={{ width: "100%" }}
                onChange={this.onChange}
                required={true}
                value={
                  data.dkN_FIN_BANKA_LIST_ID
                    ? this.props.bankList.banks.find(
                        (a) =>
                          a.dkN_FIN_BANKA_LIST_ID === data.dkN_FIN_BANKA_LIST_ID
                      )
                    : ""
                }
              />
            ) : undefined}
            {maxTaksitSayisi && data.satiS_DEPARTMANI_KODU !== "EXPORT_UPG" ? (
              <DropDownList
                name="taksiT_SAYISI"
                label="Taksit Sayısı"
                data={
                  maxTaksitSayisi
                    ? DATABANKATAKSIT.filter(
                        (a) => a.id <= maxTaksitSayisi.taksiT_SAYISI
                      )
                    : null
                }
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={this.onChange}
                required={true}
                value={
                  data.taksiT_SAYISI
                    ? DATABANKATAKSIT.find(
                        (a) => a.id === data.taksiT_SAYISI.toString()
                      )
                    : ""
                }
              />
            ) : undefined}
            {data.satiS_ONAY_DURUM === "YENI" && data.revizE_SEBEBI ? (
              <div style={{ border: "dashed red", padding: "10px" }}>
                <DropDownList
                  name="revizE_SEBEBI"
                  label="Revize Nedeni"
                  data={DATAREVIZENEDENLERI}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  disabled={true}
                  value={
                    data.revizE_SEBEBI
                      ? DATAREVIZENEDENLERI.find(
                          (a) => a.id === data.revizE_SEBEBI
                        )
                      : ""
                  }
                />
                <div>Revize Nedeni Açıklama</div>
                <TextArea
                  placeholder="Revize Nedeni Açıklama"
                  id="revizE_ACIKLAMASI"
                  name="revizE_ACIKLAMASI"
                  autoSize={false}
                  style={{ width: "100%" }}
                  disabled={true}
                  value={data.revizE_ACIKLAMASI}
                ></TextArea>
              </div>
            ) : undefined}
            <div>
              <DropDownList
                name="sevK_SEKLI"
                label="Sevk Şekli"
                data={
                  data?.satiS_DEPARTMANI_KODU?.includes("EXPORT")
                    ? DATAEXPORTSEVKSEKLI
                    : DATASEVKSEKLI
                }
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={this.onChange}
                value={
                  data.sevK_SEKLI
                    ? data?.satiS_DEPARTMANI_KODU?.includes("EXPORT")
                      ? DATAEXPORTSEVKSEKLI.find(
                          (a) => a.id === data.sevK_SEKLI
                        )
                      : DATASEVKSEKLI.find((a) => a.id === data.sevK_SEKLI)
                    : ""
                }
              />
            </div>
            {data?.sevK_SEKLI === "PARTI_PARTI" ? (
              <div>
                <div>Lütfen Partileri Belirtiniz</div>
                <TextArea
                  placeholder="Lütfen Partileri Belirtiniz"
                  id="sevK_PARTI_ACIKLAMA"
                  name="sevK_PARTI_ACIKLAMA"
                  autoSize={false}
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  value={data.sevK_PARTI_ACIKLAMA}
                ></TextArea>
              </div>
            ) : undefined}

            {this.props.orderId !== "0" && (
              <Input
                name="toplaM_HACIM"
                label="Toplam Hacim (m³)"
                style={{ width: "100%" }}
                value={data.toplaM_HACIM}
                readOnly={true}
                disabled={true}
              />
            )}
          </Col>
          <Col lg="4" md="4" xs="12">
            <DatePicker
              label="İstenen Teslim Tarihi"
              name="sevK_TARIHI"
              format="dd.MM.yyyy"
              formatPlaceholder="formatPattern"
              min={new Date()}
              value={
                data.sevK_TARIHI != null
                  ? data.sevK_TARIHI === ""
                    ? null
                    : new Date(data.sevK_TARIHI)
                  : null
              }
              onChange={this.onChange}
              style={{ width: "100%" }}
              required
            />
            <DropDownList
              name="sevK_ADRESI_TIPI"
              label="Sevk Adresi Tipi"
              data={this.state.addressList}
              textField="adreS_BASLIGI"
              dataItemKey="adreS_BASLIGI"
              style={{ width: "100%" }}
              onChange={this.onChangeSevkAdresiTipi}
              required={true}
              value={
                data.sevK_ADRESI_TIPI
                  ? this.state.addressList.find(
                      (a) => a.adreS_BASLIGI === data.sevK_ADRESI_TIPI
                    )
                  : ""
              }
            />
            {!data?.satiS_DEPARTMANI_KODU?.includes("EXPORT") && (
              <DropDownList
                name="sevK_ARAC_TIPI"
                label="Sevk Araç Tipi"
                data={DATASEVKARACTIPI}
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={this.onChange}
                value={
                  data.sevK_ARAC_TIPI
                    ? DATASEVKARACTIPI.find((a) => a.id === data.sevK_ARAC_TIPI)
                    : ""
                }
              />
            )}

            <DropDownList
              name="sevK_ZAMANI"
              label="Sevk Zamanı"
              data={DATASEVKZAMANI}
              textField="text"
              dataItemKey="id"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={
                data.sevK_ZAMANI
                  ? DATASEVKZAMANI.find((a) => a.id === data.sevK_ZAMANI)
                  : ""
              }
            />

            <div>
              <div>Sevk Notu</div>
              <TextArea
                placeholder="Sevk Notu"
                id="sevK_ADRESI"
                name="sevK_ADRESI"
                autoSize={false}
                style={{ width: "100%" }}
                onChange={this.onChange}
                value={data.sevK_ADRESI}
                // required={true}
              ></TextArea>
              <div>Lütfen bu alana sevk adresi girmeyiniz</div>
            </div>

            <div>
              <div>Açıklama</div>
              <TextArea
                placeholder="Açıklama"
                id="aciklama"
                name="aciklama"
                autoSize={false}
                style={{ width: "100%" }}
                onChange={this.onChange}
                value={data.aciklama}
              ></TextArea>
              <div>Lütfen bu alana sevk adresi girmeyiniz</div>
            </div>

            {!this.props.auth.bayiKodu ? (
              <div>
                <CommonDropDownList
                  id="plant"
                  label="Plant"
                  data={DATAPLANT}
                  onChange={this.onChange}
                  required={true}
                  value={data?.plant}
                />
              </div>
            ) : undefined}

            {data?.satiS_ONAY_DURUM === "ONAYLI" ? (
              <div>
                <DropDownList
                  name="sevK_EDILMEME_NEDENI"
                  label="Sevk Edilmeme Nedeni"
                  data={DATASEVKEDILMEMENEDENI}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  value={
                    data.sevK_EDILMEME_NEDENI
                      ? DATASEVKEDILMEMENEDENI.find(
                          (a) => a.id === data.sevK_EDILMEME_NEDENI
                        )
                      : ""
                  }
                />
                <DropDownList
                  name="sevK_EDILMEME_DETAYI"
                  label="Sevk Edilmeme Detayı"
                  data={[
                    { text: "Lütfen Seçiniz", id: "-1" },
                    ...DATASEVKEDILMEMEDETAYI.filter(
                      (child) => child?.parent === data?.sevK_EDILMEME_NEDENI
                    ),
                  ]}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  value={
                    data.sevK_EDILMEME_DETAYI
                      ? DATASEVKEDILMEMEDETAYI.find(
                          (a) =>
                            a.id === data.sevK_EDILMEME_DETAYI &&
                            a.parent === data.sevK_EDILMEME_NEDENI
                        ) || ""
                      : ""
                  }
                />
                <div>Sevk Edilmeme Açıklama</div>
                <TextArea
                  placeholder="Sevk Edilmeme Açıklama"
                  id="sevK_EDILMEME_ACIKLAMA"
                  name="sevK_EDILMEME_ACIKLAMA"
                  autoSize={false}
                  style={{ width: "100%" }}
                  onChange={this.onChange}
                  value={data.sevK_EDILMEME_ACIKLAMA}
                ></TextArea>
              </div>
            ) : undefined}
            {data.satiS_DEPARTMANI_KODU === "EXPORT_UPG" ? (
              <div>
                <div>Proforma Açıklama</div>
                <TextArea
                  placeholder="Proforma Açıklama"
                  id="proformA_ACIKLAMA"
                  name="proformA_ACIKLAMA"
                  autoSize={false}
                  style={{ width: "100%" }}
                  rows={6}
                  onChange={this.onChange}
                  value={
                    !data.proformA_ACIKLAMA || data.proformA_ACIKLAMA === ""
                      ? "Brand: \nLATEST DATE OF SHIPMENT: \nOFFER VALIDATY: \nAFTER SALES CONDITIONS: \nPACKAGING: \nADDITIONAL INFORMATION:"
                      : data.proformA_ACIKLAMA
                  }
                ></TextArea>
              </div>
            ) : undefined}
          </Col>
        </Row>
        <br></br>
        {!this.props.updateButtonDisabled && (
          <Row>
            <Col lg="4" md="4" xs="12">
              <Button color="success" type="submit" size="md">
                <i className="fas fa-check-circle"></i>
                {this.props.orderId === "0" ? "Kaydet" : "Siparişi Güncelle"}
              </Button>
            </Col>
          </Row>
        )}
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    order: state.orderReducer,
    bankList: state.bankListReducer,
    bankInstallmentList: state.bankInstallmentReducer,
    dealerList: state.dealerListReducer,
    campaignListAll: state.campaignsReducer,
    bryChannels: state.channelsReducer,
    dealerProductGroupList: state.dealerProductGroupListReducer,
    bryCampaignDealerList: state.campaignDealerListReducer,
    countryList: state.countryListReducer,
    paymentTermList: state.paymentTermListReducer,
    paymentExpiryList: state.paymentExpiryListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setOrder: bindActionCreators(setOrder, dispatch),
    setFaturaVadesi: bindActionCreators(setFaturaVadesi, dispatch),
    setCampaignToOrder: bindActionCreators(setCampaignToOrder, dispatch),
    setDealerToOrder: bindActionCreators(setDealerToOrder, dispatch),
    getCampaignsByDate: bindActionCreators(getCampaignsByDate, dispatch),
    getChannels: bindActionCreators(getChannels, dispatch),
    getDealers: bindActionCreators(getDealers, dispatch),
    getDbsBanks: bindActionCreators(getDbsBanks, dispatch),
    getBankInstallment: bindActionCreators(getBankInstallment, dispatch),
    getCampaignDealersByBayiKodu: bindActionCreators(
      getCampaignDealersByBayiKodu,
      dispatch
    ),
    getDealerProductGroupByBayiKodu: bindActionCreators(
      getDealerProductGroupByBayiKodu,
      dispatch
    ),
    getCountries: bindActionCreators(getCountries, dispatch),
    getPaymentTerms: bindActionCreators(getPaymentTerms, dispatch),
    getPaymentExpiry: bindActionCreators(getPaymentExpiry, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderProps);
