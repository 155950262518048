import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import moment from "moment";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import filterLocalization from "../../helpers/filterLocalization";
import {
  convertDateToString,
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import CustomerVisitService from "../../services/srv-customerVisit/CustomerVisitService";
import CommonDatePicker from "../sys-common/CommonDatePicker";
import CustomerTempDropDownList from "../sys-common/CustomerTempDropDownList";
import SalesRepresentativeDropDownList from "../sys-common/SalesRepresentativeDropDownList";
import ExportExcel from "../sys-toolbox/ExcelExport";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { LockedGridInlineEdit } from "../sys-toolbox/LockedGridInlineEdit";
import { DateFormatterLocale } from "../sys-toolbox/intl-format/DateFormatterLocale";
import { customerVisitGridColumns } from "./CustomerVisitGridColumns";

const CustomerVisitList = () => {
  const gridPrimaryKey = "muS_ZIYARET_GOREV_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";

  const gridRef = useRef(null);

  const [originalData, setOriginalData] = useState(null);
  const [initialGridData, setInitialGridData] = useState([]);
  const [gridFilterData, setGridFilterData] = useState({
    sort,
    take: 10,
    skip: 0,
    ...gridSearch,
  });
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    getInitialGridData();
  }, [gridFilterData]);

  function getInitialGridData() {
    setDataLoading(true);
    CustomerVisitService.getByFilter(gridFilterData, onCompletedGetData);
  }

  const onCompletedGetData = (data) => {
    if (data) {
      setInitialGridData(data);
      setOriginalData(data);
    }
    setDataLoading(false);
  };

  function onError() {
    setDataLoading(false);
    getInitialGridData();
  }

  function onEditCompleted() {
    setDataLoading(false);
    getInitialGridData();
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setGridFilterData(event.dataState);
  }

  function rowRender(row, dataItem) {
    var rowColor = "white";
    const trProps = {
      ...row.props,
      style: { backgrounColor: rowColor },
    };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  const onItemChange = (event) => {
    const data = initialGridData.data.map((item) =>
      item[gridPrimaryKey] === event.dataItem[gridPrimaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setInitialGridData({ ...initialGridData, data });
  };

  function enterEditCommand(dataItem) {
    var isOpenEdit = initialGridData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = initialGridData.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setInitialGridData({ ...initialGridData, data });
    }
  }

  const addNewItem = () => {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = initialGridData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setInitialGridData({
        ...initialGridData,
        data: [newDataItem, ...initialGridData.data],
      });
    }
  };

  const addCommand = (dataItem) => {
    setDataLoading(true);
    if (dataItem.gecicI_MUS_ADI && dataItem.muS_KODU) {
      defaultAlertMessages.MessageDetailError =
        "Gerçek Müşteri ve Geçici Müşteri aynı anda seçilemez!";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      setDataLoading(false);
    } else if (!dataItem.gecicI_MUS_ADI && !dataItem.muS_KODU) {
      defaultAlertMessages.MessageDetailError =
        "Mutlaka geçerli bir müşteri seçmelisiniz!";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      setDataLoading(false);
    } else if (
      new Date(dataItem.tarih).getTime() <
        new Date(new Date().toDateString()).getTime() ||
      !dataItem.tarih
    ) {
      defaultAlertMessages.MessageDetailError =
        "Tarih alanını kontrol ediniz. Geçmişe dönük atama yapılamaz.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      setDataLoading(false);
    } else if (!dataItem.kullanicI_ID_ATANAN) {
      defaultAlertMessages.MessageDetailError =
        "Atama yapmak için bir kullanıcı seçiniz.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      setDataLoading(false);
    } else {
      CustomerVisitService.create(dataItem, onEditCompleted, onError);
      dataItem.inEdit = false;
    }
  };

  const discardCommand = () => {
    const data = [...initialGridData.data];
    data.splice(0, 1);
    setInitialGridData({ ...initialGridData, data });
  };

  const updateCommand = (dataItem) => {
    setDataLoading(true);
    CustomerVisitService.update(dataItem, onEditCompleted, onError);
    dataItem.inEdit = false;
  };

  const removeCommand = (dataItem) => {
    setDataLoading(true);
    CustomerVisitService.delete(dataItem, onEditCompleted, onError);
    dataItem.inEdit = false;
  };

  function cancelCommand(dataItem) {
    const originalItem = originalData.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = initialGridData.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setInitialGridData({ ...initialGridData, data });
  }

  function CommandCell(props) {
    return (
      <LockedGridInlineEdit
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        remove={removeCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={gridEditField}
        idField={gridPrimaryKey}
        nameField={gridPrimaryKey}
      />
    );
  }

  function UserCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <SalesRepresentativeDropDownList
            id={field}
            name={field}
            value={dataItem.kullanicI_ID_ATANAN}
            onChange={(e) => {
              dataItem.kullanicI_ID_ATANAN = e?.value?.kullanicI_ID;
              setInitialGridData({ ...initialGridData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.kullanicI_ID_ATANAN_ADI}</td>;
    }
  }

  function CustomerCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <CustomerTempDropDownList
            id={field}
            name={field}
            value={dataItem.muS_KODU}
            onChange={(e) => {
              dataItem.muS_KODU = e?.value?.muS_KODU;
              setInitialGridData({ ...initialGridData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.muS_ADI}</td>;
    }
  }
  function DateCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <CommonDatePicker
            id={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = convertDateToString(e.value);
              dataItem.yil = e.value.getFullYear().toString();
              dataItem.hafta = moment(e.value).week().toString();
              setInitialGridData({ ...initialGridData });
            }}
          />
        </td>
      );
    } else {
      return (
        <td>{dataItem.tarih ? DateFormatterLocale(dataItem.tarih) : ""}</td>
      );
    }
  }
  const cellWithBackGround = (props) => {
    const style = {
      backgroundColor: "lightgray",
    };
    const field = props.field || "";
    return <td style={style}>{props.dataItem[field]}</td>;
  };
  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Müşteri Ziyaret Görev Atama</CardTitle>
        </CardHeader>
        <CardBody>
          {dataLoading && LoadingPanel}
          <Grid
            ref={gridRef}
            data={initialGridData}
            {...gridFilterData}
            onDataStateChange={onDataStateChange}
            sortable={true}
            pageable={gridPageable}
            resizable={true}
            rowRender={(row, dataItem) => rowRender(row, dataItem)}
            filterable={true}
            dataItemKey={gridPrimaryKey}
            editField={gridEditField}
            onItemChange={onItemChange}
          >
            <GridToolbar>
              <button
                className="btn btn-primary btn-sm"
                title="Yeni Ürün"
                onClick={addNewItem}
              >
                Yeni Görev
              </button>
              <ExportExcel
                data={gridFilterData}
                columns={customerVisitGridColumns}
                loadingFunction={setDataLoading}
                dataState={gridFilterData}
                serviceMethod={CustomerVisitService.getCustomerVisitsForExcel}
                fileName="MusteriZiyaret"
              ></ExportExcel>
            </GridToolbar>
            <GridColumn
              width="100px"
              filterable={false}
              cell={CommandCell}
              locked={true}
            />
            <GridColumn
              field="muS_ADI"
              title="Müşteri Adı"
              width="250px"
              cell={CustomerCell}
            ></GridColumn>
            <GridColumn
              field="gecicI_MUS_ADI"
              title="Geçici Müşteri Adı"
              width="250px"
              filterable={false}
            ></GridColumn>

            <GridColumn
              field="kullanicI_ID_ATANAN_ADI"
              title="Atanan Kişi"
              width="200px"
              cell={UserCell}
            ></GridColumn>
            <GridColumn
              field="yil"
              title="Yıl"
              width="150px"
              filter="numeric"
              editable={false}
              cell={cellWithBackGround}
            ></GridColumn>
            <GridColumn
              field="hafta"
              title="Hafta"
              width="150px"
              filter="numeric"
              editable={false}
              cell={cellWithBackGround}
            ></GridColumn>
            <GridColumn
              field="tarih"
              title="Tarih"
              width="200px"
              filter="date"
              cell={DateCell}
            ></GridColumn>
            <GridColumn
              field="gorev"
              title="Görev Açıklaması"
              width="400px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="kullanicI_ID_ATAYAN_ADI"
              title="Atayan Kişi"
              width="200px"
              editable={false}
              cell={cellWithBackGround}
            ></GridColumn>
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
};

export default CustomerVisitList;
