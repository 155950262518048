import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

class YesilBinaFirmaService {
  getAllYesilBinaFirma(callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/YesilBinaFirma/get-all`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getYesilBinaFirmaWithTemp(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/yesilbinafirma/get-list-with-temp?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
}

export default new YesilBinaFirmaService();
