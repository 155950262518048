import React, { useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Button } from "reactstrap";
import {
  BASE_URL,
  DATABRYSATISDEPARTMAN,
  DATALISTEVADESI,
  DATAPARABIRIMI,
  DATASAPDURUM,
  DATASIPARISTURU,
} from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import { getChannels } from "../../redux/actions/act-bry-channel/channelActions";
import {
  cancelOrderDealer,
  changeOrderDealer,
  fetchOrderDealer,
  fetchOrdersForExcel,
} from "../../redux/actions/act-order/orderActions";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const OrderListDealer = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const orderList = useSelector((state) => state.orderListDealerReducer);
  const bryChannels = useSelector((state) => state.channelsReducer);

  const _exportRef = useRef(null);
  const _gridRef = useRef(null);

  useEffect(() => {
    getOrderList();
    dispatch(getChannels());
  }, []);

  const getOrderList = useCallback(() => {
    if (auth.bayiKodu) {
      const take = orderList.dataState ? orderList.dataState.take : 6;
      const skip = orderList.dataState ? orderList.dataState.skip : 0;

      const sort = [
        {
          field: "brY_SIPARIS_ID",
          dir: "desc",
        },
      ];

      const bayiFilter = [
        {
          field: "bayI_KODU",
          operator: "eq",
          value: auth.bayiKodu,
        },
      ];

      dispatch(
        fetchOrderDealer({
          filter: { logic: "or", filters: bayiFilter },
          take,
          skip,
          sort,
        })
      );
    }
  }, [auth.bayiKodu, orderList.dataState, dispatch]);

  const goDetail = useCallback((dataItem) => {
    window.open(BASE_URL + "order-update-dealer/" + dataItem.brY_SIPARIS_ID);
  }, []);

  const cancelOrder = useCallback(
    (dataItem) => {
      dispatch(cancelOrderDealer(dataItem, orderList.dataState));
    },
    [orderList.dataState, dispatch]
  );

  const goNewOrder = useCallback(() => {
    history.push(BASE_URL + "order-update-dealer/0");
  }, [history]);

  const exportToExcel = useCallback(() => {
    dispatch(
      fetchOrdersForExcel(
        _exportRef.current,
        _gridRef.current.columns,
        orderList.dataState
      )
    );
  }, [orderList.dataState, dispatch]);

  const dataStateChange = useCallback(
    (event) => {
      filterLocalization(event);
      dispatch(fetchOrderDealer(event.dataState));
      dispatch(changeOrderDealer(event.dataState));
    },
    [dispatch]
  );

  const rowRender = useCallback((trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }, []);

  function ChangeIdToText(data, id) {
    return data.find((item) => item.id === id)?.text || id;
  }

  const SapStatusFilterCell = useCallback(
    (props) => (
      <DropdownFilterCell
        {...props}
        data={DATASAPDURUM.map((a) => a.id)}
        defaultValue={"Seçiniz"}
      />
    ),
    []
  );

  const SalesDepartmantFilterCell = useCallback(
    (props) => (
      <DropdownFilterCell
        {...props}
        data={DATABRYSATISDEPARTMAN.map((a) => a.text)}
        defaultValue={"Seçiniz"}
      />
    ),
    []
  );

  const OrderTypeFilterCell = useCallback(
    (props) => (
      <DropdownFilterCell
        {...props}
        data={DATASIPARISTURU.map((a) => a.text)}
        defaultValue={"Seçiniz"}
      />
    ),
    []
  );

  const CurrencyFilterCell = useCallback(
    (props) => (
      <DropdownFilterCell
        {...props}
        data={DATAPARABIRIMI.map((a) => a.text)}
        defaultValue={"Seçiniz"}
      />
    ),
    []
  );

  const SalesStatusFilterCell = useCallback(
    (props) => (
      <DropdownFilterCell
        {...props}
        data={["YENI", "ONAYDA", "ONAYLI", "TEKLIF", "IPTAL"]}
        defaultValue={"Seçiniz"}
      />
    ),
    []
  );

  const ChannelFilterCell = useCallback(
    (props) => (
      <DropdownFilterCell
        {...props}
        data={bryChannels.channels.map((a) => a.brY_KANAL)}
        defaultValue={"Seçiniz"}
      />
    ),
    [bryChannels.channels]
  );

  // Kullanılmıyor kaldırılabilir mi?
  const VadeGunCell = useCallback(({ dataItem, field }) => {
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const data = DATALISTEVADESI.find((a) => a.id === dataValue.toString());
    return <td>{data ? data.text : dataValue}</td>;
  }, []);

  return (
    <main style={{ flexGrow: "1" }}>
      <h2>Bayi Sipariş Listesi</h2>
      {orderList.fetching && LoadingPanel}
      <ExcelExport data={orderList.orders} ref={_exportRef}>
        <Grid
          ref={_gridRef}
          data={orderList.result}
          {...orderList.dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          style={{ minHeight: "400px" }}
          pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
          resizable={true}
          filterable
          rowRender={rowRender}
        >
          <GridToolbar>
            <Button
              color="primary"
              type="button"
              size="sm"
              onClick={goNewOrder}
            >
              <span className="k-icon k-i-plus-circle"></span> Yeni Sipariş
            </Button>
            <button
              title="Export Excel"
              className="btn btn-info btn-sm"
              onClick={exportToExcel}
            >
              Excel
            </button>
          </GridToolbar>
          <GridColumn
            filterable={false}
            width="180px"
            cell={(props) => (
              <td>
                <button
                  title="Detay"
                  className="btn btn-info btn-sm"
                  onClick={() => goDetail(props.dataItem)}
                >
                  <i className="fas fa-info-circle"></i> Detay
                </button>
                {props.dataItem.satiS_ONAY_DURUM !== "IPTAL" && (
                  <button
                    title="Sil"
                    style={{ marginLeft: "5px" }}
                    className="btn btn-danger btn-sm"
                    onClick={() => cancelOrder(props.dataItem)}
                  >
                    <i className="fas fa-times-circle"></i> İptal
                  </button>
                )}
              </td>
            )}
          />
          <GridColumn
            field="brY_SIPARIS_ID"
            title="Sipariş No"
            width="90px"
            filter="numeric"
          />
          <GridColumn
            field="crE_DATE"
            title="Sipariş Giriş Tarihi"
            width="100px"
            filterable={false}
            filter="date"
            cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
          />
          <GridColumn
            field="satiS_DEPARTMANI_ADI"
            title="Satış Departmanı"
            width="120px"
            filterCell={SalesDepartmantFilterCell}
          />
          <GridColumn
            field="brY_KANAL"
            title="Kanal"
            width="120px"
            filterCell={ChannelFilterCell}
          />
          <GridColumn
            field="sipariS_TURU"
            title="Sipariş Türü"
            width="120px"
            filterCell={OrderTypeFilterCell}
          />
          <GridColumn
            field="kampanyA_KODU"
            title="Kampanya Kodu"
            width="120px"
          />
          <GridColumn
            field="parA_BIRIMI"
            title="Para Birimi"
            width="100px"
            filterCell={CurrencyFilterCell}
          />
          <GridColumn
            field="satiS_ONAY_DURUM"
            title="Satış Onay Durum"
            width="120px"
            filterCell={SalesStatusFilterCell}
          />
          <GridColumn
            field="satiS_TEMSILCISI"
            width="150px"
            title="Satış Temsilcisi"
          />
          <GridColumn
            field="saP_DURUM"
            title="SAP Sipariş Durumu"
            width="120px"
            filterCell={SapStatusFilterCell}
            cell={(props) => {
              return (
                <td>
                  {ChangeIdToText(DATASAPDURUM, props.dataItem.saP_DURUM)}
                </td>
              );
            }}
          />
          <GridColumn
            field="saP_SIPARIS_NO"
            title="SAP Sipariş No"
            width="150px"
          />
        </Grid>
      </ExcelExport>
    </main>
  );
};

export default OrderListDealer;
