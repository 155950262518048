import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderProducts } from "../../redux/actions/act-order/orderProductActions";
import { GridInlineEditNumericPercentCell } from "../sys-toolbox/GridInlineEditNumericPercentCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import CalculateVolume from "../sys-common/CalculateVolume";

class OrderCopProductList extends Component {
  editField = "inEdit";
  isLoadOrderProductData = false;
  isRejectedData = false;
  isRejectedUpdateData = false;

  state = {
    orderProductData: this.props.orderProductData,
    visibleCalculateVolume: false,
  };

  componentDidUpdate() {
    if (
      this.props.orderProductList.fetching === false &&
      this.isLoadOrderProductData === false
    ) {
      this.setState({
        orderProductData: this.props.orderProductList.orderProducts,
      });
      this.isLoadOrderProductData = true;
    }

    if (
      this.props.orderProduct.rejectedUpdateData &&
      this.isRejectedUpdateData === true
    ) {
      this.setState({
        orderProductData: this.props.orderProductList.orderProducts,
      });
      this.isRejectedUpdateData = false;
    }
  }

  ilaveIskontoCell = (props) => {
    <GridInlineEditNumericPercentCell {...props} editField={this.editField} />;
  };

  itemChange = (event) => {
    const data = this.state.orderProductData.map((item) =>
      item.brY_SIPARIS_URUN_ID === event.dataItem.brY_SIPARIS_URUN_ID
        ? {
            ...item,
            [event.field]: event.value,
          }
        : item
    );

    this.setState({ orderProductData: data });
  };

  export = () => {
    this._export.save(
      this.props.orderProductList.orderProducts,
      this._grid.columns
    );
  };

  dataStateChange = (event) => {
    var changeData = process(
      this.state.orderProductData.slice(0),
      event.dataState
    );
    this.setState({ orderProductData: changeData.data });
  };

  rowRender = (trElement, dataItem) => {
    const trProps = { ...trElement.props, className: "ellipsis" };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  AutoComleteCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    if (dataItem.puto === "DET") {
      return (
        <td style={{ fontStyle: "italic", color: "gray" }}>
          {dataValue.toString()} ({dataItem.uruN_ADI})
        </td>
      );
    }
    return (
      <td>
        {dataValue.toString()} ({dataItem.uruN_ADI})
      </td>
    );
  };

  NumberFormatCell = (value, dataItem, isPercent, isKDV) => {
    return (
      <td
        style={{
          fontStyle: dataItem.puto === "DET" ? "italic" : "normal",
          color: dataItem.puto === "DET" ? "gray" : "rgba(0,0,0,0.78)",
        }}
      >
        {isPercent ? "% " : ""}
        <NumberFormat
          thousandSeparator={"."}
          value={value ? (isKDV ? value * 100 : value) : 0}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>
      </td>
    );
  };

  PutoCellNumber = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    return this.NumberFormatCell(dataValue, props.dataItem, false, false);
  };

  TotalPriceCellTurkishLira = (props) => {
    var { dataItem, field } = props;
    var dataValue =
      dataItem[field] === null ? "" : dataItem[field] * this.props.kur;
    return this.NumberFormatCell(dataValue, dataItem, false, false);
  };

  PutoCellPercentNumber = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    return this.NumberFormatCell(dataValue, props.dataItem, true, true);
  };

  PutoCellPercent = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    return this.NumberFormatCell(dataValue, props.dataItem, true, false);
  };

  FooterSumCell = (props) => {
    var total = this.getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam :<br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        Adet
      </td>
    );
  };

  FooterSumCellCurrency = (props) => {
    var total = this.getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        {this.props.order._order.parA_BIRIMI}
      </td>
    );
  };

  FooterSumCellPercent = (props) => {
    var toplamListeFiyati = this.props.orderProductList.orderProducts
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current["listE_BIRIM_FIYATI_KDV_SIZ"], 0);

    var toplamSatisFiyati = this.props.orderProductList.orderProducts
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current["satiS_BIRIM_FIYAT_KDV_SIZ"], 0);

    var result = Math.round(
      ((toplamListeFiyati - toplamSatisFiyati) / toplamListeFiyati) * 100
    );

    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>%{" "}
        <NumberFormat
          thousandSeparator={"."}
          value={result ? result : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
      </td>
    );
  };

  FooterSumTotalPriceCellTurkishLira = (props) => {
    var total = this.getTotalByField(props.field);
    var result = total * this.props.kur;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={result ? result : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        {" TRY"}
      </td>
    );
  };

  getTotalByField = (field) => {
    return this.props.orderProductList.orderProducts
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current[field], 0);
  };

  toggleDialogCalculateVolume = () => {
    this.setState({
      visibleCalculateVolume: !this.state.visibleCalculateVolume,
    });
  };

  render() {
    return (
      <div>
        {this.state.visibleCalculateVolume ? (
          <CalculateVolume
            toggleDialog={this.toggleDialogCalculateVolume}
            orderId={this.props.orderId}
          ></CalculateVolume>
        ) : (
          ""
        )}
        <h4>Sipariş Ürün Listesi</h4>
        {this.props.orderProductList.fetching && LoadingPanel}
        <ExcelExport
          data={this.state.orderProductData}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.state.orderProductData}
            {...this.props.orderProductList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ height: "500px" }}
            onItemChange={this.itemChange}
            editField={this.editField}
            resizable
            filterable
            confirmat
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              <button
                title="Hacim Bilgisi Hesapla"
                className="btn btn-warning btn-sm mr-1"
                onClick={() => this.toggleDialogCalculateVolume()}
              >
                <i class="fas fa-edit"></i> Hacim Bilgisi Hesapla
              </button>
            </GridToolbar>
            <GridColumn
              field="uruN_KODU"
              title="Ürün Kodu/Adı"
              cell={this.AutoComleteCell}
              width="350px"
            ></GridColumn>
            <GridColumn
              field="adet"
              title="Adet"
              width="90px"
              filterable={false}
              editor="numeric"
              footerCell={this.FooterSumCell}
            ></GridColumn>
            <GridColumn
              field="listE_BIRIM_FIYATI_KDV_SIZ"
              title={"Liste Birim Fiyatı"}
              width="100px"
              filterable={false}
              editable={false}
              footerCell={this.FooterSumCellCurrency}
              cell={this.PutoCellNumber}
            ></GridColumn>
            <GridColumn
              field="kdv"
              title="KDV"
              width="60px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercentNumber}
              // format="{0:p}"
            ></GridColumn>
            <GridColumn
              field="listE_BIRIM_FIYATI_KDV_LI"
              title={"KDV'li Liste Birim Fiyatı"}
              width="100px"
              filterable={false}
              editable={false}
              footerCell={this.FooterSumCellCurrency}
              cell={this.PutoCellNumber}
            ></GridColumn>
            <GridColumn
              field="standarT_ISKONTO"
              title="Standart İskonto"
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercent}
            ></GridColumn>
            <GridColumn
              field="kampanyA_ISKONTO"
              title="Kampanya İskonto"
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercent}
            ></GridColumn>
            <GridColumn
              field="ilavE_ISKONTO"
              title="İlave İskonto"
              width="100px"
              filterable={false}
              editor="numeric"
            ></GridColumn>
            <GridColumn
              field="hedeF_TUTAR"
              title="Hedef Tutar"
              width="100px"
              filterable={false}
              editor="numeric"
            ></GridColumn>
            <GridColumn
              field="toplaM_ISKONTO"
              title="Toplam İskonto"
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellPercent}
              footerCell={this.FooterSumCellPercent}
            ></GridColumn>
            <GridColumn
              field="satiS_BIRIM_FIYAT_KDV_SIZ"
              title={"KDV'siz Birim Fiyat"}
              width="100px"
              filterable={false}
              editable={false}
              footerCell={this.FooterSumCellCurrency}
              cell={this.PutoCellNumber}
            ></GridColumn>
            <GridColumn
              field="toplaM_FIYAT_KDV_SIZ"
              title={"KDV'siz Toplam Fiyat"}
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellNumber}
              footerCell={this.FooterSumCellCurrency}
            ></GridColumn>
            <GridColumn
              field="toplaM_FIYAT_KDV_LI"
              title={"KDV'li Toplam Fiyat"}
              width="100px"
              filterable={false}
              editable={false}
              cell={this.PutoCellNumber}
              footerCell={this.FooterSumCellCurrency}
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.orderReducer,
    orderProduct: state.orderProductReducer,
    orderProductList: state.orderProductListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderProducts: bindActionCreators(getOrderProducts, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCopProductList);
