// Auth
export const LOGIN = "LOGIN";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FULFILLED = "LOGIN_FULFILLED";
export const LOGIN_REJECTED = "LOGIN_REJECTED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_PENDING = "LOGOUT_PENDING";
export const LOGOUT_FULFILLED = "LOGOUT_FULFILLED";
export const LOGOUT_REJECTED = "LOGOUT_REJECTED";

export const LOGIN_MFA = "LOGIN_MFA";
export const LOGIN_MFA_PENDING = "LOGIN_MFA_PENDING";
export const LOGIN_MFA_FULFILLED = "LOGIN_MFA_FULFILLED";
export const LOGIN_MFA_REJECTED = "LOGIN_MFA_REJECTED";

export const REFRESH_JWT_BY_AAD = "REFRESH_JWT_BY_AAD";
export const REFRESH_JWT_BY_AAD_PENDING = "REFRESH_JWT_BY_AAD_PENDING";
export const REFRESH_JWT_BY_AAD_FULFILLED = "REFRESH_JWT_BY_AAD_FULFILLED";
export const REFRESH_JWT_BY_AAD_REJECTED = "REFRESH_JWT_BY_AAD_REJECTED";

export const LOGIN_AAD = "LOGIN_AAD";
export const LOGIN_AAD_PENDING = "LOGIN_AAD_PENDING";
export const LOGIN_AAD_FULFILLED = "LOGIN_AAD_FULFILLED";
export const LOGIN_AAD_REJECTED = "LOGIN_AAD_REJECTED";

export const UPDATE_FORGOT_PASSWORD = "UPDATE_FORGOT_PASSWORD";
export const UPDATE_FORGOT_PASSWORD_PENDING = "UPDATE_FORGOT_PASSWORD_PENDING";
export const UPDATE_FORGOT_PASSWORD_FULFILLED =
  "UPDATE_FORGOT_PASSWORD_FULFILLED";
export const UPDATE_FORGOT_PASSWORD_REJECTED =
  "UPDATE_FORGOT_PASSWORD_REJECTED";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
export const RESET_PASSWORD_FULFILLED = "RESET_PASSWORD_FULFILLED";
export const RESET_PASSWORD_REJECTED = "RESET_PASSWORD_REJECTED";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING";
export const CHANGE_PASSWORD_FULFILLED = "CHANGE_PASSWORD_FULFILLED";
export const CHANGE_PASSWORD_REJECTED = "CHANGE_PASSWORD_REJECTED";

export const CHANGE_USER = "CHANGE_USER";
export const CHANGE_MFA = "CHANGE_MFA";
export const SET_SHOW_FORM = "SET_SHOW_FORM";
export const SET_LOGIN = "SET_LOGIN";
export const RESET_LOGIN = "RESET_LOGIN";

//Smarty Token
export const FETCH_SMARTY_TOKEN = "FETCH_SMARTY_TOKEN";
export const FETCH_SMARTY_TOKEN_PENDING = "FETCH_SMARTY_TOKEN_PENDING";
export const FETCH_SMARTY_TOKEN_FULFILLED = "FETCH_SMARTY_TOKEN_FULFILLED";
export const FETCH_SMARTY_TOKEN_REJECTED = "FETCH_SMARTY_TOKEN_REJECTED";

//Menu
export const GET_MENUS = "GET_MENUS";
export const GET_MENUS_PENDING = "GET_MENUS_PENDING";
export const GET_MENUS_FULFILLED = "GET_MENUS_FULFILLED";
export const GET_MENUS_REJECTED = "GET_MENUS_REJECTED";

export const FETCH_PRODUCT_GROUP = "FETCH_PRODUCT_GROUP";
export const FETCH_PRODUCT_GROUP_PENDING = "FETCH_PRODUCT_GROUP_PENDING";
export const FETCH_PRODUCT_GROUP_FULFILLED = "FETCH_PRODUCT_GROUP_FULFILLED";
export const FETCH_PRODUCT_GROUP_REJECTED = "FETCH_PRODUCT_GROUP_REJECTED";

export const GET_STOCK = "GET_STOCK";
export const GET_STOCK_PENDING = "GET_STOCK_PENDING";
export const GET_STOCK_FULFILLED = "GET_STOCK_FULFILLED";
export const GET_STOCK_REJECTED = "GET_STOCK_REJECTED";

export const FETCH_COMPLAINT = "FETCH_COMPLAINT";
export const FETCH_COMPLAINT_PENDING = "FETCH_COMPLAINT_PENDING";
export const FETCH_COMPLAINT_FULFILLED = "FETCH_COMPLAINT_FULFILLED";
export const FETCH_COMPLAINT_REJECTED = "FETCH_COMPLAINT_REJECTED";

export const GET_COMPLAINT = "GET_COMPLAINT";
export const GET_COMPLAINT_PENDING = "GET_COMPLAINT_PENDING";
export const GET_COMPLAINT_FULFILLED = "GET_COMPLAINT_FULFILLED";
export const GET_COMPLAINT_REJECTED = "GET_COMPLAINT_REJECTED";

export const CHANGE_COMPLAINT = "CHANGE_COMPLAINT";
export const SET_COMPLAINT = "SET_COMPLAINT";

export const UPDATE_COMPLAINT = "UPDATE_COMPLAINT";
export const UPDATE_COMPLAINT_PENDING = "UPDATE_COMPLAINT_PENDING";
export const UPDATE_COMPLAINT_FULFILLED = "UPDATE_COMPLAINT_FULFILLED";
export const UPDATE_COMPLAINT_REJECTED = "UPDATE_COMPLAINT_REJECTED";

export const FETCH_COMPLAINT_HISTORY = "FETCH_COMPLAINT_HISTORY";
export const FETCH_COMPLAINT_HISTORY_PENDING =
  "FETCH_COMPLAINT_HISTORY_PENDING";
export const FETCH_COMPLAINT_HISTORY_FULFILLED =
  "FETCH_COMPLAINT_HISTORY_FULFILLED";
export const FETCH_COMPLAINT_HISTORY_REJECTED =
  "FETCH_COMPLAINT_HISTORY_REJECTED";

export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_PENDING = "FETCH_ORDER_PENDING";
export const FETCH_ORDER_FULFILLED = "FETCH_ORDER_FULFILLED";
export const FETCH_ORDER_REJECTED = "FETCH_ORDER_REJECTED";

export const FETCH_ORDER_DEALER = "FETCH_ORDER_DEALER";
export const FETCH_ORDER_DEALER_PENDING = "FETCH_ORDER_DEALER_PENDING";
export const FETCH_ORDER_DEALER_FULFILLED = "FETCH_ORDER_DEALER_FULFILLED";
export const FETCH_ORDER_DEALER_REJECTED = "FETCH_ORDER_DEALER_REJECTED";
export const CHANGE_ORDER_DEALER = "CHANGE_ORDER_DEALER";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_PENDING = "GET_ORDER_PENDING";
export const GET_ORDER_FULFILLED = "GET_ORDER_FULFILLED";
export const GET_ORDER_REJECTED = "GET_ORDER_REJECTED";

export const CHANGE_ORDER = "CHANGE_ORDER";
export const SET_ORDER = "SET_ORDER";
export const SET_DEALER_TO_ORDER = "SET_DEALER_TO_ORDER";
export const SET_FATURA_VADESI_TO_ORDER = "SET_FATURA_VADESI_TO_ORDER";
export const SET_CAMPAIGN_TO_ORDER = "SET_CAMPAIGN_TO_ORDER";

export const GET_ORDER_PRODUCTS = "GET_ORDER_PRODUCTS";
export const GET_ORDER_PRODUCTS_PENDING = "GET_ORDER_PRODUCTS_PENDING";
export const GET_ORDER_PRODUCTS_FULFILLED = "GET_ORDER_PRODUCTS_FULFILLED";
export const GET_ORDER_PRODUCTS_REJECTED = "GET_ORDER_PRODUCTS_REJECTED";

export const CALLSP_ADD_ORDER = "CALLSP_ADD_ORDER";
export const CALLSP_ADD_ORDER_PENDING = "CALLSP_ADD_ORDER_PENDING";
export const CALLSP_ADD_ORDER_FULFILLED = "CALLSP_ADD_ORDER_FULFILLED";
export const CALLSP_ADD_ORDER_REJECTED = "CALLSP_ADD_ORDER_REJECTED";

export const CALLSP_CUSTOMER_PRODUCT = "CALLSP_CUSTOMER_PRODUCT";
export const CALLSP_CUSTOMER_PRODUCT_PENDING =
  "CALLSP_CUSTOMER_PRODUCT_PENDING";
export const CALLSP_CUSTOMER_PRODUCT_FULFILLED =
  "CALLSP_CUSTOMER_PRODUCT_FULFILLED";
export const CALLSP_CUSTOMER_PRODUCT_REJECTED =
  "CALLSP_CUSTOMER_PRODUCT_REJECTED";

export const CALLSP_CUSTOMER_PRODUCT_GROUP = "CALLSP_CUSTOMER_PRODUCT_GROUP";
export const CALLSP_CUSTOMER_PRODUCT_GROUP_PENDING =
  "CALLSP_CUSTOMER_PRODUCT_GROUP_PENDING";
export const CALLSP_CUSTOMER_PRODUCT_GROUP_FULFILLED =
  "CALLSP_CUSTOMER_PRODUCT_GROUP_FULFILLED";
export const CALLSP_CUSTOMER_PRODUCT_GROUP_REJECTED =
  "CALLSP_CUSTOMER_PRODUCT_GROUP_REJECTED";

export const CALLSP_CUSTOMER_GROUP_PRODUCT = "CALLSP_CUSTOMER_GROUP_PRODUCT";
export const CALLSP_CUSTOMER_GROUP_PRODUCT_PENDING =
  "CALLSP_CUSTOMER_GROUP_PRODUCT_PENDING";
export const CALLSP_CUSTOMER_GROUP_PRODUCT_FULFILLED =
  "CALLSP_CUSTOMER_GROUP_PRODUCT_FULFILLED";
export const CALLSP_CUSTOMER_GROUP_PRODUCT_REJECTED =
  "CALLSP_CUSTOMER_GROUP_PRODUCT_REJECTED";

export const CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP =
  "CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP";
export const CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING =
  "CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING";
export const CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED =
  "CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED";
export const CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED =
  "CALLSP_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED";

export const CALLSP_SALES_CHANNEL_PRODUCT = "CALLSP_SALES_CHANNEL_PRODUCT";
export const CALLSP_SALES_CHANNEL_PRODUCT_PENDING =
  "CALLSP_SALES_CHANNEL_PRODUCT_PENDING";
export const CALLSP_SALES_CHANNEL_PRODUCT_FULFILLED =
  "CALLSP_SALES_CHANNEL_PRODUCT_FULFILLED";
export const CALLSP_SALES_CHANNEL_PRODUCT_REJECTED =
  "CALLSP_SALES_CHANNEL_PRODUCT_REJECTED";

export const CALLSP_SALES_CHANNEL_PRODUCT_GROUP =
  "CALLSP_SALES_CHANNEL_PRODUCT_GROUP";
export const CALLSP_SALES_CHANNEL_PRODUCT_GROUP_PENDING =
  "CALLSP_SALES_CHANNEL_PRODUCT_GROUP_PENDING";
export const CALLSP_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED =
  "CALLSP_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED";
export const CALLSP_SALES_CHANNEL_PRODUCT_GROUP_REJECTED =
  "CALLSP_SALES_CHANNEL_PRODUCT_GROUP_REJECTED";

export const UPDATE_CUSTOMER_PRODUCT = "UPDATE_CUSTOMER_PRODUCT";
export const UPDATE_CUSTOMER_PRODUCT_PENDING =
  "UPDATE_CUSTOMER_PRODUCT_PENDING";
export const UPDATE_CUSTOMER_PRODUCT_FULFILLED =
  "UPDATE_CUSTOMER_PRODUCT_FULFILLED";
export const UPDATE_CUSTOMER_PRODUCT_REJECTED =
  "UPDATE_CUSTOMER_PRODUCT_REJECTED";

export const UPDATE_CUSTOMER_PRODUCT_GROUP = "UPDATE_CUSTOMER_PRODUCT_GROUP";
export const UPDATE_CUSTOMER_PRODUCT_GROUP_PENDING =
  "UPDATE_CUSTOMER_PRODUCT_GROUP_PENDING";
export const UPDATE_CUSTOMER_PRODUCT_GROUP_FULFILLED =
  "UPDATE_CUSTOMER_PRODUCT_GROUP_FULFILLED";
export const UPDATE_CUSTOMER_PRODUCT_GROUP_REJECTED =
  "UPDATE_CUSTOMER_PRODUCT_GROUP_REJECTED";

export const UPDATE_CUSTOMER_GROUP_PRODUCT = "UPDATE_CUSTOMER_GROUP_PRODUCT";
export const UPDATE_CUSTOMER_GROUP_PRODUCT_PENDING =
  "UPDATE_CUSTOMER_GROUP_PRODUCT_PENDING";
export const UPDATE_CUSTOMER_GROUP_PRODUCT_FULFILLED =
  "UPDATE_CUSTOMER_GROUP_PRODUCT_FULFILLED";
export const UPDATE_CUSTOMER_GROUP_PRODUCT_REJECTED =
  "UPDATE_CUSTOMER_GROUP_PRODUCT_REJECTED";

export const UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP =
  "UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP";
export const UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING =
  "UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING";
export const UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED =
  "UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED";
export const UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED =
  "UPDATE_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED";

export const UPDATE_SALES_CHANNEL_PRODUCT = "UPDATE_SALES_CHANNEL_PRODUCT";
export const UPDATE_SALES_CHANNEL_PRODUCT_PENDING =
  "UPDATE_SALES_CHANNEL_PRODUCT_PENDING";
export const UPDATE_SALES_CHANNEL_PRODUCT_FULFILLED =
  "UPDATE_SALES_CHANNEL_PRODUCT_FULFILLED";
export const UPDATE_SALES_CHANNEL_PRODUCT_REJECTED =
  "UPDATE_SALES_CHANNEL_PRODUCT_REJECTED";

export const UPDATE_SALES_CHANNEL_PRODUCT_GROUP =
  "UPDATE_SALES_CHANNEL_PRODUCT_GROUP";
export const UPDATE_SALES_CHANNEL_PRODUCT_GROUP_PENDING =
  "UPDATE_SALES_CHANNEL_PRODUCT_GROUP_PENDING";
export const UPDATE_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED =
  "UPDATE_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED";
export const UPDATE_SALES_CHANNEL_PRODUCT_GROUP_REJECTED =
  "UPDATE_SALES_CHANNEL_PRODUCT_GROUP_REJECTED";

export const CALLSP_REMOVE_ORDER = "CALLSP_REMOVE_ORDER";
export const CALLSP_REMOVE_ORDER_PENDING = "CALLSP_REMOVE_ORDER_PENDING";
export const CALLSP_REMOVE_ORDER_FULFILLED = "CALLSP_REMOVE_ORDER_FULFILLED";
export const CALLSP_REMOVE_ORDER_REJECTED = "CALLSP_REMOVE_ORDER_REJECTED";

export const CALLSP_REMOVE_CUSTOMER_PRODUCT = "CALLSP_REMOVE_CUSTOMER_PRODUCT";
export const CALLSP_REMOVE_CUSTOMER_PRODUCT_PENDING =
  "CALLSP_REMOVE_CUSTOMER_PRODUCT_PENDING";
export const CALLSP_REMOVE_CUSTOMER_PRODUCT_FULFILLED =
  "CALLSP_REMOVE_CUSTOMER_PRODUCT_FULFILLED";
export const CALLSP_REMOVE_CUSTOMER_PRODUCT_REJECTED =
  "CALLSP_REMOVE_CUSTOMER_PRODUCT_REJECTED";

export const CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP =
  "CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP";
export const CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP_PENDING =
  "CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP_PENDING";
export const CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP_FULFILLED =
  "CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP_FULFILLED";
export const CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP_REJECTED =
  "CALLSP_REMOVE_CUSTOMER_PRODUCT_GROUP_REJECTED";

export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT";
export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_PENDING =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_PENDING";
export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_FULFILLED =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_FULFILLED";
export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_REJECTED =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_REJECTED";

export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP";
export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING";
export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED";
export const CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED =
  "CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED";

export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT";
export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_PENDING =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_PENDING";
export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_FULFILLED =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_FULFILLED";
export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_REJECTED =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_REJECTED";

export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP";
export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP_PENDING =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP_PENDING";
export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED";
export const CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP_REJECTED =
  "CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP_REJECTED";

export const CALLSP_CANCEL_ORDER = "CALLSP_CANCEL_ORDER";
export const CALLSP_CANCEL_ORDER_PENDING = "CALLSP_CANCEL_ORDER_PENDING";
export const CALLSP_CANCEL_ORDER_FULFILLED = "CALLSP_CANCEL_ORDER_FULFILLED";
export const CALLSP_CANCEL_ORDER_REJECTED = "CALLSP_CANCEL_ORDER_REJECTED";

export const CALLSP_REMOVE_ORDER_DEALER = "CALLSP_REMOVE_ORDER_DEALER";
export const CALLSP_REMOVE_ORDER_DEALER_PENDING =
  "CALLSP_REMOVE_ORDER_DEALER_PENDING";
export const CALLSP_REMOVE_ORDER_DEALER_FULFILLED =
  "CALLSP_REMOVE_ORDER_DEALER_FULFILLED";
export const CALLSP_REMOVE_ORDER_DEALER_REJECTED =
  "CALLSP_REMOVE_ORDER_DEALER_REJECTED";

export const CALLSP_CANCEL_ORDER_DEALER = "CALLSP_CANCEL_ORDER_DEALER";
export const CALLSP_CANCEL_ORDER_DEALER_PENDING =
  "CALLSP_CANCEL_ORDER_DEALER_PENDING";
export const CALLSP_CANCEL_ORDER_DEALER_FULFILLED =
  "CALLSP_CANCEL_ORDER_DEALER_FULFILLED";
export const CALLSP_CANCEL_ORDER_DEALER_REJECTED =
  "CALLSP_CANCEL_ORDER_DEALER_REJECTED";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_PENDING = "UPDATE_ORDER_PENDING";
export const UPDATE_ORDER_FULFILLED = "UPDATE_ORDER_FULFILLED";
export const UPDATE_ORDER_REJECTED = "UPDATE_ORDER_REJECTED";

export const FETCH_DEALERS = "FETCH_DEALERS";
export const FETCH_DEALERS_PENDING = "FETCH_DEALERS_PENDING";
export const FETCH_DEALERS_FULFILLED = "FETCH_DEALERS_FULFILLED";
export const FETCH_DEALERS_REJECTED = "FETCH_DEALERS_REJECTED";

export const GET_DEALERS = "GET_DEALERS";
export const GET_DEALERS_PENDING = "GET_DEALERS_PENDING";
export const GET_DEALERS_FULFILLED = "GET_DEALERS_FULFILLED";
export const GET_DEALERS_REJECTED = "GET_DEALERS_REJECTED";

export const CHANGE_ORDER_PRODUCTS = "CHANGE_ORDER_PRODUCTS";

export const FETCH_BRY_CAMPAIGNS = "FETCH_BRY_CAMPAIGNS";
export const FETCH_BRY_CAMPAIGNS_PENDING = "FETCH_BRY_CAMPAIGNS_PENDING";
export const FETCH_BRY_CAMPAIGNS_FULFILLED = "FETCH_BRY_CAMPAIGNS_FULFILLED";
export const FETCH_BRY_CAMPAIGNS_REJECTED = "FETCH_BRY_CAMPAIGNS_REJECTED";

export const GET_BRY_CAMPAIGNS = "GET_BRY_CAMPAIGNS";
export const GET_BRY_CAMPAIGNS_PENDING = "GET_BRY_CAMPAIGNS_PENDING";
export const GET_BRY_CAMPAIGNS_FULFILLED = "GET_BRY_CAMPAIGNS_FULFILLED";
export const GET_BRY_CAMPAIGNS_REJECTED = "GET_BRY_CAMPAIGNS_REJECTED";

export const CHANGE_BRY_CAMPAIGN = "CHANGE_BRY_CAMPAIGN";
export const SET_BRY_CAMPAIGN = "SET_BRY_CAMPAIGN";

export const FETCH_BRY_CAMPAIGNS_PRODUCT = "FETCH_BRY_CAMPAIGNS_PRODUCT";
export const FETCH_BRY_CAMPAIGNS_PRODUCT_PENDING =
  "FETCH_BRY_CAMPAIGNS_PRODUCT_PENDING";
export const FETCH_BRY_CAMPAIGNS_PRODUCT_FULFILLED =
  "FETCH_BRY_CAMPAIGNS_PRODUCT_FULFILLED";
export const FETCH_BRY_CAMPAIGNS_PRODUCT_REJECTED =
  "FETCH_BRY_CAMPAIGNS_PRODUCT_REJECTED";

export const GET_BRY_CAMPAIGNS_PRODUCT = "GET_BRY_CAMPAIGNS_PRODUCT";
export const GET_BRY_CAMPAIGNS_PRODUCT_PENDING =
  "GET_BRY_CAMPAIGNS_PRODUCT_PENDING";
export const GET_BRY_CAMPAIGNS_PRODUCT_FULFILLED =
  "GET_BRY_CAMPAIGNS_PRODUCT_FULFILLED";
export const GET_BRY_CAMPAIGNS_PRODUCT_REJECTED =
  "GET_BRY_CAMPAIGNS_PRODUCT_REJECTED";

export const DELETE_BRY_CAMPAIGNS_PRODUCT = "DELETE_BRY_CAMPAIGNS_PRODUCT";
export const DELETE_BRY_CAMPAIGNS_PRODUCT_PENDING =
  "DELETE_BRY_CAMPAIGNS_PRODUCT_PENDING";
export const DELETE_BRY_CAMPAIGNS_PRODUCT_FULFILLED =
  "DELETE_BRY_CAMPAIGNS_PRODUCT_FULFILLED";
export const DELETE_BRY_CAMPAIGNS_PRODUCT_REJECTED =
  "DELETE_BRY_CAMPAIGNS_PRODUCT_REJECTED";

export const FETCH_BRY_PRODUCT_CATEGORY = "FETCH_BRY_PRODUCT_CATEGORY";
export const FETCH_BRY_PRODUCT_CATEGORY_PENDING =
  "FETCH_BRY_PRODUCT_CATEGORY_PENDING";
export const FETCH_BRY_PRODUCT_CATEGORY_FULFILLED =
  "FETCH_BRY_PRODUCT_CATEGORY_FULFILLED";
export const FETCH_BRY_PRODUCT_CATEGORY_REJECTED =
  "FETCH_BRY_PRODUCT_CATEGORY_REJECTED";

export const FETCH_BRY_PRODUCT_GROUP = "FETCH_BRY_PRODUCT_GROUP";
export const FETCH_BRY_PRODUCT_GROUP_PENDING =
  "FETCH_BRY_PRODUCT_GROUP_PENDING";
export const FETCH_BRY_PRODUCT_GROUP_FULFILLED =
  "FETCH_BRY_PRODUCT_GROUP_FULFILLED";
export const FETCH_BRY_PRODUCT_GROUP_REJECTED =
  "FETCH_BRY_PRODUCT_GROUP_REJECTED";

export const FETCH_BRY_PRODUCT_MODEL_DETAIL = "FETCH_BRY_PRODUCT_MODEL_DETAIL";
export const FETCH_BRY_PRODUCT_MODEL_DETAIL_PENDING =
  "FETCH_BRY_PRODUCT_MODEL_DETAIL_PENDING";
export const FETCH_BRY_PRODUCT_MODEL_DETAIL_FULFILLED =
  "FETCH_BRY_PRODUCT_MODEL_DETAIL_FULFILLED";
export const FETCH_BRY_PRODUCT_MODEL_DETAIL_REJECTED =
  "FETCH_BRY_PRODUCT_MODEL_DETAIL_REJECTED";

export const FETCH_BRY_PRODUCT_MODEL = "FETCH_BRY_PRODUCT_MODEL";
export const FETCH_BRY_PRODUCT_MODEL_PENDING =
  "FETCH_BRY_PRODUCT_MODEL_PENDING";
export const FETCH_BRY_PRODUCT_MODEL_FULFILLED =
  "FETCH_BRY_PRODUCT_MODEL_FULFILLED";
export const FETCH_BRY_PRODUCT_MODEL_REJECTED =
  "FETCH_BRY_PRODUCT_MODEL_REJECTED";

export const FETCH_BRY_PRODUCT_TYPE = "FETCH_BRY_PRODUCT_TYPE";
export const FETCH_BRY_PRODUCT_TYPE_PENDING = "FETCH_BRY_PRODUCT_TYPE_PENDING";
export const FETCH_BRY_PRODUCT_TYPE_FULFILLED =
  "FETCH_BRY_PRODUCT_TYPE_FULFILLED";
export const FETCH_BRY_PRODUCT_TYPE_REJECTED =
  "FETCH_BRY_PRODUCT_TYPE_REJECTED";

export const CHANGE_BRY_CAMPAIGNS_PRODUCT = "CHANGE_BRY_CAMPAIGNS_PRODUCT";
export const SET_BRY_CAMPAIGNS_PRODUCT = "SET_BRY_CAMPAIGNS_PRODUCT";

export const GET_BRY_CAMPAIGN = "GET_BRY_CAMPAIGN";
export const GET_BRY_CAMPAIGN_PENDING = "GET_BRY_CAMPAIGN_PENDING";
export const GET_BRY_CAMPAIGN_FULFILLED = "GET_BRY_CAMPAIGN_FULFILLED";
export const GET_BRY_CAMPAIGN_REJECTED = "GET_BRY_CAMPAIGN_REJECTED";

export const GET_BRY_CHANNELS = "GET_BRY_CHANNELS";
export const GET_BRY_CHANNELS_PENDING = "GET_BRY_CHANNELS_PENDING";
export const GET_BRY_CHANNELS_FULFILLED = "GET_BRY_CHANNELS_FULFILLED";
export const GET_BRY_CHANNELS_REJECTED = "GET_BRY_CHANNELS_REJECTED";

export const GET_BRY_CHANNELS_BY_FILTER = "GET_BRY_CHANNELS_BY_FILTER";
export const GET_BRY_CHANNELS_BY_FILTER_PENDING =
  "GET_BRY_CHANNELS_BY_FILTER_PENDING";
export const GET_BRY_CHANNELS_BY_FILTER_FULFILLED =
  "GET_BRY_CHANNELS_BY_FILTER_FULFILLED";
export const GET_BRY_CHANNELS_BY_FILTER_REJECTED =
  "GET_BRY_CHANNELS_BY_FILTER_REJECTED";

export const GET_BRY_SALES_CHANNELS_BY_FILTER =
  "GET_BRY_SALES_CHANNELS_BY_FILTER";
export const GET_BRY_SALES_CHANNELS_BY_FILTER_PENDING =
  "GET_BRY_SALES_CHANNELS_BY_FILTER_PENDING";
export const GET_BRY_SALES_CHANNELS_BY_FILTER_FULFILLED =
  "GET_BRY_SALES_CHANNELS_BY_FILTER_FULFILLED";
export const GET_BRY_SALES_CHANNELS_BY_FILTER_REJECTED =
  "GET_BRY_SALES_CHANNELS_BY_FILTER_REJECTED";

export const FETCH_DEALER_PRODUCT_GROUP = "FETCH_DEALER_PRODUCT_GROUP";
export const FETCH_DEALER_PRODUCT_GROUP_PENDING =
  "FETCH_DEALER_PRODUCT_GROUP_PENDING";
export const FETCH_DEALER_PRODUCT_GROUP_FULFILLED =
  "FETCH_DEALER_PRODUCT_GROUP_FULFILLED";
export const FETCH_DEALER_PRODUCT_GROUP_REJECTED =
  "FETCH_DEALER_PRODUCT_GROUP_REJECTED";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_PENDING = "GET_PRODUCTS_PENDING";
export const GET_PRODUCTS_FULFILLED = "GET_PRODUCTS_FULFILLED";
export const GET_PRODUCTS_REJECTED = "GET_PRODUCTS_REJECTED";

export const GET_PRODUCTS_BY_FILTER = "GET_PRODUCTS_BY_FILTER";
export const GET_PRODUCTS_BY_FILTER_PENDING = "GET_PRODUCTS_BY_FILTER_PENDING";
export const GET_PRODUCTS_BY_FILTER_FULFILLED =
  "GET_PRODUCTS_BY_FILTER_FULFILLED";
export const GET_PRODUCTS_BY_FILTER_REJECTED =
  "GET_PRODUCTS_BY_FILTER_REJECTED";

export const ADD_ORDER_PRODUCT = "ADD_ORDER_PRODUCT";
export const ADD_ORDER_PRODUCT_PENDING = "ADD_ORDER_PRODUCT_PENDING";
export const ADD_ORDER_PRODUCT_FULFILLED = "ADD_ORDER_PRODUCT_FULFILLED";
export const ADD_ORDER_PRODUCT_REJECTED = "ADD_ORDER_PRODUCT_REJECTED";

export const EDIT_ORDER_PRODUCT = "EDIT_ORDER_PRODUCT";
export const EDIT_ORDER_PRODUCT_PENDING = "EDIT_ORDER_PRODUCT_PENDING";
export const EDIT_ORDER_PRODUCT_FULFILLED = "EDIT_ORDER_PRODUCT_FULFILLED";
export const EDIT_ORDER_PRODUCT_REJECTED = "EDIT_ORDER_PRODUCT_REJECTED";

export const REMOVE_ORDER_PRODUCT = "REMOVE_ORDER_PRODUCT";
export const REMOVE_ORDER_PRODUCT_PENDING = "REMOVE_ORDER_PRODUCT_PENDING";
export const REMOVE_ORDER_PRODUCT_FULFILLED = "REMOVE_ORDER_PRODUCT_FULFILLED";
export const REMOVE_ORDER_PRODUCT_REJECTED = "REMOVE_ORDER_PRODUCT_REJECTED";

export const FETCH_SALES_DEPARTMANT = "FETCH_SALES_DEPARTMANT";
export const FETCH_SALES_DEPARTMANT_PENDING = "FETCH_SALES_DEPARTMANT_PENDING";
export const FETCH_SALES_DEPARTMANT_FULFILLED =
  "FETCH_SALES_DEPARTMANT_FULFILLED";
export const FETCH_SALES_DEPARTMANT_REJECTED =
  "FETCH_SALES_DEPARTMANT_REJECTED";

export const FETCH_SALES_DEPARTMENT = "FETCH_SALES_DEPARTMENT";
export const FETCH_SALES_DEPARTMENT_PENDING = "FETCH_SALES_DEPARTMENT_PENDING";
export const FETCH_SALES_DEPARTMENT_FULFILLED =
  "FETCH_SALES_DEPARTMENT_FULFILLED";
export const FETCH_SALES_DEPARTMENT_REJECTED =
  "FETCH_SALES_DEPARTMENT_REJECTED";
export const CALLSP_UPDATE_SALES_DEPARTMENT = "CALLSP_UPDATE_SALES_DEPARTMENT";
export const CALLSP_UPDATE_SALES_DEPARTMENT_PENDING =
  "CALLSP_UPDATE_SALES_DEPARTMENT_PENDING";
export const CALLSP_UPDATE_SALES_DEPARTMENT_FULFILLED =
  "CALLSP_UPDATE_SALES_DEPARTMENT_FULFILLED";
export const CALLSP_UPDATE_SALES_DEPARTMENT_REJECTED =
  "CALLSP_UPDATE_SALES_DEPARTMENT_REJECTED";
export const CHANGE_SALES_DEPARTMENT = "CHANGE_SALES_DEPARTMENT";

export const FETCH_BRY_CUSTOMER_GROUP = "FETCH_BRY_CUSTOMER_GROUP";
export const FETCH_BRY_CUSTOMER_GROUP_PENDING =
  "FETCH_BRY_CUSTOMER_GROUP_PENDING";
export const FETCH_BRY_CUSTOMER_GROUP_FULFILLED =
  "FETCH_BRY_CUSTOMER_GROUP_FULFILLED";
export const FETCH_BRY_CUSTOMER_GROUP_REJECTED =
  "FETCH_BRY_CUSTOMER_GROUP_REJECTED";

export const UPDATE_BRY_CAMPAIGN_PARTICIPANTS =
  "UPDATE_BRY_CAMPAIGN_PARTICIPANTS";
export const UPDATE_BRY_CAMPAIGN_PARTICIPANTS_PENDING =
  "UPDATE_BRY_CAMPAIGN_PARTICIPANTS_PENDING";
export const UPDATE_BRY_CAMPAIGN_PARTICIPANTS_FULFILLED =
  "UPDATE_BRY_CAMPAIGN_PARTICIPANTS_FULFILLED";
export const UPDATE_BRY_CAMPAIGN_PARTICIPANTS_REJECTED =
  "UPDATE_BRY_CAMPAIGN_PARTICIPANTS_REJECTED";

export const GET_BRY_CAMPAIGN_DEPARTMANTS = "GET_BRY_CAMPAIGN_DEPARTMANTS";
export const GET_BRY_CAMPAIGN_DEPARTMANTS_PENDING =
  "GET_BRY_CAMPAIGN_DEPARTMANTS_PENDING";
export const GET_BRY_CAMPAIGN_DEPARTMANTS_FULFILLED =
  "GET_BRY_CAMPAIGN_DEPARTMANTS_FULFILLED";
export const GET_BRY_CAMPAIGN_DEPARTMANTS_REJECTED =
  "GET_BRY_CAMPAIGN_DEPARTMANTS_REJECTED";

export const GET_BRY_CAMPAIGN_CHANNELS = "GET_BRY_CAMPAIGN_CHANNELS";
export const GET_BRY_CAMPAIGN_CHANNELS_PENDING =
  "GET_BRY_CAMPAIGN_CHANNELS_PENDING";
export const GET_BRY_CAMPAIGN_CHANNELS_FULFILLED =
  "GET_BRY_CAMPAIGN_CHANNELS_FULFILLED";
export const GET_BRY_CAMPAIGN_CHANNELS_REJECTED =
  "GET_BRY_CAMPAIGN_CHANNELS_REJECTED";

export const GET_BRY_CAMPAIGN_CUSTOMER_GROUPS =
  "GET_BRY_CAMPAIGN_CUSTOMER_GROUPS";
export const GET_BRY_CAMPAIGN_CUSTOMER_GROUPS_PENDING =
  "GET_BRY_CAMPAIGN_CUSTOMER_GROUPS_PENDING";
export const GET_BRY_CAMPAIGN_CUSTOMER_GROUPS_FULFILLED =
  "GET_BRY_CAMPAIGN_CUSTOMER_GROUPS_FULFILLED";
export const GET_BRY_CAMPAIGN_CUSTOMER_GROUPS_REJECTED =
  "GET_BRY_CAMPAIGN_CUSTOMER_GROUPS_REJECTED";

export const GET_BRY_CAMPAIGN_DEALERS = "GET_BRY_CAMPAIGN_DEALERS";
export const GET_BRY_CAMPAIGN_DEALERS_PENDING =
  "GET_BRY_CAMPAIGN_DEALERS_PENDING";
export const GET_BRY_CAMPAIGN_DEALERS_FULFILLED =
  "GET_BRY_CAMPAIGN_DEALERS_FULFILLED";
export const GET_BRY_CAMPAIGN_DEALERS_REJECTED =
  "GET_BRY_CAMPAIGN_DEALERS_REJECTED";

export const GET_BRY_CAMPAIGN_DEALER_OPTIONS =
  "GET_BRY_CAMPAIGN_DEALER_OPTIONS";
export const GET_BRY_CAMPAIGN_DEALER_OPTIONS_PENDING =
  "GET_BRY_CAMPAIGN_DEALER_OPTIONS_PENDING";
export const GET_BRY_CAMPAIGN_DEALER_OPTIONS_FULFILLED =
  "GET_BRY_CAMPAIGN_DEALER_OPTIONS_FULFILLED";
export const GET_BRY_CAMPAIGN_DEALER_OPTIONS_REJECTED =
  "GET_BRY_CAMPAIGN_DEALER_OPTIONS_REJECTED";

export const UPDATE_CAMPAIGN_DEALERS = "UPDATE_CAMPAIGN_DEALERS";
export const UPDATE_CAMPAIGN_DEALERS_PENDING =
  "UPDATE_CAMPAIGN_DEALERS_PENDING";
export const UPDATE_CAMPAIGN_DEALERS_FULFILLED =
  "UPDATE_CAMPAIGN_DEALERS_FULFILLED";
export const UPDATE_CAMPAIGN_DEALERS_REJECTED =
  "UPDATE_CAMPAIGN_DEALERS_REJECTED";

export const FETCH_ORDER_FILES = "FETCH_ORDER_FILES";
export const FETCH_ORDER_FILES_PENDING = "FETCH_ORDER_FILES_PENDING";
export const FETCH_ORDER_FILES_FULFILLED = "FETCH_ORDER_FILES_FULFILLED";
export const FETCH_ORDER_FILES_REJECTED = "FETCH_ORDER_FILES_REJECTED";

export const DELETE_ORDER_FILES = "DELETE_ORDER_FILES";

export const DOWNLOAD_ORDER_FILES = "DOWNLOAD_ORDER_FILES";

export const UPDATE_CALCULATE_CAMPAIGN_DISCOUNT =
  "UPDATE_CALCULATE_CAMPAIGN_DISCOUNT";
export const UPDATE_CALCULATE_CAMPAIGN_DISCOUNT_PENDING =
  "UPDATE_CALCULATE_CAMPAIGN_DISCOUNT_PENDING";
export const UPDATE_CALCULATE_CAMPAIGN_DISCOUNT_FULFILLED =
  "UPDATE_CALCULATE_CAMPAIGN_DISCOUNT_FULFILLED";
export const UPDATE_CALCULATE_CAMPAIGN_DISCOUNT_REJECTED =
  "UPDATE_CALCULATE_CAMPAIGN_DISCOUNT_REJECTED";

export const CALLSP_APPROVAL_HIERARCHY = "CALLSP_APPROVAL_HIERARCHY";
export const CALLSP_APPROVAL_HIERARCHY_PENDING =
  "CALLSP_APPROVAL_HIERARCHY_PENDING";
export const CALLSP_APPROVAL_HIERARCHY_FULFILLED =
  "CALLSP_APPROVAL_HIERARCHY_FULFILLED";
export const CALLSP_APPROVAL_HIERARCHY_REJECTED =
  "CALLSP_APPROVAL_HIERARCHY_REJECTED";

export const EXECUTE_ORDER_APPROVAL_QUERY = "EXECUTE_ORDER_APPROVAL_QUERY";
export const EXECUTE_ORDER_APPROVAL_QUERY_PENDING =
  "EXECUTE_ORDER_APPROVAL_QUERY_PENDING";
export const EXECUTE_ORDER_APPROVAL_QUERY_FULFILLED =
  "EXECUTE_ORDER_APPROVAL_QUERY_FULFILLED";
export const EXECUTE_ORDER_APPROVAL_QUERY_REJECTED =
  "EXECUTE_ORDER_APPROVAL_QUERY_REJECTED";

export const EXECUTE_ORDER_APPROVAL_CANCEL = "EXECUTE_ORDER_APPROVAL_CANCEL";
export const EXECUTE_ORDER_DATE_APPROVAL = "EXECUTE_ORDER_DATE_APPROVAL";
export const EXECUTE_ORDER_APPROVAL_CANCEL_PENDING =
  "EXECUTE_ORDER_APPROVAL_CANCEL_PENDING";
export const EXECUTE_ORDER_APPROVAL_CANCEL_FULFILLED =
  "EXECUTE_ORDER_APPROVAL_CANCEL_FULFILLED";
export const EXECUTE_ORDER_APPROVAL_CANCEL_REJECTED =
  "EXECUTE_ORDER_APPROVAL_CANCEL_REJECTED";

export const GET_ORDER_PRODUCTS_OPTIONS = "GET_ORDER_PRODUCTS_OPTIONS";
export const GET_ORDER_PRODUCTS_OPTIONS_PENDING =
  "GET_ORDER_PRODUCTS_OPTIONS_PENDING";
export const GET_ORDER_PRODUCTS_OPTIONS_FULFILLED =
  "GET_ORDER_PRODUCTS_OPTIONS_FULFILLED";
export const GET_ORDER_PRODUCTS_OPTIONS_REJECTED =
  "GET_ORDER_PRODUCTS_OPTIONS_REJECTED";

export const GET_DEALER_PRODUCT_GROUPS = "GET_DEALER_PRODUCT_GROUPS";
export const GET_DEALER_PRODUCT_GROUPS_PENDING =
  "GET_DEALER_PRODUCT_GROUPS_PENDING";
export const GET_DEALER_PRODUCT_GROUPS_FULFILLED =
  "GET_DEALER_PRODUCT_GROUPS_FULFILLED";
export const GET_DEALER_PRODUCT_GROUPS_REJECTED =
  "GET_DEALER_PRODUCT_GROUPS_REJECTED";

export const RETURN_ADD_CAMPAIGN = "RETURN_ADD_CAMPAIGN";
export const RETURN_ADD_CAMPAIGN_PENDING = "RETURN_ADD_CAMPAIGN_PENDING";
export const RETURN_ADD_CAMPAIGN_FULFILLED = "RETURN_ADD_CAMPAIGN_FULFILLED";
export const RETURN_ADD_CAMPAIGN_REJECTED = "RETURN_ADD_CAMPAIGN_REJECTED";

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_PENDING = "UPDATE_CAMPAIGN_PENDING";
export const UPDATE_CAMPAIGN_FULFILLED = "UPDATE_CAMPAIGN_FULFILLED";
export const UPDATE_CAMPAIGN_REJECTED = "UPDATE_CAMPAIGN_REJECTED";

export const FETCH_PRODUCT_PRICE = "FETCH_PRODUCT_PRICE";
export const FETCH_PRODUCT_PRICE_PENDING = "FETCH_PRODUCT_PRICE_PENDING";
export const FETCH_PRODUCT_PRICE_FULFILLED = "FETCH_PRODUCT_PRICE_FULFILLED";
export const FETCH_PRODUCT_PRICE_REJECTED = "FETCH_PRODUCT_PRICE_REJECTED";

export const UPDATE_PRODUCT_PRICE = "UPDATE_PRODUCT_PRICE";
export const UPDATE_PRODUCT_PRICE_PENDING = "UPDATE_PRODUCT_PRICE_PENDING";
export const UPDATE_PRODUCT_PRICE_FULFILLED = "UPDATE_PRODUCT_PRICE_FULFILLED";
export const UPDATE_PRODUCT_PRICE_REJECTED = "UPDATE_PRODUCT_PRICE_REJECTED";

export const CHANGE_PRODUCT_PRICE = "CHANGE_PRODUCT_PRICE";

export const FETCH_PRODUCT_PRICE_LOG = "FETCH_PRODUCT_PRICE_LOG";
export const FETCH_PRODUCT_PRICE_LOG_PENDING =
  "FETCH_PRODUCT_PRICE_LOG_PENDING";
export const FETCH_PRODUCT_PRICE_LOG_FULFILLED =
  "FETCH_PRODUCT_PRICE_LOG_FULFILLED";
export const FETCH_PRODUCT_PRICE_LOG_REJECTED =
  "FETCH_PRODUCT_PRICE_LOG_REJECTED";

export const CHANGE_PRODUCT_PRICE_LOG = "CHANGE_PRODUCT_PRICE_LOG";

export const INSERT_BRY_CAMPAIGN_PRODUCTS = "INSERT_BRY_CAMPAIGN_PRODUCTS";
export const INSERT_BRY_CAMPAIGN_PRODUCTS_PENDING =
  "INSERT_BRY_CAMPAIGN_PRODUCTS_PENDING";
export const INSERT_BRY_CAMPAIGN_PRODUCTS_FULFILLED =
  "INSERT_BRY_CAMPAIGN_PRODUCTS_FULFILLED";
export const INSERT_BRY_CAMPAIGN_PRODUCTS_REJECTED =
  "INSERT_BRY_CAMPAIGN_PRODUCTS_REJECTED";

export const GET_ORDER_APPROVE_LOG = "GET_ORDER_APPROVE_LOG";
export const GET_ORDER_APPROVE_LOG_PENDING = "GET_ORDER_APPROVE_LOG_PENDING";
export const GET_ORDER_APPROVE_LOG_FULFILLED =
  "GET_ORDER_APPROVE_LOG_FULFILLED";
export const GET_ORDER_APPROVE_LOG_REJECTED = "GET_ORDER_APPROVE_LOG_REJECTED";

export const GET_DBS_BANKS = "GET_DBS_BANKS";
export const GET_DBS_BANKS_PENDING = "GET_DBS_BANKS_PENDING";
export const GET_DBS_BANKS_FULFILLED = "GET_DBS_BANKS_FULFILLED";
export const GET_DBS_BANKS_REJECTED = "GET_DBS_BANKS_REJECTED";

export const GET_BRY_BANK_INSTALLMENTS = "GET_BRY_BANK_INSTALLMENTS";
export const GET_BRY_BANK_INSTALLMENTS_PENDING =
  "GET_BRY_BANK_INSTALLMENTS_PENDING";
export const GET_BRY_BANK_INSTALLMENTS_FULFILLED =
  "GET_BRY_BANK_INSTALLMENTS_FULFILLED";
export const GET_BRY_BANK_INSTALLMENTS_REJECTED =
  "GET_BRY_BANK_INSTALLMENTS_REJECTED";

// CITY
export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_PENDING = "GET_CITIES_PENDING";
export const GET_CITIES_FULFILLED = "GET_CITIES_FULFILLED";
export const GET_CITIES_REJECTED = "GET_CITIES_REJECTED";
// End CITY

// USER
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_PENDING = "FETCH_USERS_PENDING";
export const FETCH_USERS_FULFILLED = "FETCH_USERS_FULFILLED";
export const FETCH_USERS_REJECTED = "FETCH_USERS_REJECTED";

export const FETCH_SATIS_TEMSILCILERI_USERS = "FETCH_SATIS_TEMSILCILERI_USERS";
export const FETCH_SATIS_TEMSILCILERI_USERS_PENDING =
  "FETCH_SATIS_TEMSILCILERI_USERS_PENDING";
export const FETCH_SATIS_TEMSILCILERI_USERS_FULFILLED =
  "FETCH_SATIS_TEMSILCILERI_USERS_FULFILLED";
export const FETCH_SATIS_TEMSILCILERI_USERS_REJECTED =
  "FETCH_SATIS_TEMSILCILERI_USERS_REJECTED";
// End USER

// USER PROJECT RESPONSIBILITY
export const FETCH_USER_PROJECT_RESPONSIBILITY =
  "FETCH_USER_PROJECT_RESPONSIBILITY";
export const FETCH_USER_PROJECT_RESPONSIBILITY_PENDING =
  "FETCH_USER_PROJECT_RESPONSIBILITY_PENDING";
export const FETCH_USER_PROJECT_RESPONSIBILITY_FULFILLED =
  "FETCH_USER_PROJECT_RESPONSIBILITY_FULFILLED";
export const FETCH_USER_PROJECT_RESPONSIBILITY_REJECTED =
  "FETCH_USER_PROJECT_RESPONSIBILITY_REJECTED";

export const INSERT_USER_PROJECT_RESPONSIBILITY =
  "INSERT_USER_PROJECT_RESPONSIBILITY";
export const INSERT_USER_PROJECT_RESPONSIBILITY_PENDING =
  "INSERT_USER_PROJECT_RESPONSIBILITY_PENDING";
export const INSERT_USER_PROJECT_RESPONSIBILITY_FULFILLED =
  "INSERT_USER_PROJECT_RESPONSIBILITY_FULFILLED";
export const INSERT_USER_PROJECT_RESPONSIBILITY_REJECTED =
  "INSERT_USER_PROJECT_RESPONSIBILITY_REJECTED";

export const UPDATE_USER_PROJECT_RESPONSIBILITY =
  "UPDATE_USER_PROJECT_RESPONSIBILITY";
export const UPDATE_USER_PROJECT_RESPONSIBILITY_PENDING =
  "UPDATE_USER_PROJECT_RESPONSIBILITY_PENDING";
export const UPDATE_USER_PROJECT_RESPONSIBILITY_FULFILLED =
  "UPDATE_USER_PROJECT_RESPONSIBILITY_FULFILLED";
export const UPDATE_USER_PROJECT_RESPONSIBILITY_REJECTED =
  "UPDATE_USER_PROJECT_RESPONSIBILITY_REJECTED";

export const DELETE_USER_PROJECT_RESPONSIBILITY =
  "DELETE_USER_PROJECT_RESPONSIBILITY";
export const DELETE_USER_PROJECT_RESPONSIBILITY_PENDING =
  "DELETE_USER_PROJECT_RESPONSIBILITY_PENDING";
export const DELETE_USER_PROJECT_RESPONSIBILITY_FULFILLED =
  "DELETE_USER_PROJECT_RESPONSIBILITY_FULFILLED";
export const DELETE_USER_PROJECT_RESPONSIBILITY_REJECTED =
  "DELETE_USER_PROJECT_RESPONSIBILITY_REJECTED";

export const CHANGE_USER_PROJECT_RESPONSIBILITY =
  "CHANGE_USER_PROJECT_RESPONSIBILITY";
// End USER_PROJECT_RESPONSIBILITY

// SALES_APPROVAL
export const FETCH_SALES_APPROVAL = "FETCH_SALES_APPROVAL";
export const FETCH_SALES_APPROVAL_PENDING = "FETCH_SALES_APPROVAL_PENDING";
export const FETCH_SALES_APPROVAL_FULFILLED = "FETCH_SALES_APPROVAL_FULFILLED";
export const FETCH_SALES_APPROVAL_REJECTED = "FETCH_SALES_APPROVAL_REJECTED";
// End SALES_APPROVAL

// PRODUCT_BOM
export const FETCH_PRODUCT_BOM = "FETCH_PRODUCT_BOM";
export const FETCH_PRODUCT_BOM_PENDING = "FETCH_PRODUCT_BOM_PENDING";
export const FETCH_PRODUCT_BOM_FULFILLED = "FETCH_PRODUCT_BOM_FULFILLED";
export const FETCH_PRODUCT_BOM_REJECTED = "FETCH_PRODUCT_BOM_REJECTED";

export const INSERT_PRODUCT_BOM = "INSERT_PRODUCT_BOM";
export const INSERT_PRODUCT_BOM_PENDING = "INSERT_PRODUCT_BOM_PENDING";
export const INSERT_PRODUCT_BOM_FULFILLED = "INSERT_PRODUCT_BOM_FULFILLED";
export const INSERT_PRODUCT_BOM_REJECTED = "INSERT_PRODUCT_BOM_REJECTED";

export const UPDATE_PRODUCT_BOM = "UPDATE_PRODUCT_BOM";
export const UPDATE_PRODUCT_BOM_PENDING = "UPDATE_PRODUCT_BOM_PENDING";
export const UPDATE_PRODUCT_BOM_FULFILLED = "UPDATE_PRODUCT_BOM_FULFILLED";
export const UPDATE_PRODUCT_BOM_REJECTED = "UPDATE_PRODUCT_BOM_REJECTED";

export const DELETE_PRODUCT_BOM = "DELETE_PRODUCT_BOM";
export const DELETE_PRODUCT_BOM_PENDING = "DELETE_PRODUCT_BOM_PENDING";
export const DELETE_PRODUCT_BOM_FULFILLED = "DELETE_PRODUCT_BOM_FULFILLED";
export const DELETE_PRODUCT_BOM_REJECTED = "DELETE_PRODUCT_BOM_REJECTED";

export const CHANGE_PRODUCT_BOM = "CHANGE_PRODUCT_BOM";
// End PRODUCT_BOM

// PRODUCT_LIST
export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";
export const FETCH_PRODUCT_LIST_PENDING = "FETCH_PRODUCT_LIST_PENDING";
export const FETCH_PRODUCT_LIST_FULFILLED = "FETCH_PRODUCT_LIST_FULFILLED";
export const FETCH_PRODUCT_LIST_REJECTED = "FETCH_PRODUCT_LIST_REJECTED";
// End PRODUCT_LIST

// SALES_REPRESENTATIVE_MANAGER
export const FETCH_SALES_REPRESENTATIVE_MANAGER =
  "FETCH_SALES_REPRESENTATIVE_MANAGER";
export const FETCH_SALES_REPRESENTATIVE_MANAGER_PENDING =
  "FETCH_SALES_REPRESENTATIVE_MANAGER_PENDING";
export const FETCH_SALES_REPRESENTATIVE_MANAGER_FULFILLED =
  "FETCH_SALES_REPRESENTATIVE_MANAGER_FULFILLED";
export const FETCH_SALES_REPRESENTATIVE_MANAGER_REJECTED =
  "FETCH_SALES_REPRESENTATIVE_MANAGER_REJECTED";

export const INSERT_SALES_REPRESENTATIVE_MANAGER =
  "INSERT_SALES_REPRESENTATIVE_MANAGER";
export const INSERT_SALES_REPRESENTATIVE_MANAGER_PENDING =
  "INSERT_SALES_REPRESENTATIVE_MANAGER_PENDING";
export const INSERT_SALES_REPRESENTATIVE_MANAGER_FULFILLED =
  "INSERT_SALES_REPRESENTATIVE_MANAGER_FULFILLED";
export const INSERT_SALES_REPRESENTATIVE_MANAGER_REJECTED =
  "INSERT_SALES_REPRESENTATIVE_MANAGER_REJECTED";

export const UPDATE_SALES_REPRESENTATIVE_MANAGER =
  "UPDATE_SALES_REPRESENTATIVE_MANAGER";
export const UPDATE_SALES_REPRESENTATIVE_MANAGER_PENDING =
  "UPDATE_SALES_REPRESENTATIVE_MANAGER_PENDING";
export const UPDATE_SALES_REPRESENTATIVE_MANAGER_FULFILLED =
  "UPDATE_SALES_REPRESENTATIVE_MANAGER_FULFILLED";
export const UPDATE_SALES_REPRESENTATIVE_MANAGER_REJECTED =
  "UPDATE_SALES_REPRESENTATIVE_MANAGER_REJECTED";

export const DELETE_SALES_REPRESENTATIVE_MANAGER =
  "DELETE_SALES_REPRESENTATIVE_MANAGER";
export const DELETE_SALES_REPRESENTATIVE_MANAGER_PENDING =
  "DELETE_SALES_REPRESENTATIVE_MANAGER_PENDING";
export const DELETE_SALES_REPRESENTATIVE_MANAGER_FULFILLED =
  "DELETE_SALES_REPRESENTATIVE_MANAGER_FULFILLED";
export const DELETE_SALES_REPRESENTATIVE_MANAGER_REJECTED =
  "DELETE_SALES_REPRESENTATIVE_MANAGER_REJECTED";

export const CHANGE_SALES_REPRESENTATIVE_MANAGER =
  "CHANGE_SALES_REPRESENTATIVE_MANAGER";
// End SALES_REPRESENTATIVE_MANAGER

// DEALER_PROPOSER
export const FETCH_DEALER_PROPOSER = "FETCH_DEALER_PROPOSER";
export const FETCH_DEALER_PROPOSER_PENDING = "FETCH_DEALER_PROPOSER_PENDING";
export const FETCH_DEALER_PROPOSER_FULFILLED =
  "FETCH_DEALER_PROPOSER_FULFILLED";
export const FETCH_DEALER_PROPOSER_REJECTED = "FETCH_DEALER_PROPOSER_REJECTED";

export const INSERT_DEALER_PROPOSER = "INSERT_DEALER_PROPOSER";
export const INSERT_DEALER_PROPOSER_PENDING = "INSERT_DEALER_PROPOSER_PENDING";
export const INSERT_DEALER_PROPOSER_FULFILLED =
  "INSERT_DEALER_PROPOSER_FULFILLED";
export const INSERT_DEALER_PROPOSER_REJECTED =
  "INSERT_DEALER_PROPOSER_REJECTED";

export const UPDATE_DEALER_PROPOSER = "UPDATE_DEALER_PROPOSER";
export const UPDATE_DEALER_PROPOSER_PENDING = "UPDATE_DEALER_PROPOSER_PENDING";
export const UPDATE_DEALER_PROPOSER_FULFILLED =
  "UPDATE_DEALER_PROPOSER_FULFILLED";
export const UPDATE_DEALER_PROPOSER_REJECTED =
  "UPDATE_DEALER_PROPOSER_REJECTED";

export const DELETE_DEALER_PROPOSER = "DELETE_DEALER_PROPOSER";
export const DELETE_DEALER_PROPOSER_PENDING = "DELETE_DEALER_PROPOSER_PENDING";
export const DELETE_DEALER_PROPOSER_FULFILLED =
  "DELETE_DEALER_PROPOSER_FULFILLED";
export const DELETE_DEALER_PROPOSER_REJECTED =
  "DELETE_DEALER_PROPOSER_REJECTED";

export const CHANGE_DEALER_PROPOSER = "CHANGE_DEALER_PROPOSER";
// End DEALER_PROPOSER

export const FETCH_USER_RIGHTS = "FETCH_USER_RIGHTS";
export const FETCH_USER_RIGHTS_PENDING = "FETCH_USER_RIGHTS_PENDING";
export const FETCH_USER_RIGHTS_FULFILLED = "FETCH_USER_RIGHTS_FULFILLED";
export const FETCH_USER_RIGHTS_REJECTED = "FETCH_USER_RIGHTS_REJECTED";

export const CHANGE_USER_RIGHTS = "CHANGE_USER_RIGHTS";

export const FETCH_USER_SALES_RIGHTS = "FETCH_USER_SALES_RIGHTS";
export const FETCH_USER_SALES_RIGHTS_PENDING =
  "FETCH_USER_SALES_RIGHTS_PENDING";
export const FETCH_USER_SALES_RIGHTS_FULFILLED =
  "FETCH_USER_SALES_RIGHTS_FULFILLED";
export const FETCH_USER_SALES_RIGHTS_REJECTED =
  "FETCH_USER_SALES_RIGHTS_REJECTED";

export const CHANGE_USER_SALES_RIGHTS = "CHANGE_USER_SALES_RIGHTS";

export const FETCH_CUSTOMER_PRODUCT = "FETCH_CUSTOMER_PRODUCT";
export const FETCH_CUSTOMER_PRODUCT_PENDING = "FETCH_CUSTOMER_PRODUCT_PENDING";
export const FETCH_CUSTOMER_PRODUCT_FULFILLED =
  "FETCH_CUSTOMER_PRODUCT_FULFILLED";
export const FETCH_CUSTOMER_PRODUCT_REJECTED =
  "FETCH_CUSTOMER_PRODUCT_REJECTED";

export const CHANGE_CUSTOMER_PRODUCT = "CHANGE_CUSTOMER_PRODUCT";

export const FETCH_CUSTOMER_PRODUCT_GROUP = "FETCH_CUSTOMER_PRODUCT_GROUP";
export const FETCH_CUSTOMER_PRODUCT_GROUP_PENDING =
  "FETCH_CUSTOMER_PRODUCT_GROUP_PENDING";
export const FETCH_CUSTOMER_PRODUCT_GROUP_FULFILLED =
  "FETCH_CUSTOMER_PRODUCT_GROUP_FULFILLED";
export const FETCH_CUSTOMER_PRODUCT_GROUP_REJECTED =
  "FETCH_CUSTOMER_PRODUCT_GROUP_REJECTED";

export const CHANGE_CUSTOMER_PRODUCT_GROUP = "CHANGE_CUSTOMER_PRODUCT_GROUP";

export const FETCH_CUSTOMER_GROUP_PRODUCT = "FETCH_CUSTOMER_GROUP_PRODUCT";
export const FETCH_CUSTOMER_GROUP_PRODUCT_PENDING =
  "FETCH_CUSTOMER_GROUP_PRODUCT_PENDING";
export const FETCH_CUSTOMER_GROUP_PRODUCT_FULFILLED =
  "FETCH_CUSTOMER_GROUP_PRODUCT_FULFILLED";
export const FETCH_CUSTOMER_GROUP_PRODUCT_REJECTED =
  "FETCH_CUSTOMER_GROUP_PRODUCT_REJECTED";

export const CHANGE_CUSTOMER_GROUP_PRODUCT = "CHANGE_CUSTOMER_GROUP_PRODUCT";

export const FETCH_CUSTOMER_GROUP_PRODUCT_GROUP =
  "FETCH_CUSTOMER_GROUP_PRODUCT_GROUP";
export const FETCH_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING =
  "FETCH_CUSTOMER_GROUP_PRODUCT_GROUP_PENDING";
export const FETCH_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED =
  "FETCH_CUSTOMER_GROUP_PRODUCT_GROUP_FULFILLED";
export const FETCH_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED =
  "FETCH_CUSTOMER_GROUP_PRODUCT_GROUP_REJECTED";

export const CHANGE_CUSTOMER_GROUP_PRODUCT_GROUP =
  "CHANGE_CUSTOMER_GROUP_PRODUCT_GROUP";

export const FETCH_SALES_CHANNEL_PRODUCT = "FETCH_SALES_CHANNEL_PRODUCT";
export const FETCH_SALES_CHANNEL_PRODUCT_PENDING =
  "FETCH_SALES_CHANNEL_PRODUCT_PENDING";
export const FETCH_SALES_CHANNEL_PRODUCT_FULFILLED =
  "FETCH_SALES_CHANNEL_PRODUCT_FULFILLED";
export const FETCH_SALES_CHANNEL_PRODUCT_REJECTED =
  "FETCH_SALES_CHANNEL_PRODUCT_REJECTED";

export const CHANGE_SALES_CHANNEL_PRODUCT = "CHANGE_SALES_CHANNEL_PRODUCT";

export const FETCH_SALES_CHANNEL_PRODUCT_GROUP =
  "FETCH_SALES_CHANNEL_PRODUCT_GROUP";
export const FETCH_SALES_CHANNEL_PRODUCT_GROUP_PENDING =
  "FETCH_SALES_CHANNEL_PRODUCT_GROUP_PENDING";
export const FETCH_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED =
  "FETCH_SALES_CHANNEL_PRODUCT_GROUP_FULFILLED";
export const FETCH_SALES_CHANNEL_PRODUCT_GROUP_REJECTED =
  "FETCH_SALES_CHANNEL_PRODUCT_GROUP_REJECTED";

export const CHANGE_SALES_CHANNEL_PRODUCT_GROUP =
  "CHANGE_SALES_CHANNEL_PRODUCT_GROUP";

export const CALLSP_ORDER_SAP_STATUS = "CALLSP_ORDER_SAP_STATUS";
export const CALLSP_ORDER_SAP_STATUS_PENDING =
  "CALLSP_ORDER_SAP_STATUS_PENDING";
export const CALLSP_ORDER_SAP_STATUS_FULFILLED =
  "CALLSP_ORDER_SAP_STATUS_FULFILLED";
export const CALLSP_ORDER_SAP_STATUS_REJECTED =
  "CALLSP_ORDER_SAP_STATUS_REJECTED";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_PENDING = "GET_COUNTRIES_PENDING";
export const GET_COUNTRIES_FULFILLED = "GET_COUNTRIES_FULFILLED";
export const GET_COUNTRIES_REJECTED = "GET_COUNTRIES_REJECTED";

export const GET_PAYMENT_TERMS = "GET_PAYMENT_TERMS";
export const GET_PAYMENT_TERMS_PENDING = "GET_PAYMENT_TERMS_PENDING";
export const GET_PAYMENT_TERMS_FULFILLED = "GET_PAYMENT_TERMS_FULFILLED";
export const GET_PAYMENT_TERMS_REJECTED = "GET_PAYMENT_TERMS_REJECTED";

export const GET_PAYMENT_EXPIRY = "GET_PAYMENT_EXPIRY";
export const GET_PAYMENT_EXPIRY_PENDING = "GET_PAYMENT_EXPIRY_PENDING";
export const GET_PAYMENT_EXPIRY_FULFILLED = "GET_PAYMENT_EXPIRY_FULFILLED";
export const GET_PAYMENT_EXPIRY_REJECTED = "GET_PAYMENT_EXPIRY_REJECTED";

export const GET_COEFFICIENTS = "GET_COEFFICIENTS";
export const GET_COEFFICIENTS_PENDING = "GET_COEFFICIENTS_PENDING";
export const GET_COEFFICIENTS_FULFILLED = "GET_COEFFICIENTS_FULFILLED";
export const GET_COEFFICIENTS_REJECTED = "GET_COEFFICIENTS_REJECTED";

export const FETCH_BRY_PRODUCT_BRAND = "FETCH_BRY_PRODUCT_BRAND";
export const FETCH_BRY_PRODUCT_BRAND_PENDING =
  "FETCH_BRY_PRODUCT_BRAND_PENDING";
export const FETCH_BRY_PRODUCT_BRAND_FULFILLED =
  "FETCH_BRY_PRODUCT_BRAND_FULFILLED";
export const FETCH_BRY_PRODUCT_BRAND_REJECTED =
  "FETCH_BRY_PRODUCT_BRAND_REJECTED";

export const GET_ORDER_ADDRESS_INFO = "GET_ORDER_ADDRESS_INFO";
export const GET_ORDER_ADDRESS_INFO_PENDING = "GET_ORDER_ADDRESS_INFO_PENDING";
export const GET_ORDER_ADDRESS_INFO_FULFILLED =
  "GET_ORDER_ADDRESS_INFO_FULFILLED";
export const GET_ORDER_ADDRESS_INFO_REJECTED =
  "GET_ORDER_ADDRESS_INFO_REJECTED";

export const UPDATE_ORDER_ADDRESS_INFO = "UPDATE_ORDER_ADDRESS_INFO";
export const UPDATE_ORDER_ADDRESS_INFO_PENDING =
  "UPDATE_ORDER_ADDRESS_INFO_PENDING";
export const UPDATE_ORDER_ADDRESS_INFO_FULFILLED =
  "UPDATE_ORDER_ADDRESS_INFO_FULFILLED";
export const UPDATE_ORDER_ADDRESS_INFO_REJECTED =
  "UPDATE_ORDER_ADDRESS_INFO_REJECTED";

export const SET_ORDER_ADDRESS_INFO = "SET_ORDER_ADDRESS_INFO";

export const FETCH_ORDER_RESPONSIBLE_PERSON = "FETCH_ORDER_RESPONSIBLE_PERSON";
export const FETCH_ORDER_RESPONSIBLE_PERSON_PENDING =
  "FETCH_ORDER_RESPONSIBLE_PERSON_PENDING";
export const FETCH_ORDER_RESPONSIBLE_PERSON_FULFILLED =
  "FETCH_ORDER_RESPONSIBLE_PERSON_FULFILLED";
export const FETCH_ORDER_RESPONSIBLE_PERSON_REJECTED =
  "FETCH_ORDER_RESPONSIBLE_PERSON_REJECTED";

export const CHANGE_ORDER_RESPONSIBLE_PERSON =
  "CHANGE_ORDER_RESPONSIBLE_PERSON";

export const UPDATE_ORDER_RESPONSIBLE_PERSON =
  "UPDATE_ORDER_RESPONSIBLE_PERSON";
export const UPDATE_ORDER_RESPONSIBLE_PERSON_PENDING =
  "UPDATE_ORDER_RESPONSIBLE_PERSON_PENDING";
export const UPDATE_ORDER_RESPONSIBLE_PERSON_FULFILLED =
  "UPDATE_ORDER_RESPONSIBLE_PERSON_FULFILLED";
export const UPDATE_ORDER_RESPONSIBLE_PERSON_REJECTED =
  "UPDATE_ORDER_RESPONSIBLE_PERSON_REJECTED";

export const DELETE_ORDER_RESPONSIBLE_PERSON =
  "DELETE_ORDER_RESPONSIBLE_PERSON";
export const DELETE_ORDER_RESPONSIBLE_PERSON_PENDING =
  "DELETE_ORDER_RESPONSIBLE_PERSON_PENDING";
export const DELETE_ORDER_RESPONSIBLE_PERSON_FULFILLED =
  "DELETE_ORDER_RESPONSIBLE_PERSON_FULFILLED";
export const DELETE_ORDER_RESPONSIBLE_PERSON_REJECTED =
  "DELETE_ORDER_RESPONSIBLE_PERSON_REJECTED";

//Excel
export const CHANGE_EXCEL_VISIBILITY = "CHANGE_EXCEL_VISIBILITY";
export const CHANGE_EXCEL_VISIBILITY_DATA = "CHANGE_EXCEL_VISIBILITY_DATA";

export const FETCH_EXCEL = "FETCH_EXCEL";
export const FETCH_EXCEL_PENDING = "FETCH_EXCEL_PENDING";
export const FETCH_EXCEL_FULFILLED = "FETCH_EXCEL_FULFILLED";
export const FETCH_EXCEL_REJECTED = "FETCH_EXCEL_REJECTED";
export const CHANGE_EXCEL = "CHANGE_EXCEL";

export const CALLSP_COPY_ORDER = "CALLSP_COPY_ORDER";
export const CALLSP_COPY_ORDER_PENDING = "CALLSP_COPY_ORDER_PENDING";
export const CALLSP_COPY_ORDER_FULFILLED = "CALLSP_COPY_ORDER_FULFILLED";
export const CALLSP_COPY_ORDER_REJECTED = "CALLSP_COPY_ORDER_REJECTED";

export const GET_USER_SALES_REGIONS = "GET_USER_SALES_REGIONS";
export const GET_USER_SALES_REGIONS_PENDING = "GET_USER_SALES_REGIONS_PENDING";
export const GET_USER_SALES_REGIONS_FULFILLED =
  "GET_USER_SALES_REGIONS_FULFILLED";
export const GET_USER_SALES_REGIONS_REJECTED =
  "GET_USER_SALES_REGIONS_REJECTED";
