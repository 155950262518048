import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../config/globalVariables";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";

class OrderDateApproveService {
  getApproveList(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparistarihonay/get-approve-list?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getById(orderDateApproveId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparistarihonay/get-by-id/${orderDateApproveId}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getHistoryLogByOrderId(orderId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparistarihonay/get-histoy-log/${orderId}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  executeOrderDateApproval(data, callback) {
    return axios
      .post(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparistarihonay/execute-order-date-approval`,
        data
      )
      .then((response) => {
        defaultAlertMessages.MessageDetailSuccess = response.message;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        if (callback) callback(response);
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new OrderDateApproveService();
