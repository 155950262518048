import { TIMEZONE } from "../../../config/globalVariables";
import { getLocalDate } from "../../../helpers/utils";
import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function orderReducer(state = initialState.order, action) {
  switch (action.type) {
    case actionTypes.GET_ORDER:
      return {
        ...state,
        _order: action.payload,
      };
    case actionTypes.GET_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_ORDER_FULFILLED:
      return {
        ...state,
        _order: action.payload,
        fetching: false,
      };
    case actionTypes.GET_ORDER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CALLSP_ADD_ORDER:
      return {
        ...state,
        _order: action.payload.data,
      };
    case actionTypes.CALLSP_ADD_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
        dataInserted: false,
      };
    case actionTypes.CALLSP_ADD_ORDER_FULFILLED:
      return {
        ...state,
        _order: action.payload.data,
        dataInserted: true,
        fetching: false,
      };
    case actionTypes.CALLSP_ADD_ORDER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.UPDATE_ORDER:
      return {
        ...state,
      };
    case actionTypes.UPDATE_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.UPDATE_ORDER_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.UPDATE_ORDER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CALLSP_ORDER_SAP_STATUS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.CALLSP_ORDER_SAP_STATUS_FULFILLED:
      return {
        ...state,
        fetching: false,
        dataUpdated: action.payload.success,
      };
    case actionTypes.CALLSP_ORDER_SAP_STATUS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CALLSP_COPY_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.CALLSP_COPY_ORDER_FULFILLED:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.CALLSP_COPY_ORDER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.SET_DEALER_TO_ORDER:
      var newDealerForOrder = { bayI_KODU: action.payload };
      return {
        ...state,
        _order: {
          ...state._order,
          ...newDealerForOrder,
        },
      };
    case actionTypes.SET_CAMPAIGN_TO_ORDER:
      var campaignInfo = {
        kampanyA_KODU: action.payload.kampanyA_KODU,
        listE_FIYATI_VADESI: action.payload.listE_FIYATI_VADESI,
        maX_ODEME_VADESI: action.payload.maX_ODEME_VADESI,
        artI_GUN: action.payload.artI_GUN,
        odemE_VADESI_TIPI: action.payload.faturA_VADESI
          ? "FATURA_VADESI"
          : action.payload.maX_ODEME_VADESI !== null ||
            action.payload.artI_GUN !== null
          ? "FATURA_VADESI"
          : null,
        faturA_VADESI: action.payload.faturA_VADESI,
      };

      return {
        ...state,
        _order: { ...state._order, ...campaignInfo },
      };
    case actionTypes.SET_FATURA_VADESI_TO_ORDER:
      var newFaturaVadesiForOrder = { faturA_VADESI: action.payload };
      return {
        ...state,
        _order: {
          ...state._order,
          ...newFaturaVadesiForOrder,
        },
      };
    case actionTypes.SET_ORDER:
      var newOrder = {};
      if (action.payload.target.element.current) {
        newOrder = {
          [action.payload.target.element.current.name]: action.payload.value,
        };
      } else {
        newOrder = {
          [action.payload.target.name]: action.payload.target.value.bayI_KODU
            ? action.payload.target.value.bayI_KODU
            : action.payload.target.value.brY_KANAL
            ? action.payload.target.value.brY_KANAL
            : action.payload.target.value.saP_DURUM
            ? action.payload.target.value.saP_DURUM
            : action.payload.target.value.dkN_FIN_BANKA_LIST_ID
            ? action.payload.target.value.dkN_FIN_BANKA_LIST_ID
            : action.payload.target.value.kampanyA_KODU
            ? action.payload.target.value.kampanyA_KODU
            : action.payload.target.value.kosuL_KODU
            ? action.payload.target.value.kosuL_KODU
            : action.payload.target.value.ulkE_TANIM_ID
            ? action.payload.target.value.ulkE_TANIM_ID
            : action.payload.target.value.id ||
              action.payload.target.value.id === "0"
            ? action.payload.target.value.id
            : action.payload.target.value,
        };

        if (
          action.payload.target.props.name === "sipariS_TURU" &&
          action.payload.value.id !== "KAMPANYA"
        ) {
          newOrder["kampanyA_KODU"] = "";
        }

        if (action.payload.target.props.name === "bayI_KODU") {
          newOrder["satiS_DEPARTMANI_KODU"] = "";
        }
        if (action.payload.target.props.name === "sevK_ADRESI_TIPI") {
          newOrder["sevK_ADRESI_TIPI"] =
            action.payload.target.value.adreS_BASLIGI;
        }

        if (action.payload.target.props.name === "satiS_DEPARTMANI_KODU") {
          if (action.payload.value.id === "EXPORT_UPG") {
            newOrder["sipariS_TURU"] = "STANDART";
            newOrder["brY_KANAL"] = "EXPORT";
            newOrder["kampanyA_KODU"] = "";
            newOrder["odemE_VADESI_TIPI"] = null;
            newOrder["faturA_VADESI"] = null;
            newOrder["odemE_VADE_TARIHI"] = null;
            newOrder["listE_FIYATI_VADESI"] = null;
            newOrder["maX_ODEME_VADESI"] = null;
            newOrder["artI_GUN"] = null;
            newOrder["sevK_ZAMANI"] = "FARK_ETMEZ";
          } else {
            newOrder["brY_KANAL"] = "";
            newOrder["exporT_SIPARIS_TIPI"] = "";
            newOrder["exporT_SIPARIS_TURU"] = "";
            newOrder["incoterms"] = "";
            newOrder["incoterms2"] = "";
            newOrder["sevK_YAPILACAK_ULKE_TANIM_ID"] = null;
            newOrder["odemE_KOSULU"] = "";
            newOrder["sevK_ZAMANI"] = "";
          }
        }

        if (action.payload.target.props.name === "odemE_VADESI_TIPI") {
          if (action.payload.target.value.id === "NOKTA_VADE") {
            newOrder["faturA_VADESI"] = null;
          } else if (action.payload.target.value.id === "FATURA_VADESI") {
            newOrder["odemE_VADE_TARIHI"] = null;
          }
        }

        if (
          action.payload.target.props.name === "odemE_TIPI" &&
          action.payload.target.value.id !== "DBS"
        ) {
          newOrder["taksiT_SAYISI"] = null;
          newOrder["dkN_FIN_BANKA_LIST_ID"] = null;
        }

        if (
          action.payload.target.props.name === "sevK_SEKLI" &&
          action.payload.target.value.id !== "PARTI_PARTI"
        ) {
          newOrder["sevK_PARTI_ACIKLAMA"] = null;
        }

        // if (
        //   action.payload.target.props.name === "parA_BIRIMI" &&
        //   action.payload.target.value.id === "TRY"
        // ) {
        //   newOrder["faturA_KURU"] = null;
        // }

        if (
          (action.payload.target.props.name === "sevK_EDILMEME_NEDENI" ||
            action.payload.target.props.name === "sevK_EDILMEME_DETAYI" ||
            action.payload.target.props.name === "sevK_SEKLI" ||
            action.payload.target.props.name === "sevK_ARAC_TIPI" ||
            action.payload.target.props.name === "sevK_ZAMANI" ||
            action.payload.target.props.name === "yesiL_BINA_SERTIFIKA") &&
          action.payload.target.value.id === "-1"
        ) {
          newOrder[action.payload.target.props.name] = null;
        }

        if (
          action.payload.target.props.name === "faturA_VADESI" ||
          action.payload.target.props.name === "listE_FIYATI_VADESI" ||
          action.payload.target.props.name === "taksiT_SAYISI" ||
          action.payload.target.props.name === "maX_ODEME_VADESI" ||
          action.payload.target.props.name === "artI_GUN"
        ) {
          newOrder[action.payload.target.props.name] = parseInt(
            action.payload.target.value.id
          );
        }

        if (action.payload.target.dateInput) {
          newOrder[action.payload.target.name] = getLocalDate(
            action.payload.target.value,
            TIMEZONE
          );
        }
      }

      return {
        ...state,
        _order: { ...state._order, ...newOrder },
      };
    default:
      return state;
  }
}
