import React from "react";
import { useSelector } from "react-redux";
import { Window } from "@progress/kendo-react-dialogs";
import OrderDiscountProductGroupList from "./OrderDiscountProductGroupList";

const OrderDiscountProductGroupModal = ({ toggleDialog, orderId }) => {
  const auth = useSelector((state) => state.authReducer);

  return (
    <Window
      title="Sipariş Discount Ürün Grubu Listesi"
      onClose={toggleDialog}
      initialHeight="40vh"
      initialWidth="35%"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <OrderDiscountProductGroupList orderId={orderId} />
    </Window>
  );
};

export default OrderDiscountProductGroupModal;
