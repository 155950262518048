import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getLocalDate,
} from "../../helpers/utils";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import OfferService from "../../services/srv-offer/OfferService";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import NumberFormat from "react-number-format";
import { formatDate } from "@telerik/kendo-intl";
import { TIMEZONE } from "../../config/globalVariables";
import { MyOrderProductCommandCell } from "./MyOrderProductCommandCell";
import CommonDatePicker from "../sys-common/CommonDatePicker";

export default function MrcProductList(props) {
  const _grid = useRef(null);

  const editField = "inEdit";
  const primaryKey = "mrC_TEKLIF_URUN_ID";

  // Data Related Functions Begin
  const [products, setProducts] = useState({ data: [] });
  const [originalProducts, setOriginalProducts] = useState({ data: [] });
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsDataState, setProductsDataState] = useState({
    take: 9999,
    skip: 0,
  });

  function onCompletedGetProducts(result) {
    setProducts({ data: result });
    setOriginalProducts({ data: result });
    if (result.dataState) {
      setProductsDataState(result.dataState);
    }
    setProductsLoading(false);
  }

  useEffect(() => {
    getProductsByFilter();
  }, [productsDataState]);

  function getProductsByFilter() {
    setProductsLoading(true);
    OfferService.getOfferProduct(props.teklifId, onCompletedGetProducts);
  }

  function onLoadingCompleted(result) {
    setProductsLoading(false);
    getProductsByFilter();
  }
  function onDataStateChange(event) {
    filterLocalization(event);
    setProductsDataState(event.dataState);
  }

  function onItemChange(event) {
    const data = products.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setProducts({ ...products, data });
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  }
  function enterEditCommand(dataItem) {
    var isOpenEdit = products.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = products.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setProducts({ ...products, data });
    }
  }
  function discardCommand() {
    const data = [...products.data];
    data.splice(0, 1);
    setProducts({ ...products, data });
  }
  function updateCommand(dataItem) {
    setProductsLoading(true);
    OfferService.productReviseUpdate(
      dataItem,
      onLoadingCompleted,
      onLoadingCompleted
    );
    dataItem.inEdit = false;
  }
  function cancelCommand(dataItem) {
    const originalItem = originalProducts.data.find(
      (p) => p[primaryKey] === dataItem[primaryKey]
    );
    const data = products.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    setProducts({ ...products, data });
  }
  function CommandCell(props) {
    return props.dataItem.puto !== "MST" ? (
      <MyOrderProductCommandCell
        {...props}
        edit={enterEditCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={editField}
        idField={primaryKey}
        nameField={primaryKey}
      />
    ) : (
      <td></td>
    );
  }

  const PutoCellNumber = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return NumberFormatCell(dataValue, props.dataItem, false, false);
  };

  const NumberFormatCell = (value, dataItem, isPercent, isKDV) => {
    return (
      <td
        style={{
          fontStyle: dataItem.puto === "DET" ? "italic" : "normal",
          color: dataItem.puto === "DET" ? "gray" : "rgba(0,0,0,0.78)",
        }}
      >
        {isPercent ? "% " : ""}
        <NumberFormat
          thousandSeparator={"."}
          value={value ? (isKDV ? value * 100 : value) : 0}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="5"
        ></NumberFormat>
      </td>
    );
  };

  const PutoCellString = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    if (dataItem.puto === "DET") {
      return (
        <td style={{ fontStyle: "italic", color: "gray" }}>
          {dataValue.toString()} ({dataItem.uruN_ADI})
        </td>
      );
    }
    return (
      <td>
        {dataValue.toString()} ({dataItem.uruN_ADI})
      </td>
    );
  };

  const GridDateFormatCellEditable = (props, format) => {
    const value = props.dataItem[props.field];
    if (props.dataItem.inEdit) {
      return (
        <td>
          <CommonDatePicker
            label="Revize Teslim Tarihi"
            id="revizE_ISTENEN_TESLIM_TARIHI"
            min={new Date()}
            value={props.dataItem.revizE_ISTENEN_TESLIM_TARIHI}
            required
            onChange={(e) =>
              setProducts({
                ...products,
                data: products.data.map((item) =>
                  item[primaryKey] === props.dataItem[primaryKey]
                    ? {
                        ...item,
                        revizE_ISTENEN_TESLIM_TARIHI: getLocalDate(
                          e.target.value,
                          TIMEZONE
                        ),
                      }
                    : item
                ),
              })
            }
          />
        </td>
      );
    } else {
      if (value === null || value === undefined || value === "") {
        return <td title={format}></td>;
      } else {
        return <td title={format}>{formatDate(new Date(value), format)}</td>;
      }
    }
  };

  return (
    <main style={{ flexGrow: "1" }}>
      {productsLoading && LoadingPanel}
      <h4>Ürün Listesi</h4>
      <Grid
        ref={_grid}
        data={products}
        {...productsDataState}
        dataItemKey={primaryKey}
        editField={editField}
        onDataStateChange={onDataStateChange}
        onItemChange={onItemChange}
        rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
        sortable={false}
        resizable={true}
        filterable={false}
        confirmat
        style={{ maxHeight: "600px" }}
      >
        <GridColumn cell={CommandCell} width="220px" filterable={false} />
        <GridColumn
          field="uruN_ADI"
          title="Ürün Adı"
          width="200px"
          cell={PutoCellString}
          editable={false}
        ></GridColumn>
        {/* <GridColumn
          field="seciM_FIYATI"
          title="Seçim Fiyatı"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn> */}
        <GridColumn
          field="adet"
          title="ADET"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn>
        {/* <GridColumn
          field="listE_FIYATI"
          title="Liste Fiyatı"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn>
        <GridColumn
          field="indiriM_CARPANI"
          title="İndirim Çarpanı"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn>
        <GridColumn
          field="hedeF_BIRIM_FIYAT"
          title="Hedef Birim Fiyat"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn>
        <GridColumn
          field="biriM_FIYAT"
          title="Birim Fiyat"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn>
        <GridColumn
          field="mL_CARPANI"
          title="ML Çarpanı"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn>
        <GridColumn
          field="otV_CARPANI"
          title="OTV Çarpanı"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn> */}
        <GridColumn
          field="toplaM_FIYAT"
          title="Toplam Fiyat"
          filterable={false}
          editable={false}
          cell={PutoCellNumber}
        ></GridColumn>
        <GridColumn
          field="tesliM_TARIHI"
          title="Teslim Tarihi"
          cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
          filterable={false}
          editable={false}
        ></GridColumn>
        <GridColumn
          field="revizE_ISTENEN_TESLIM_TARIHI"
          title="Revize Teslim Tarihi"
          cell={(props) => GridDateFormatCellEditable(props, "dd.MM.yyyy")}
          filterable={false}
          width={"150px"}
        ></GridColumn>
      </Grid>
    </main>
  );
}
