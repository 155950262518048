import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { Window } from "@progress/kendo-react-dialogs";
import DealerDropDownList from "../sys-common/DealerDropDownList";
import { copyOrder } from "../../redux/actions/act-order/orderActions";
import { BASE_URL } from "../../config/globalVariables";

const OrderCopy = ({ toggleDialog }) => {
  const { brY_SIPARIS_ID, bayI_KODU: initialBayiKodu } = useSelector(
    (state) => state.orderReducer._order
  );
  const dispatch = useDispatch();

  const [bayiKodu, setBayiKodu] = useState(initialBayiKodu);
  const [newOrderId, setNewOrderId] = useState(null);

  const handleCopyOrderResponse = useCallback((response) => {
    if (response.success) {
      setNewOrderId(response.data.brY_YENI_SIPARIS_ID);
    }
  }, []);

  const handleCopyOrder = useCallback(() => {
    const data = {
      BRY_SIPARIS_ID: brY_SIPARIS_ID,
      BAYI_KODU: bayiKodu,
    };
    dispatch(copyOrder(data, handleCopyOrderResponse));
  }, [brY_SIPARIS_ID, bayiKodu, dispatch, handleCopyOrderResponse]);

  const handleBayiKoduChange = useCallback((e) => {
    setBayiKodu(e.value.bayI_KODU);
  }, []);

  const handleGoToNewOrder = useCallback(() => {
    window.open(`${BASE_URL}order-update/${newOrderId}`);
  }, [newOrderId]);

  const renderButton = () =>
    newOrderId ? (
      <Button color="success" onClick={handleGoToNewOrder}>
        <i className="fas fa-arrow-right mr-2" />
        Kopyalanan Siparişe Git
      </Button>
    ) : (
      <Button color="primary" onClick={handleCopyOrder}>
        <i className="fas fa-check-circle mr-2" />
        Kopyala
      </Button>
    );

  return (
    <Window
      title="Sipariş Kopyala"
      onClose={toggleDialog}
      initialHeight="40vh"
      initialWidth="35%"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <DealerDropDownList
        id="bayI_KODU"
        label="Bayi Seçimi (Arama yapmak için en az 3 harf giriniz.)"
        value={bayiKodu}
        onChange={handleBayiKoduChange}
        required
      />
      <Row className="mt-4">
        <Col xs={12} className="text-center">
          {renderButton()}
        </Col>
      </Row>
    </Window>
  );
};

export default OrderCopy;
