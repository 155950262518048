import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";

export const MyOrderProductCommandCell = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem[props.idField] === undefined;

  return inEdit ? (
    <td className="k-command-cell">
      <Button
        icon="check"
        look="outline"
        className="k-button k-grid-save-command"
        onClick={() =>
          isNewItem ? props.add(dataItem) : props.update(dataItem)
        }
      >
        Onay
      </Button>
      <Button
        icon="times"
        look="outline"
        className="k-button k-grid-cancel-command"
        onClick={() =>
          isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
        }
      >
        İptal
      </Button>
    </td>
  ) : (
    <td className="k-command-cell">
      <Button
        icon="pencil"
        look="outline"
        className="k-button k-grid-edit-command "
        onClick={() => props.edit(dataItem)}
      >
        Revize Güncelle
      </Button>
    </td>
  );
};
