import { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { getOrderProducts } from "../../redux/actions/act-order/orderProductActions";
import OrderDateApproveProductList from "./OrderDateApproveProductList";
import { OrderDateApproveProps } from "./OrderDateApproveProps";
import OrderDateApproveService from "../../services/srv-order-date-approval/OrderDateApproveService";
import { getOrder } from "../../redux/actions/act-order/orderActions";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { Button, Col, Row } from "reactstrap";
import { TextArea } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import { confirmAlert } from "react-confirm-alert";
import { getConfirmWarningMessage } from "../../helpers/utils";
import { BASE_URL } from "../../config/globalVariables";

const OrderDateApproveDetail = (props) => {
  const orderDateApproveId = props.match.params.orderDateApproveId;

  const [orderLoading, setOrderLoading] = useState(true);
  const [orderDateAproval, setOrderDateAproval] = useState({});
  const [orderDateHistoryLog, setOrderDateHistoryLog] = useState([]);

  const [timeLeft, setTimeLeft] = useState(120);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    getOrderDateApproveById();
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsActive(false);
      clearInterval(interval);
    }

    if (timeLeft === 1) {
      window.location.replace(BASE_URL);
    }

    return () => clearInterval(interval);
  }, [isActive, timeLeft]);

  const addTime = () => {
    setTimeLeft((time) => time + 60);
    setIsActive(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const orderDateApproveReject = (islem) => {
    // islem = "ONAY" || "RED"
    var tarihOnay = {
      ISLEM: islem,
      BRY_SIPARIS_TARIH_ONAY_ID: orderDateAproval?.brY_SIPARIS_TARIH_ONAY_ID,
      BRY_SIPARIS_ID: orderDateAproval?.brY_SIPARIS_ID,
      ACIKLAMA: orderDateAproval?.aciklama,
    };
    OrderDateApproveService.executeOrderDateApproval(tarihOnay).then(() => {
      getOrderDateApproveById();
    });
  };

  function onCompletedGetOrderDateApproveById(data) {
    setOrderDateAproval(data);
    props.getOrderProducts(data.brY_SIPARIS_ID);
    props.getOrder(data.brY_SIPARIS_ID);
    OrderDateApproveService.getHistoryLogByOrderId(
      data.brY_SIPARIS_ID,
      (data) => {
        setOrderDateHistoryLog(data);
      }
    ).then(() => setOrderLoading(false));
    if (data.onaY_RED !== null) {
      setTimeLeft(0);
    }
  }

  function getOrderDateApproveById() {
    setOrderLoading(true);
    OrderDateApproveService.getById(
      orderDateApproveId,
      onCompletedGetOrderDateApproveById
    );
  }

  function refreshProductList() {
    props.getOrderProducts(orderDateAproval?.brY_SIPARIS_ID);
  }

  const approveCancel = () => {
    const defaultAlertMessages = {
      TittleWarning: "Uyarı",
      MessageDetailWarning: "Tarih onayı reddedilecektir. Onaylıyor musunuz?",
      Yes: "Evet",
      No: "Hayır",
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmWarningMessage(
          onClose,
          () => orderDateApproveReject("RED"),
          defaultAlertMessages
        );
      },
    });
  };

  const maxRDDDate =
    props.orderProductList?.fetching === false &&
    props.orderProductList?.orderProducts?.length
      ? Math.max(
          ...props.orderProductList.orderProducts.map((product) =>
            new Date(product.maX_RDD_DATE).getTime()
          )
        )
      : null;

  return (
    <Fragment>
      {props.order.fetching !== false &&
      props.orderProductList.fetching !== false &&
      orderLoading !== false ? (
        LoadingPanel
      ) : (
        <main style={{ flexGrow: "1", padding: "40px" }}>
          <div>
            <h3>Bireysel Sipariş Tarih Onay Detayı</h3>
            {orderDateAproval?.onaY_RED === null && (
              <Card
                style={{
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <CardBody>
                  <CardTitle>
                    <img
                      width="50px"
                      height="50px"
                      alt=""
                      src={
                        window.location.origin +
                        process.env.PUBLIC_URL +
                        "/dex_logo.png"
                      }
                    />
                    Bu ekranda işlem yapabilmek için 2 dakikanız bulunmaktadır.
                  </CardTitle>
                  <hr></hr>
                  <div style={{ fontSize: "15px", color: "red" }}>
                    Kalan Süre : {formatTime(timeLeft)}
                    <Button
                      type="button"
                      color="primary"
                      size="md"
                      style={{ marginLeft: "20px" }}
                      disabled={!isActive && timeLeft === 0}
                      onClick={() => addTime()}
                    >
                      <i className="k-icon k-i-plus-circle"></i> Süre Ekle
                    </Button>
                  </div>
                </CardBody>
              </Card>
            )}

            {orderDateAproval?.onaY_RED !== null && (
              <Card
                style={{
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <CardBody>
                  <h4>
                    Sipariş Onay Durumu :{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          orderDateAproval?.onaY_RED === "ONAY"
                            ? "green"
                            : "red",
                      }}
                    >
                      {orderDateAproval?.onaY_RED}
                    </span>
                  </h4>
                </CardBody>
              </Card>
            )}
            <hr />
            <Card
              style={{
                width: "100%",
                marginTop: "20px",
              }}
            >
              <CardBody>
                <CardTitle>Sipariş Bilgileri</CardTitle>
                <hr></hr>
                <OrderDateApproveProps
                  orderData={props.order._order}
                  maX_RDD_DATE={maxRDDDate}
                />
              </CardBody>
            </Card>
            <Card
              style={{
                width: "100%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <CardBody>
                <CardTitle>Ürün Listesi</CardTitle>
                <div>
                  <OrderDateApproveProductList
                    orderId={props.order._order?.brY_SIPARIS_ID}
                    orderProductData={props.orderProductList?.orderProducts}
                    refreshFunc={refreshProductList}
                  ></OrderDateApproveProductList>
                </div>
              </CardBody>
            </Card>
            <Card
              style={{
                width: "100%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <CardBody>
                <Row>
                  <Col
                    lg={{ size: 6, offset: 3 }}
                    md={{ size: 6, offset: 3 }}
                    xs={{ size: 12 }}
                  >
                    <div>Onay/Red Açıklaması</div>
                    <TextArea
                      placeholder="Açıklama"
                      id="aciklama"
                      name="aciklama"
                      autoSize={false}
                      style={{ width: "100%" }}
                      value={orderDateAproval?.aciklama}
                      readOnly={orderDateAproval?.onaY_RED !== null}
                      onChange={(e) =>
                        setOrderDateAproval({
                          ...orderDateAproval,
                          aciklama: e.value,
                        })
                      }
                    ></TextArea>
                    <div style={{ marginTop: "10px", textAlign: "center" }}>
                      {" "}
                      <Button
                        type="button"
                        color="success"
                        size="md"
                        disabled={
                          (!isActive && timeLeft === 0) ||
                          orderDateAproval?.onaY_RED !== null
                        }
                        onClick={() => orderDateApproveReject("ONAY")}
                      >
                        <i className="k-icon k-i-check"></i> ONAYLA
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        size="md"
                        style={{ marginLeft: "5px" }}
                        disabled={
                          (!isActive && timeLeft === 0) ||
                          orderDateAproval?.onaY_RED !== null
                        }
                        onClick={() => approveCancel()}
                      >
                        <i className="k-icon k-i-times"></i> REDDET
                      </Button>
                      {!isActive &&
                        timeLeft === 0 &&
                        orderDateAproval?.onaY_RED === null && (
                          <div className="mt-2 text-red">
                            <i className="k-icon k-font-icon k-i-information"></i>
                            <i>
                              *Onaylama süreci zaman aşımına uğradı. Lütfen
                              tekrar deneyiniz.
                            </i>
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card
              style={{
                width: "100%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <CardBody>
                <CardTitle>Onay Red Geçmişi</CardTitle>
                <hr></hr>
                <Grid
                  data={orderDateHistoryLog || []}
                  sortable={false}
                  resizable={false}
                >
                  <GridColumn
                    field="satiS_ONAYCI_UNVAN"
                    title="Onay Hiyerarşisi"
                  ></GridColumn>
                  <GridColumn
                    field="satiS_ONAYCI_ADSOYAD"
                    title="Ad Soyad"
                  ></GridColumn>
                  <GridColumn field="onaY_RED" title="Onay/Red"></GridColumn>
                  <GridColumn
                    field="onaY_RED_TARIHI"
                    title="Onay Red Tarihi"
                    cell={(props) =>
                      GridDateFormatCell(props, "dd.MM.yyyy HH:mm")
                    }
                  ></GridColumn>
                  <GridColumn field="aciklama" title="Açıklama"></GridColumn>
                </Grid>
              </CardBody>
            </Card>
          </div>
        </main>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    orderProductList: state.orderProductListReducer,
    order: state.orderReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderProducts: bindActionCreators(getOrderProducts, dispatch),
    getOrder: bindActionCreators(getOrder, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDateApproveDetail);
