import React, { useState, useEffect } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { Row, Col, Button } from "reactstrap";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import NumberFormat from "react-number-format";
import OrderProductService from "../../services/srv-bry-order/OrderProductService";

const CalculateVolume = (props) => {
  const [data, setData] = useState([]);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 9999,
  });

  useEffect(() => {
    OrderProductService.getListBySiparisId(props.orderId, (result) => {
      if (result) {
        console.log(result);
        setData(result.data);
        if (result.dataState) setDataState(result.dataState);
      }
    });
  }, [props.orderId]);

  const onDataStateChange = (event) => {
    setDataState(event.dataState);
  };

  const itemChange = (event) => {
    const dataOrder = data.map((item) =>
      item.brY_SIPARIS_URUN_ID === event.dataItem.brY_SIPARIS_URUN_ID
        ? {
            ...item,
            [event.field]: event.value,

            secilI_URUN_HACIM:
              event.field === "secilI_URUN_ADET"
                ? event.value * item.hacim
                : item.secilI_URUN_HACIM,
          }
        : item
    );
    setData(dataOrder);
    FooterSumCellCapacity(event);
  };

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    if (dataItem.dataItem.puto === "DET") {
      trProps["style"] = {
        color: "gray",
        fontStyle: "italic",
      };
    }

    return React.cloneElement(
      trElement,
      { ...trProps },
      ...trElement.props.children
    );
  }

  const customCellRender = (tdElement, cellProps) => {
    const { field, dataItem } = cellProps;
    const shouldRender = dataItem.puto === "HAYIR" || dataItem.puto === "DET";
    if (
      (field === "hacim" ||
        field === "secilI_URUN_ADET" ||
        field === "secilI_URUN_HACIM") &&
      !shouldRender
    ) {
      return React.cloneElement(
        tdElement,
        {
          style: {
            ...tdElement.props.style,
            position: "relative",
          },
        },
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "transparent",
            userSelect: "none",
          }}
        >
          {tdElement.props.children}
        </div>
      );
    }
    return tdElement;
  };

  const customFooterCellRender = (tdElement, cellProps) => {
    const total = data
      .filter((item) => item.puto === "HAYIR" || item.puto === "DET")
      .reduce((sum, item) => sum + (item[cellProps.field] || 0), 0);
    return <td>{total}</td>;
  };

  const FooterSumCellCapacity = (props) => {
    const total = getTotal(props.field, true);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam :<br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        {props.field === "secilI_URUN_ADET" ? "Adet" : "m³"}
      </td>
    );
  };

  const getTotal = (field) => {
    return data.reduce((sum, item) => {
      if (field === "adet" || field === "secilI_URUN_ADET") {
        if (item.puto === "MST" || item.puto === "HAYIR") {
          return sum + (parseFloat(item[field]) || 0);
        }
      } else if (field === "hacim" || field === "secilI_URUN_HACIM") {
        if (item.puto === "DET" || item.puto === "HAYIR") {
          return sum + (parseFloat(item[field]) || 0);
        }
      } else {
        return sum + (parseFloat(item[field]) || 0);
      }
      return sum;
    }, 0);
  };

  const updateSeciliUrunHacim = (index, newValue) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      const item = updatedData[index];
      item.secilI_URUN_ADET = newValue;
      item.secilI_URUN_HACIM = newValue * item.hacim;

      if (item.puto === "MST") {
        for (let i = index + 1; i < updatedData.length; i++) {
          const nextItem = updatedData[i];
          if (nextItem.puto === "DET") {
            const carpan = nextItem.adet / item.adet;
            nextItem.secilI_URUN_ADET = newValue * carpan;
            nextItem.secilI_URUN_HACIM =
              nextItem.secilI_URUN_ADET * nextItem.hacim;
          } else {
            break;
          }
        }
      }

      return updatedData;
    });
  };

  const NumberFormatCell = (value, dataItem, isPercent, isKDV) => {
    return (
      <td
        style={{
          fontStyle: dataItem.puto === "DET" ? "italic" : "normal",
          color: dataItem.puto === "DET" ? "gray" : "rgba(0,0,0,0.78)",
        }}
      >
        {isPercent ? "% " : ""}
        <NumberFormat
          thousandSeparator={"."}
          value={value ? (isKDV ? value * 100 : value) : 0}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>
      </td>
    );
  };

  const PutoCellNumber = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return NumberFormatCell(dataValue, props.dataItem, false, false);
  };

  const getTotalByField = (field) => {
    return data
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current[field], 0);
  };

  const FooterSumCell = (props) => {
    var total = getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam :<br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        Adet
      </td>
    );
  };

  return (
    <Window
      title={"Hacim Bilgisi Hesapla"}
      onClose={props.toggleDialog}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
        zIndex: 10000,
      }}
    >
      <h6 className="text-red">
        <i class="fa fa-info-circle"></i> Bu ekranda <u>seçili adet, </u>
        <u>seçili hacim </u>
        üzerinde yapılan değişiklikler
        <u>
          <b> kaydedilmeyecektir. </b>
        </u>
        Sadece hesaplama amaçlıdır.
      </h6>
      <Grid
        sortable={false}
        resizable={true}
        filterable={false}
        data={data}
        {...dataState}
        dataItemKey={"brY_SIPARIS_URUN_ID"}
        rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
        cellRender={customCellRender}
        footerCellRender={customFooterCellRender}
        style={{ height: "95%" }}
      >
        <GridToolbar></GridToolbar>
        {/* Diğer kolonlar buraya gelecek */}

        <GridColumn field="uruN_KODU" title="Ürün Kodu/Adı"></GridColumn>

        <GridColumn
          field="adet"
          title="Adet"
          filterable={false}
          editor="numeric"
          footerCell={FooterSumCell}
          cell={PutoCellNumber}
        ></GridColumn>

        <GridColumn
          field="hacim"
          title="Hacim m³"
          editor="numeric"
          filterable={false}
          editable={true}
          footerCell={FooterSumCellCapacity}
          cell={PutoCellNumber}
        />

        <GridColumn
          field="secilI_URUN_ADET"
          title="Seçili Adet"
          filterable={false}
          editable={true}
          cell={(props) => (
            <td>
              {props.dataItem.puto === "DET" ? (
                <span>{props.dataItem.secilI_URUN_ADET}</span>
              ) : (
                <input
                  style={{ width: "120px" }}
                  type="number"
                  value={props.dataItem.secilI_URUN_ADET}
                  onChange={(e) =>
                    updateSeciliUrunHacim(
                      props.dataIndex,
                      Math.min(e.target.value, props.dataItem.adet)
                    )
                  }
                  min="0"
                  max={props.dataItem.adet}
                  step={
                    props.dataItem.puto === "DET" ? props.dataItem.carpim : 1
                  }
                />
              )}
            </td>
          )}
          footerCell={FooterSumCellCapacity}
        />

        <GridColumn
          field="secilI_URUN_HACIM"
          title="Seçili Hacim m³"
          editor="numeric"
          filterable={false}
          editable={true}
          footerCell={FooterSumCellCapacity}
          cell={PutoCellNumber}
        />
      </Grid>
    </Window>
  );
};

export default CalculateVolume;
