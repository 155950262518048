import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../config/globalVariables";

class OrderProductService {
  getBySiparisUrunId(teklifSiparisUrunId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparisurun/get-by-order-product-id/${teklifSiparisUrunId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }

  getListBySiparisId(orderId, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_DEX_BIREYSEL}/siparisurun/get-by-orderId/${orderId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }
}

export default new OrderProductService();
